import comprobantesConstants from "../../constants/servicio-gestion-vacaciones/Comprobantes";

const initState = {
  payload: [],
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  updating: false,
};

const comprobanteReducer = (state = initState, action) => {
  const { type, payload } = action
  switch (type) {

    case comprobantesConstants.UPDATE_COMPROBANTE:
    case comprobantesConstants.GET_STATE_COMPROBANTE:
    case comprobantesConstants.GET_STATE_FIRMA_COMPROBANTE:
    case comprobantesConstants.DELETE_COMPROBANTE:
    case comprobantesConstants.ADD_COMPROBANTE: {
      return {
        ...state,
        updating: true,
      };
    }

    case comprobantesConstants.COMPROBANTE_SUCCESS:
    case comprobantesConstants.GET_COMPROBANTE_CURRENT_USER_SUCCESS: {
        return {
          ...state,
          loading: false,
          payload: payload,
        };
    }
  
    case comprobantesConstants.GET_COMPROBANTE:
    case comprobantesConstants.GET_COMPROBANTE_CURRENT_USER: {
      return {
        ...state,
        loading: true,
        message: "",
      };
    }
    case comprobantesConstants.GET_STATE_COMPROBANTE_SUCCESS: 
    case comprobantesConstants.GET_STATE_FIRMA_COMPROBANTE_SUCCESS: {
      return {
        ...state,
        updating: false,
      };
    }
    
    case comprobantesConstants.UPDATE_COMPROBANTE_SUCCESS: 
    case comprobantesConstants.ADD_COMPROBANTE_SUCCESS:
    case comprobantesConstants.DELETE_COMPROBANTE_SUCCESS:{
      return {
        ...state,
        updating: false,
        payload: payload,
      };
    }

    default:
      return state;
  }
};
export default comprobanteReducer;
