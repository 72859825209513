import axios from "axios";
axios.defaults.headers.common['Authorization'] = localStorage.getItem('auth_token');

const {REACT_APP_API_URL} = process.env;
const ServicioHistorial={} 

//Servicio para listado de historial data Empleados

ServicioHistorial.getHistorial=async()=>{
    return await axios({
      method: 'get',
      url: `${REACT_APP_API_URL}/vacaciones/api/movimientos/listado`,
      responseType: 'json'
      }).then((response) => response)
      .catch(err=>err)
  }


  ServicioHistorial.getHistorialCurrentUser = async(data) => {
    return await axios({
      method: 'get',
      url: `${REACT_APP_API_URL}/vacaciones/api/movimientos/user`,
      params: data,
      responseType: 'json'
      }).then((response) => response.data.data)
      .catch(err=>err)
  }
 
  ServicioHistorial.getHistorialSum = async(data) => {
    return await axios({
      method: 'get',
      url: `${REACT_APP_API_URL}/vacaciones/api/movimientos/listado-agregado/`,
      responseType: 'json',
      params: data
      }).then((response) => response.data.agregado)
      .catch(err=>err)
  }

  ServicioHistorial.getHistorialOne=async(data)=>{

    return await axios({
      method: 'get',
      url: `${REACT_APP_API_URL}/vacaciones/api/movimientos/detalle/`,
      responseType: 'json',
      params: {id:data}
      }).then((response) => response.data)
      .catch(err=>err)
  }

  ServicioHistorial.addMovimientoAxios = async (data) => {
    const { _id, type, flag } = data;
    return await axios({
      method: "post",
      url: `${REACT_APP_API_URL}/vacaciones/api/movimientos/create`,
      data: {
        origin: _id,
        type: type,
        flag: flag
      },
    }).then((response) => response.data)
    .catch((err) => err);
  };
  
  ServicioHistorial.generarMovimiento=async(data)=>{
    const{ type, userId,employeeStartDate }= data
    return await axios({
      method: "post",
      url: `${REACT_APP_API_URL}/vacaciones/api/movimientos/create`,
      data: {
        type: type,
        userId: userId,
        employeeStartDate: employeeStartDate
      },
    }).then((response) => response.data)
    .catch((err) => err);

  }
  
  ServicioHistorial.deleteMovimientoAxios = async (data) => {
    const {_id} = data;
    return await axios({
      method: "delete",
      url: `${REACT_APP_API_URL}/vacaciones/api/movimientos/delete/${_id}`,
      data: {
        _id: data,
      },
    }).then((response) => response);
  };

//----------Implementacion de la logica M
  //? Servicio para eliminar las solicitudes M para la nueva logica.
  ServicioHistorial.deleteMovimientosMAxios= async (data) => {
  return await axios({
    method: "post",
      url: `${REACT_APP_API_URL}/vacaciones/api/movimientos/eliminarmovimientosM`,
    data: {
      id: data,
    },
  }).then((response) => response.data);
};



  export default ServicioHistorial;