export const GET_PRESUPUESTOS = "GET_PRESUPUESTOS";
export const GET_PRESUPUESTOS_SUCCESS = "GET_PRESUPUESTOS_SUCCESS";

export const GET_CENTROS = "GET_CENTROS";
export const GET_CENTROS_SUCCESS = "GET_CENTROS_SUCCESS";

export const POST_PRESUPUESTO_DATA = "POST_PRESUPUESTO_DATA";
export const POST_PRESUPUESTO_DATA_SUCCESS = "POST_PRESUPUESTO_DATA_SUCCESS";

export const POST_PROCESAR_SAC = "POST_PROCESAR_SAC";
export const POST_PROCESAR_SAC_SUCCESS = "POST_PROCESAR_SAC_SUCCESS";

export default {
  GET_PRESUPUESTOS,
  GET_PRESUPUESTOS_SUCCESS,
  GET_CENTROS,
  GET_CENTROS_SUCCESS,
  POST_PRESUPUESTO_DATA,
  POST_PRESUPUESTO_DATA_SUCCESS,
  POST_PROCESAR_SAC,
  POST_PROCESAR_SAC_SUCCESS
};
