import { all, takeEvery, fork } from "redux-saga/effects";
import {
  GET_FACTURAS,
  GET_FILE,
  GET_ONE_FILE
} from "../../constants/servicio-vista-factura/VistaFactura";
import facturafunctions from "./Factura.functions";

//? Listar Facturas
export function* listfacturasSagas() {
  yield takeEvery(GET_FACTURAS, facturafunctions.listFacturas);
}

export function* listFilesSagas() {
  yield takeEvery(GET_FILE, facturafunctions.listFiles);
}
export function* listOneFileSagas() {
  yield takeEvery(GET_ONE_FILE, facturafunctions.listOneFile);
}

export default function* rootSagaFacturas() {
  yield all([
    fork(listfacturasSagas),
    fork(listFilesSagas),
    fork(listOneFileSagas),

  ]);
}
