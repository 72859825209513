import { put, call } from "redux-saga/effects";
import ContratosActions from '../../../actions/servicio-contratos/Contratos'
import { message } from "antd";
import serviceContratos from "axios/servicio-contratos/Contratos";

//? Lista los contratos
export function* listContratos() {
  try {
    
    const contratos = yield call(serviceContratos.listContratos);

    if (contratos.error) {
      message.error(contratos.error);
    }
    yield put(ContratosActions.listadoContratos(contratos));
  } catch (err) {
    message.error(err.message);
  }
}

//? Agrega contratos
export function* addContratos({ payload }) {
  try {
    const contratos = yield call(serviceContratos.createdContratos, payload);
    
    if (contratos.error) {
      message.error(contratos.msg);
    }else{
      message.success("Contrato Agregado");
      yield put(ContratosActions.SuccessAddContratosAction());
    }

  } catch (err) {
    message.error(err.message);
  }
}


//? Lista solo un contrato
export function* listOneContratos({ payload }) {
  try {
    const contratos = yield call(serviceContratos.listContratoId, payload);

    if (contratos.error) {
      message.error(contratos.error);
    }
    yield put(ContratosActions.listIDContratosSuccess(contratos));
  } catch (err) {
    message.error(err.message);
  }
}


//? eliminar contratos
function* deleteContratos({ payload }) {
  try {
    const contratos = yield call(serviceContratos.deleteContratos, payload);

    if (contratos.error) {
      message.error(contratos.msg);
    }
    yield put(ContratosActions.deleteContratoSuccess());
    message.success(contratos.msg);
  } catch (err) {
    message.error(err.message);
  }
}


//? Editar Contratos
export function* updateContratos({ payload }) {
  try {
    const contratos = yield call(serviceContratos.updateContratos, payload);
    if (contratos.error) {
      message.error(contratos.msg);
    }
    message.success(contratos.msg);
    yield put(ContratosActions.updateContratoSuccess());
  } catch (err) {
    message.error(err.message);
  }
}

//? Agregar archivos
export function* addFiles({ payload }) {
  try {
    const addFiles = yield call(serviceContratos.insertFilesAxios, payload);
    if (addFiles.msg) {
      yield put(ContratosActions.SuccessAddFilesAction());
      message.success("¡Archivo Insertado!");
    } else {
      message.error("Error al insertar el archivo!");
    }
  } catch (err) {
    message.error(err.message);
  }
}


// //? Eliminar archivos
export function* deleteFiles({ payload }) {
  try {
    const deleteFiles = yield call(
      serviceContratos.deleteFilesAxios,
      payload
    );
    
    if (deleteFiles) {
      message.success("¡Archivo Eliminado!");
      yield put(ContratosActions.successDeleteFilesAction(payload));
    }
  } catch (err) {
    message.error(err.message);
  }
}

export default {
    listContratos,
    addContratos,
    listOneContratos,
    deleteContratos,
    updateContratos,
    addFiles,
    deleteFiles
}