import { put, call } from "redux-saga/effects";
import { message } from "antd";
import serviceComprobante from "axios/servicio-gestion-vacaciones/Comprobantes";
import serviceUnysoft from "axios/servicio-gestion-unysoft/ServiceUnysoft";
import comprobantesActions from '../../../actions/servicio-gestion-vacaciones/Comprobantes'


//? Funcion para agregar los comprobantes
function* addComprobante({ payload }) {
    try {
   // Data utilizada para el calculo de haberes
      const data = {
        cedula: payload.ci,
        daysQuantity: payload.cantidadDias
      }

      // Se crea los haberes y se agrega al objeto del payload
      const haberes = yield call(serviceUnysoft.listarHaberesAxios, data);
      payload.vacationAmmount = haberes

      // Se crea el comprobante
      const comprobante = yield call(serviceComprobante.addComprobantesAxios, payload);
      
      console.log('comprobante: ',comprobante)
      if (comprobante.msg) {
        message.error("Falló la creación de comprobante");
      } else {
        yield put(comprobantesActions.addComprobanteSuccessAction());
        message.success("Comprobante creada!");
      }

    } catch (err) {
      message.error(err.message);
    }
  }


//? Funcion para editar Comprobantes
function* editComprobante({ payload }) {
    try {
      // Data utilizada para el calculo de haberes
      const data = {
        cedula: payload.ci,
        daysQuantity: payload.cantidadDias
      }
          // Se crea los haberes y se agrega al objeto del payload
      const haberes = yield call(serviceUnysoft.listarHaberesAxios, data);
      payload.vacationAmmount = haberes

      // Se edita el comprobante
      const comprobante = yield call(serviceComprobante.EditComprobantesAxios, payload);

      if (comprobante) {
        yield put(comprobantesActions.updateComprobanteSuccessAction());
        message.success("Comprobante editada!");
      }

    } catch (err) {
      message.error(err.message);
    }
  }

//? Funcion para eliminar Comprobantes
function* deleteComprobante({ payload }) {
    try {

      // Se elimina el comprobante
      const comprobante = yield call(serviceComprobante.deleteComprobantesAxios, payload);

      if (comprobante) {
        yield put(comprobantesActions.deleteComprobanteSuccessAction());
        message.success("Comprobante eliminado!"); 
      }

    } catch (err) {
      message.error(err.message);
    }
  }


//? Funcion para listar los comprobantes
function* listComprobante() {
  try {
    const comprobante = yield call(serviceComprobante.getComprobantesAxios);

    if (comprobante.msg) {
      message.error(comprobante.msg);
    } else {
      yield put(comprobantesActions.ComprobanteSuccessAction(comprobante.data));
    }
  } catch (err) {
    message.error(err.message);
  }
}


function* CurrentUserComprobante({payload}) {
  try {
    const comprobante = yield call(serviceComprobante.getCurrentUserComprobantesAxios, payload);
  console.log('comprobante: ', comprobante)
    if (comprobante.msg) {
      message.error(comprobante.msg);
    } else {
      
      yield put(comprobantesActions.getCurrentUserComprobanteSuccessAction(comprobante.data));
    }
  } catch (err) {
    message.error(err.message);
  }
}


//? Funcion para cambiar el estado del comprobante de PDF
function* changeStatePdfComprobante({payload}) {
  try {
    const stateComprobante = yield call(serviceComprobante.changeStateComprobantesPdfAxios, payload );
    
      if (stateComprobante.error === "true") {
      message.error(stateComprobante.error);
    }
      yield put(comprobantesActions.getStateComprobanteSuccessAction());
    
  } catch (err) {
    message.error(err.message);
  }
}


//? Funcion para cambiar el estado del comprobante de Firmas
function* changeStateFirmaComprobante({payload}) {
  try {
    const stateComprobante = yield call(serviceComprobante.changeStateComprobantesFirmasAxios,payload);
    message.success("Documento firmado correctamente")
    yield put(comprobantesActions.getStateFirmasComprobanteSuccessAction(stateComprobante));
    } 
    catch (err) {
    message.error(err.message);
    }
}


//? Funcion para cambiar el estado del comprobante
function* firmaAll({payload}) {
  try {
    const stateComprobante = yield call(serviceComprobante.firmarAllAxios,payload);

      if (stateComprobante.error === "true") {
      message.error(stateComprobante.error);
    } 
      yield put(comprobantesActions.getComprobanteAction());
      message.success(stateComprobante.msg)

  } catch (err) {
    message.error(err.message);
  }
}



export default  {
    addComprobante,
    editComprobante,
    deleteComprobante,
    listComprobante,
    CurrentUserComprobante,
    changeStatePdfComprobante,
    changeStateFirmaComprobante,
    firmaAll
}
