///CONSTANTES ROLES
export const CONSULTA_ROLES_GET = 'CONSULTA_ROLES_GET'
export const ADD_ROLES_POST = 'ADD_ROLES_POST'
export const DELETE_ROLES_POST = 'DELETE_ROLES_POST'
export const UPDATE_ROLES_POST = 'UPDATE_ROLES_POST'
export const ROLES_SUCCESS = 'ROLES_SUCCESS'
export const CONSULTA_ROLES_EXITOSO = 'CONSULTA_ROLES_EXITOSO'


export default {
    CONSULTA_ROLES_GET,
    ADD_ROLES_POST,
    DELETE_ROLES_POST,
    UPDATE_ROLES_POST,
    ROLES_SUCCESS,
    CONSULTA_ROLES_EXITOSO,
}