
///CONSTANTES REPORTES 
export const GET_REPORTES = 'GET_REPORTES'
export const GET_REPORTES_SUCCESS = 'GET_REPORTES_SUCCESS'

export const ADD_REPORTES = 'ADD_REPORTES'
export const ADD_REPORTES_SUCCESS = 'ADD_REPORTES_SUCCESS'

export const DELETE_REPORTES = 'DELETE_REPORTES'
export const DELETE_REPORTES_SUCCESS = 'DELETE_REPORTES_SUCCESS'

export const UPDATE_REPORTES = 'UPDATE_REPORTES'
export const UPDATE_REPORTES_SUCCESS = 'UPDATE_REPORTES_SUCCESS'

export default  {
    GET_REPORTES, GET_REPORTES_SUCCESS, 
    ADD_REPORTES, ADD_REPORTES_SUCCESS,
    DELETE_REPORTES, DELETE_REPORTES_SUCCESS,
    UPDATE_REPORTES, UPDATE_REPORTES_SUCCESS
}