import playeroConstants from "../../constants/servicio-playero/Playero";

const initState = {
  loadingProcess: false,
  message: "",
  showMessage: false,
  redirect: "",
};

const presupuestoReducer = (state = initState, action) => {
  const { type } = action;
  switch (type) {
    case playeroConstants.POST_BEACH_SAP_DATA: {
      return {
        ...state,
        loadingProcess: true,
        message: "",
      };
    }

    case playeroConstants.POST_BEACH_SAP_DATA_SUCCESS: {
      return {
        ...state,
        loadingProcess: false,
      };
    }

    default:
      return state;
  }
};
export default presupuestoReducer;
