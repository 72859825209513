import { all, takeEvery, fork } from "redux-saga/effects";
import {CONSULTA_ROLES_GET, ADD_ROLES_POST, DELETE_ROLES_POST, 
UPDATE_ROLES_POST} from "../../constants/servicio-autenticacion/Roles";
import rolesFunction from './functions/Roles.functions'


export function* listRoleSaga() {
  yield takeEvery(CONSULTA_ROLES_GET, rolesFunction.listRole );
}

export function* addRoleSaga() {
  yield takeEvery(ADD_ROLES_POST, rolesFunction.addRole );
}


export function* deleteRoleSaga() {
  yield takeEvery(DELETE_ROLES_POST, rolesFunction.deleteRole );
}


export function* updateRoleSaga() {
  yield takeEvery(UPDATE_ROLES_POST, rolesFunction.updateRole );
}

export default function* rootSagaRoles() {
  yield all([
    fork(listRoleSaga),
    fork(addRoleSaga),
    fork(deleteRoleSaga),
    fork(updateRoleSaga),
  ]);
}
