import { all, takeEvery, fork } from "redux-saga/effects";
import {
  GET_CONTRATOS,
  ADD_CONTRATOS,
  GET_CONTRATOS_ID,
  DELETE_CONTRATOS,
  UPDATE_CONTRATOS,
  POST_FILES,
  DELETE_FILES,
} from "../../constants/servicio-contratos/Contratos";
import contratos_func from "./functions/Contratos.functions";
 
//? Lista los contratos
export function* listContratosSaga() {
  yield takeEvery(GET_CONTRATOS, contratos_func.listContratos );
}

//? Agrega contratos
export function* addContratosSaga() {
  yield takeEvery(ADD_CONTRATOS, contratos_func.addContratos);
}

//? Lista solo un contrato
export function* listOneContratosSaga() {
  yield takeEvery(GET_CONTRATOS_ID, contratos_func.listOneContratos);
}

//? eliminar contratos
export function* deleteContratosSaga() {
  yield takeEvery(DELETE_CONTRATOS, contratos_func.deleteContratos);
}

//? Editar Contratos
export function* updateContratosSaga() {
  yield takeEvery(UPDATE_CONTRATOS, contratos_func.updateContratos);
}

//? Agregar archivos
export function* addFilesSagas() {
  yield takeEvery(POST_FILES, contratos_func.addFiles);
}

//? Eliminar archivos
export function* deleteFilesSagas() {
  yield takeEvery(DELETE_FILES, contratos_func.deleteFiles);
}

export default function* rootSagaContratos() {
  yield all([
    fork(listContratosSaga),
    fork(addContratosSaga),
    fork(listOneContratosSaga),
    fork(deleteContratosSaga),
    fork(updateContratosSaga),
    fork(addFilesSagas),
    fork(deleteFilesSagas),
  ]);
}
