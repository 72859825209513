import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import PageHeaderAlt from "components/layout-components/PageHeaderAlt";
import { useSelector, useDispatch } from "react-redux";

// Importaciones de componentes y librerías
import { Tabs, Form, Button, Row, Col, Spin, message, Upload } from "antd";
import Flex from "components/shared-components/Flex";
import { InboxOutlined } from "@ant-design/icons";
import "moment/locale/es";

// importaciones de las acciones
import { postBeachSapDataAction } from "redux/actions/servicio-playero/Playero";

// importaciones de las selectors
import { selectLoadingProcess } from "redux/selectors/servicio-playero/playero.selectors";

const propsDragger = {
  name: "file",
  multiple: false,
  action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
  onChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
      // console.log(info.file, info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
    console.log("Dropped files", e.dataTransfer.files);
  },
};

const { TabPane } = Tabs;
const { Dragger } = Upload;
const ADD = "ADD";

// Creación de componente
const ComprobanteForm = (props) => {
  const { mode = ADD } = props;
  let history = useHistory();
  let dispatch = useDispatch();

  const isLoadingProcess = useSelector(selectLoadingProcess);

  console.log(isLoadingProcess);
  const [form] = Form.useForm();

  // Se definen useState
  const [submitLoading, setSubmitLoading] = useState(false);

  const onFinish = (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    form
      .validateFields()
      .then((values) => {
        setTimeout(() => {
          setSubmitLoading(false);
          if (mode === ADD && values) {
            // console.log("Trae algo?: ", uploadedFile);
            dispatch(postBeachSapDataAction(uploadedFile));
          }
        }, 1500);
      })
      .catch((info) => {
        setSubmitLoading(false);
        message.error("¡Por favor complete todos los campos! ");
      });
  };
  const [uploadedFile, setUploadedFile] = useState(null);

  return (
    <>
      <Form
        encType="multipart/form-data"
        layout="vertical"
        form={form}
        name="advanced_search"
        className="ant-advanced-search-form"
      >
        <PageHeaderAlt className="border-bottom" overlap>
          <div className="container">
            <Flex
              className="py-2"
              mobileFlex={false}
              justifyContent="between"
              alignItems="center"
            >
              <h2 className="mb-3">
                {mode === "ADD" ? "Sistema Playero" : ""}
              </h2>
              <div className="mb-3">
                <Button
                  className="mr-2"
                  onClick={() => {
                    for (let index = 0; index < 2; index++) {
                      history.goBack();
                    }
                  }}
                >
                  Descartar
                </Button>
                <Button
                  type="primary"
                  onClick={(e) => onFinish(e)}
                  htmlType="button"
                  loading={submitLoading}
                  disabled={isLoadingProcess || !uploadedFile}
                >
                  {mode === "ADD" ? "Procesar" : ""}
                </Button>
              </div>
            </Flex>
          </div>
        </PageHeaderAlt>
        <div className="container">
          <Tabs
            className="mx-auto"
            defaultActiveKey="1"
            style={{ marginTop: 30 }}
          >
            <TabPane
              className="mx-auto"
              tab="General"
              key="1"
              style={{ paddingBottom: "20px" }}
            >
              <Spin
                tip="Procesando..."
                size="large"
                spinning={isLoadingProcess}
              >
                <Row gutter={16} justify="center" align="middle">
                  <Col xs={24} sm={15} md={15} lg={15} xl={20} xxl={8}>
                    <Dragger
                      {...propsDragger}
                      maxCount={1}
                      accept=".xls,.xlsx"
                      beforeUpload={(file) => {
                        setUploadedFile(file);
                        return false; // Para evitar la subida inmediata del archivo
                      }}
                      onRemove={() => setUploadedFile()}
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Haz clic para subir el archivo
                      </p>
                    </Dragger>
                  </Col>
                </Row>
              </Spin>
            </TabPane>
          </Tabs>
        </div>
      </Form>
    </>
  );
};

export default ComprobanteForm;
