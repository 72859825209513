export const USER_GET_ADMIN = 'USER_GET_ADMIN' 
export const USER_SUCCESS_ADMIN = 'USER_SUCCESS_ADMIN' 
export const USER_ADD_ADMIN = 'USER_ADD_ADMIN' 
export const USER_SUCCESS_ONLY_ADMIN = 'USER_SUCCESS_ONLY_ADMIN'
export const USER_ONLY_ADMIN = 'USER_ONLY_ADMIN'
export const USER_ONLY_GET_ADMIN = 'USER_ONLY_GET_ADMIN'
export const USER_SUCCESS_ONLY_ADMIN_CI='USER_SUCCESS_ONLY_ADMIN_CI'
export const USER_EDIT_ADMIN='USER_EDIT_ADMIN'
export const USER_SUCCESS_EDIT_ADMIN='USER_SUCCESS_EDIT_ADMIN'

export default {
    USER_GET_ADMIN,
    USER_SUCCESS_ADMIN,
    USER_ADD_ADMIN,
    USER_SUCCESS_ONLY_ADMIN,
    USER_ONLY_ADMIN,
    USER_ONLY_GET_ADMIN,
    USER_SUCCESS_ONLY_ADMIN_CI,
    USER_EDIT_ADMIN,
    USER_SUCCESS_EDIT_ADMIN
}