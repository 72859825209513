import axios from "axios";
const serviceProfile = {};
const moment = require('moment')

const { REACT_APP_API_URL } = process.env;

axios.defaults.headers.common['Authorization'] = localStorage.getItem('auth_token') ;

serviceProfile.updateProfile = async (data) => {
  const { _id, username, nombres, apellidos, cargo, telefono,fecha_nac } = data;

  return await axios({
    method: "post",
    url: `${REACT_APP_API_URL}/admin/api/user/edit/userdatos/${_id}`,
    data: {
      username: username,
      nombres: nombres,
      apellidos: apellidos,
      cargo: cargo,
      telefono: telefono,
      fecha_nac: moment(fecha_nac).format('MM/DD/YYYY')
    },
  }).then((response) => response);
};

serviceProfile.listprofileSaga = async () => {
  return await axios({
    method: "get",
    url: `${REACT_APP_API_URL}/admin/api/user/list/`,
    responseType: "json",
  })
    .then((response) => response.data)
    .catch((err) => err);
};






export default serviceProfile;
