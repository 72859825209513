import axios from "axios";
axios.defaults.headers.common["Authorization"] =
  localStorage.getItem("auth_token");

const ServicioPlayero = {};
const { REACT_APP_API_URL } = process.env;

//? Procesar SAC.
ServicioPlayero.postBeachDataAxios = async (data) => {
  let formData = new FormData();

  formData.append("file", data);
  return await axios({
    method: "post",
    url: `${REACT_APP_API_URL}/playero/api/beach/upload_files`,
    data: formData, // Le pasamos el formData
    headers: { "Content-Type": "multipart/form-data" }, // El contenido debe ser tipo multipart/form-data
  })
    .then((response) => response)
    .catch((err) => err);
};

export default ServicioPlayero;
