import { put, call } from "redux-saga/effects";
//? Se importan las constantes
import ReportesUsersActions from '../../actions/servicio-reportes/Users'
import { message } from "antd";
import serviceUsers from "axios/servicio-reportes/Users"; // Importamos el servicio de axios de users del servicio Reportes

//? Lista todos los usuarios del dominio -> Endpoint de Servicio de autenticacion
export function* listUsersDomain  () {
  try {
    const users = yield call(serviceUsers.consultUsersDomainAxios);

    if (users.error === "true") {
      message.error(users.error);
    } else {
      yield put(ReportesUsersActions.listUsersReportesSuccessAction(users) );
    }

  } catch (err) {
    message.error(err.message);
  }
  }

//? Lista por id de usuarios de reportes -> obtiene el rol de cada usuario
export function* listOneUsersReportes ({ payload }) {
  try {
    const dataUser = yield call(
      serviceUsers.listOneUsersReportesAxios,
      payload
    );

    if (dataUser) {
      yield put(ReportesUsersActions.listOneUserReportesSuccessAction(dataUser));
    }

  } catch (err) {
    message.error(err.message);
  }
  }

//? Listar todos los usuarios de reportes
export function* listAllUser () {
    try {
      const dataUser = yield call(serviceUsers.listUsersReportesAxios);
      if (dataUser.msg) {
        message.error(dataUser.msg);
      }
      yield put(ReportesUsersActions.listAllUserReportesSuccessAction(dataUser));

    } catch (err) {
      message.error(err.message);
    }
  }

//? Crea Usuarios de reportes
export function* createUserReportes ({ payload }) {
    try {

      const dataUser = yield call(
        serviceUsers.createUserReportesAxios,
        payload
      );

      if (dataUser.msg) {
        message.error(dataUser.msg);
      } else {
        message.success("Registrado");
      }

      yield put(ReportesUsersActions.createUserReportesSuccessAction());

    } catch (err) {
      message.error(err.message);
    }
  }

//? Edita Usuarios de reportes
export function* editUserReportes ({ payload }) {
    try {
      const dataUser = yield call(serviceUsers.editUserReportesAxios, payload);

      if (dataUser.msg) {
        message.error(dataUser.msg);
      } else {
        message.success(`¡Datos del usuario editados exitosamente!`);
        yield put(ReportesUsersActions.editUserReportesSucessAcction());
      }

    } catch (err) {
      message.error(err.message);
    }
  }

//? Elimina usuarios de reportes
export function* deleteUserReportes ({ payload }) {
    try {
      const dataUser = yield call(
        serviceUsers.deleteUserReportesAxios,
        payload
      );
        console.log("deleteUserReportes: ",dataUser )
      if (dataUser.msg) {
        message.success(dataUser.msg);
      }
      yield put(ReportesUsersActions.deleteUserReportesSuccessAction());

    } catch (err) {
      message.error(err.message);
    }
  }

//? Lista datos de un usuario por id -> Endpoint de Servicio de Autenticacion
    
    export  function* listOneUser ({ payload }) {
    try {
      const dataUser = yield call(serviceUsers.listOneUserDataAxios, payload);

      if (dataUser.msg) {
        message.error(dataUser.msg);
      } else {
        const { ci } = dataUser;
        const dataCi = ci;
        yield put(ReportesUsersActions.listUserAdminSuccessAction(dataCi));
      }

    } catch (err) {
      message.error(err.message);
    }
  }

export default {
    listUsersDomain,
    listOneUsersReportes,
    listAllUser,
    createUserReportes,
    editUserReportes,
    deleteUserReportes,
    listOneUser
}