import presupuestoConstants from "../../constants/servicio-presupuesto/presupuesto";

const initState = {
  payloadPresupuestos: [],
  payloadCentros: [],
  loadingCentros: false,
  loadingPresupuestos: false,
  loadingProcess: false,
  processSacloading: false,
  message: "",
  showMessage: false,
  redirect: "",
};

const presupuestoReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    //? Reducer Listar presupuestos
    case presupuestoConstants.GET_PRESUPUESTOS: {
      return {
        ...state,
        loadingPresupuestos: true,
      };
    }

    case presupuestoConstants.GET_PRESUPUESTOS_SUCCESS: {
      return {
        ...state,
        payloadPresupuestos: payload,
        loadingPresupuestos: false,
      };
    }

    //? Reducer Listar centros
    case presupuestoConstants.GET_CENTROS: {
      return {
        ...state,
        loadingCentros: true,
      };
    }

    case presupuestoConstants.GET_CENTROS_SUCCESS: {
      return {
        ...state,
        payloadCentros: payload,
        loadingCentros: false,
      };
    }

    //? Reducer post de datos de presupuestos
    case presupuestoConstants.POST_PRESUPUESTO_DATA: {
      return {
        ...state,
        loadingProcess: true,
        message: "",
      };
    }

    case presupuestoConstants.POST_PRESUPUESTO_DATA_SUCCESS: {
      return {
        ...state,
        loadingProcess: false,
      };
    }

    //? Reducer para procesar sac
    case presupuestoConstants.POST_PROCESAR_SAC: {
      return {
        ...state,
        processSacloading: true,
      };
    }

    case presupuestoConstants.POST_PROCESAR_SAC_SUCCESS: {
      return {
        ...state,
        processSacloading: false,
      };
    }

    default:
      return state;
  }
};
export default presupuestoReducer;
