import { put, call } from "redux-saga/effects";
import { message } from "antd";
import utilsActions from "../../../actions/servicio-gestion-vacaciones/Utils";
import serviceUtils from "axios/servicio-gestion-vacaciones/Utils";

//? Calculo de vacaciones, días hábiles, fecha retorno etc.
function* calculateVacaciones({ payload }) {

  try {
    const utils = yield call(serviceUtils.postCalculoVacaciones, payload);

    if (utils.error === "true") {
      message.error(utils.error);
    } else {
      yield put(utilsActions.calculateVacacionesSuccessAction(utils));
    }
  } catch (err) {
    message.error(err.message);
  }
}

export default {
  calculateVacaciones,
};
