import profileConstants from "../../constants/servicio-autenticacion/Profile";

const initState = {
  payload: [],
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
};

const profileReducer = (state = initState, action) => {
  const { type, payload } = action

  switch (type) {
    
    case profileConstants.UPDATE_PROFILE: {
      return {
        ...state,
        loading: false,
      };
    }

    case profileConstants.PROFILE_SUCCESS: {
        return {
          ...state,
          loading: false,
          payload:payload,
      
        }
      }

    case profileConstants.GET_PROFILE: {
      return {
        ...state,
        loading: true,
        message:''
      };
    }

    default:
      return state;
  }
};
export default profileReducer;
