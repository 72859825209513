import { all, takeEvery, fork } from "redux-saga/effects";
import { POST_BEACH_SAP_DATA } from "../../constants/servicio-playero/Playero";
import playerofunctions from "./Playero.functions";

export function* sendBeachSapDataSagas() {
  yield takeEvery(POST_BEACH_SAP_DATA, playerofunctions.sendBeachSapData);
}

export default function* rootSagaReportes() {
  yield all([fork(sendBeachSapDataSagas)]);
}
