import { put, call } from "redux-saga/effects";
import { AUTH_TOKEN, USER_NAME, USER_ID } from "../../../constants/servicio-autenticacion/Auth";
import { authSaga } from "axios/servicio-autenticacion/Auth";
import authActions from '../../../actions/servicio-autenticacion/Auth'


// Se realiza peticiones a la api para la autenticación
function* signInApi({ payload }) {
    try {
      const user = yield call(authSaga, payload);
      const username = user.nombres + ' ' + user.apellidos;
      if (user.msg) {
        yield put( authActions.showAuthMessageAction(user.msg) );
      } else {
        localStorage.setItem(AUTH_TOKEN, user.token);
        localStorage.setItem(USER_ID, user.uid);
        localStorage.setItem(USER_NAME, username);
        yield put( authActions.authenticatedAction(user) );
      }
    } catch (err) {
      yield put( authActions.showAuthMessageAction(err.message) ); 
    }
}

// Se realiza peticiones a la api para el deslogueo
function* signOut() {
    try {
        localStorage.removeItem(AUTH_TOKEN);
        localStorage.removeItem(USER_NAME);
        localStorage.removeItem(USER_ID);
        localStorage.removeItem('persist:viewer')
        localStorage.removeItem('persist:root')
        yield put( authActions.signOutSuccessAction() );
     
    } catch (err) {
        yield put( authActions.showAuthMessageAction(err) );
    }
}

export default { signInApi, signOut }
