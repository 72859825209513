import { createSelector } from "reselect";

const selectFacturas = (state) => state.vistaFactura;

export const selectFacturasLoading = createSelector(
  [selectFacturas],
  (currentState) => currentState.loadingFacturas
);

export const selectFacturasPayload = createSelector(
  [selectFacturas],
  (currentState) => {
    return currentState.payloadFacturas;
  }
);

export const selectFilePayload = createSelector(
  [selectFacturas],
  (currentState) => {
    return currentState.payloadFile;
  }
);

export const selectOneFilePayload = createSelector(
  [selectFacturas],
  (currentState) => {
    return currentState.payloadOneFile;
  }
);

export const selectFileLoading = createSelector(
  [selectFacturas],
  (currentState) => currentState.loadingFiles
);