import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';

const Apps = ({ match }) => (
  <Suspense fallback={<Loading cover="content"/>}>
    <Switch>
      <Route path={`${match.url}/portal-liquidaciones-digitales/liquidaciones`} component={lazy(() => import(`./liquidaciones`))} />
      {/* <Route path={`${match.url}/portal-admin/roles`} component={lazy(() => import(`./roles`))} /> */}
      <Redirect from={`${match.url}`} to={`${match.url}/portal-liquidaciones-digitales/liquidaciones`} />
    </Switch>
  </Suspense>
);

export default Apps;