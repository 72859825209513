import { createSelector } from 'reselect'

const selectSolicitudesUsers = (state) => state.solicitudesUsers


export const selectAllSolicitudesUsers = createSelector(
    [selectSolicitudesUsers],
    solicitudesUsers => solicitudesUsers.dataUserVaca
)

export const selectAllSystemUsersSolicitudes = createSelector(
    [selectSolicitudesUsers],
    solicitudesUsers => solicitudesUsers.payload
)

export const selectSolicitudesCurrentUser = createSelector(
    [selectSolicitudesUsers, (_,id) => id],
    ( solicitudesUsers, id) =>   
    solicitudesUsers.dataUserVaca.filter( user => user._id === id)[0]
)

export const selectSolicitudesCurrentUserCi = ci => createSelector(
    [selectSolicitudesUsers],
    solicitudesUsers =>   {
        console.log('SELECTOR selectSolicitudesCurrentUserCi: ', ci, solicitudesUsers)
    return solicitudesUsers.dataUserVaca.filter( user => user.ci === ci)[0]
}
)
export const selectCurrentUserRoleSolicitudes = createSelector(
    [selectSolicitudesUsers],
    solicitudesUsers => solicitudesUsers.role
)

export const selectCurrentUserCiSolicitudes = createSelector(
    [selectSolicitudesUsers],
    solicitudesUsers => solicitudesUsers.ci
)

export const selectUpdateSolicitudesUsers = createSelector(
    [selectSolicitudesUsers],
    solicitudesUsers => solicitudesUsers.update
)

export const selectLoadingSolicitudesUsers = createSelector(
    [selectSolicitudesUsers],
    solicitudesUsers => solicitudesUsers.loading
)