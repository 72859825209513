import { all, takeEvery, fork } from "redux-saga/effects";
import {
  GET_REPORTES, ADD_REPORTES, DELETE_REPORTES, UPDATE_REPORTES,
} from "../../constants/servicio-reportes/Reportes";
import reportes_functions from './Reportes.functions'

//? Listar Reportes de contratos
export function* listReportesSagas() {
  yield takeEvery(GET_REPORTES, reportes_functions.listReportes );
}

//? Agregar Reportes de contratos
export function* addReportesSagas() {
  yield takeEvery(ADD_REPORTES, reportes_functions.addReportes );
}

//? Eliminar Reportes de contratos
export function* deleteReportesSagas() {
  yield takeEvery(DELETE_REPORTES, reportes_functions.deleteReportes);
}

//? Eliminar Actualizar de contratos
export function* updateReportesSagas() {
  yield takeEvery(UPDATE_REPORTES, reportes_functions.updateReportes );
}

export default function* rootSagaReportes() {
  yield all([
    fork(listReportesSagas),
    fork(addReportesSagas),
    fork(deleteReportesSagas),
    fork(updateReportesSagas),
  ]);
}
