import LiquidacionesActionTypes from "../../constants/servicio-liquidacion-digital/Liquidaciones";

const initState = {
  payload: [],
  filters: {},
  PDFGenerated: false,
  DocumentaUser:{},
  progressBar: {
    show: false,
    percent: 0,
    state: 'active'
  },
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  updating: false,
};

function removePropFromObject(obj, prop) {
  const { [prop]: _, ...rest } = obj
  return { ...rest }
}

const liquidacionesReducer = (state = initState, action) => {
  const { type, payload  } = action
  switch (type) {

    case LiquidacionesActionTypes.GET_LIQUIDACIONES_SUCCESS: {
      const { comprobantes, PDFGenerated } = payload
      return {
        ...state,
        loading: false,
        payload: comprobantes,
        filters: removePropFromObject(payload,'comprobantes'),
        PDFGenerated: PDFGenerated
      };
    }

    case LiquidacionesActionTypes.GET_LIQUIDACIONES: {
      return {
        ...state,
        loading:true,
        message: "",
      };
    }

    case LiquidacionesActionTypes.SEND_LIQUIDACIONES: {
      return {
        ...state,
        message: "",
      };
    }

    case LiquidacionesActionTypes.REGISTER_COMPROBANTE_SENDED: {
      const { CI } = payload
      
      return {
        ...state,
        payload: state.payload.map( e => e.CI === CI ? {...e, sended: true} : e )
      };
    }

    case LiquidacionesActionTypes.SEND_LIQUIDACIONES_SUCCESS: {
      const { msg } = payload
      return {
        ...state,
        message: msg,
      };
    }

    case LiquidacionesActionTypes.SEND_LIQUIDACIONES_FAILURE: {
      const { error, msg } = payload
      return {
        ...state,
        message: msg,
        PDFGenerated: !error
      };
    }

    case LiquidacionesActionTypes.GENERATE_LIQUIDACIONES: {
      return {
        ...state,
        message: "generando PDFS",
      };
    }

    case LiquidacionesActionTypes.GENERATE_LIQUIDACIONES_SUCCESS: {
      const { totalCreados, completed } = payload
      return {
        ...state,
        message: `${ totalCreados } PDFs generados exitósamente!`,
        PDFGenerated: completed
      };
    }

    case LiquidacionesActionTypes.GENERATE_LIQUIDACIONES_FAILURE: {
      const { error, totalCreados, completed } = payload
      return {
        ...state,        message: `${ totalCreados } PDFs generados exitósamente!`,
        PDFGenerated: !error
      };
    }

    case LiquidacionesActionTypes.GENERATE_ONE_LIQUIDACIONES: {
      return {
        ...state,
        message: "",
      };
    }

    case LiquidacionesActionTypes.GENERATE_ONE_LIQUIDACIONES_SUCCESS: {
      const { totalCreados, completed } = payload
      return {
        ...state,
        message: `${ totalCreados } PDF generado exitósamente!`,
        PDFGenerated: completed
      };
    }

    case LiquidacionesActionTypes.GENERATE_ONE_LIQUIDACIONES_FAILURE: {
      const { error, totalCreados, completed } = payload
      return {
        ...state,
        message: `${ totalCreados } PDF generado exitósamente!`,
        PDFGenerated: !error
      };
    }


    case LiquidacionesActionTypes.INIT_PROGRESS_BAR: {
      return {
        ...state,
        progressBar: {
          ...state.progressBar,
          show: true
        }
      };
    }

    case LiquidacionesActionTypes.INIT_PROGRESS_BAR: {
      return {
        ...state,
        progressBar: {
          ...state.progressBar,
          show: true,
        }
      };
    }
   
    case LiquidacionesActionTypes.INIT_FAILURE_PROGRESS_BAR: {
      return {
        ...state,
        progressBar: {
          ...state.progressBar,
          state: 'failure'
        }
      };
    }

    case LiquidacionesActionTypes.UPDATE_PROGRESS_BAR: {
      const { percent, fielBase64, NOMBRE  } = payload
      return {
        ...state,
        progressBar: {
          ...state.progressBar,
          percent: percent
        },
        payload:state.payload.map( e => fielBase64 && NOMBRE && e.NOMBRE == NOMBRE ?
          Object.assign( e, {
            base64:fielBase64
          }): e
          )
      };
    }
    case LiquidacionesActionTypes.END_PROGRESS_BAR: {
      return {
        ...state,
        progressBar: {
          ...state.progressBar,
          show: false
        }
      };
    }


    default:
      return state;
  }
};
export default liquidacionesReducer;
