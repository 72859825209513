import axios from "axios";
axios.defaults.headers.common['Authorization'] = localStorage.getItem('auth_token') ;
const ServicioModulos={} 
const {REACT_APP_API_URL} = process.env;

///Servicio para listar Modulos
ServicioModulos.getModulosAxios = async ()=>{
  
   return await axios({
        method: 'get',
        url: `${REACT_APP_API_URL}/admin/api/modulos/register/`,
        responseType: 'json'
      }).then((response) =>response.data.datos)
      .catch(err=>err)
    
}

///Servicio para agregar Modulos
ServicioModulos.addModulosAxios=async(data)=>{
const {name, description, files,url} = data;
var formData = new FormData();
formData.append('image', files);
formData.append('name', name);
formData.append('url', url);
formData.append('description', description);
 
 return await axios({
  method: 'post',
  data: formData, // Le pasamos el formData
  headers: {'Content-Type': 'multipart/form-data'}, // El contenido debe ser tipo multipart/form-data
  url: `${REACT_APP_API_URL}/admin/api/modulos/register/`
}).then((response) => response.data)

}

///Servicio para eliminar Modulos
ServicioModulos.deleteModulosAxios=async(data)=>{
  return await axios({
    method: 'delete',
    url: `${REACT_APP_API_URL}/admin/api/modulos/register/del/${data}`,
    data: {
      _id:data
    }
    }).then((response) => response)
}

///servicio para modificar datos modulos
ServicioModulos.updateModulosAxios=async(data)=>{
  const { _id, name, description, files, url } = data;
  console.log(data, 'axios')
    // Creamos un objeto FormData y le appendeamos los datos desestructurados
    var formData = new FormData();
    formData.append('image', files);
    formData.append('name', name);
    formData.append('url', url);
    formData.append('description', description);
   
    
   return await axios({
      method: 'post',
      data: formData,  // Le pasamos el formData
      headers: { 'Content-Type': 'multipart/form-data' }, // El contenido debe ser tipo multipart/form-data
      url: `${REACT_APP_API_URL}/admin/api/modulos/register/edit/${_id}`,
    }).then((response) => response)


}
export default ServicioModulos;