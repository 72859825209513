import moment from 'moment';
import Snowfall from 'react-snowfall'


class Utils {
  /**
   * Get first character from first & last sentences of a username
   * @param {String} name - Username
   * @return {String} 2 characters string
   */
  static getNameInitial(name) {
    let initials = name.match(/\b\w/g) || [];
    return ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();
  }

  /**
   * Get current path related object from Navigation Tree
   * @param {Array} navTree - Navigation Tree from directory 'configs/NavigationConfig'
   * @param {String} path - Location path you looking for e.g '/app/dashboards/analytic'
   * @return {Object} object that contained the path string
   */
  static getRouteInfo(navTree, path) {
    if (navTree.path === path) {
      return navTree;
    }
    let route;
    for (let p in navTree) {
      if (navTree.hasOwnProperty(p) && typeof navTree[p] === "object") {
        route = this.getRouteInfo(navTree[p], path);
        if (route) {
          return route;
        }
      }
    }
    return route;
  }

  /**
   *
   * @param {data_i} hex
   * @param {dataUser} hex
   * @returns
   */
  static hola(data) { }

  // 	static filterData(data1, data2){
  // 		// let datos= data1.filter(op =>{
  // 		// 	let valor2 = data2.find(valor2 =>

  // 		// 			datos['userID']== valor2['userID']
  // 		// 		)
  // 		// 		return valor2
  // 		// })
  // 		// return datos
  // 	}
  /**
   * Get accessible color contrast
   * @param {String} hex - Hex color code e.g '#3e82f7'
   * @return {String} 'dark' or 'light'
   */
  static getColorContrast(hex) {
    if (!hex) {
      return "dark";
    }
    const threshold = 130;
    const hRed = hexToR(hex);
    const hGreen = hexToG(hex);
    const hBlue = hexToB(hex);
    function hexToR(h) {
      return parseInt(cutHex(h).substring(0, 2), 16);
    }
    function hexToG(h) {
      return parseInt(cutHex(h).substring(2, 4), 16);
    }
    function hexToB(h) {
      return parseInt(cutHex(h).substring(4, 6), 16);
    }
    function cutHex(h) {
      return h.charAt(0) === "#" ? h.substring(1, 7) : h;
    }
    const cBrightness = (hRed * 299 + hGreen * 587 + hBlue * 114) / 1000;
    if (cBrightness > threshold) {
      return "dark";
    } else {
      return "light";
    }
  }

  /**
   * Darken or lighten a hex color
   * @param {String} color - Hex color code e.g '#3e82f7'
   * @param {Number} percent - Percentage -100 to 100, positive for lighten, negative for darken
   * @return {String} Darken or lighten color
   */
  static shadeColor(color, percent) {
    let R = parseInt(color.substring(1, 3), 16);
    let G = parseInt(color.substring(3, 5), 16);
    let B = parseInt(color.substring(5, 7), 16);
    R = parseInt((R * (100 + percent)) / 100);
    G = parseInt((G * (100 + percent)) / 100);
    B = parseInt((B * (100 + percent)) / 100);
    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;
    const RR =
      R.toString(16).length === 1 ? `0${R.toString(16)}` : R.toString(16);
    const GG =
      G.toString(16).length === 1 ? `0${G.toString(16)}` : G.toString(16);
    const BB =
      B.toString(16).length === 1 ? `0${B.toString(16)}` : B.toString(16);
    return `#${RR}${GG}${BB}`;
  }

  /**
   * Convert RGBA to HEX
   * @param {String} rgba - RGBA color code e.g 'rgba(197, 200, 198, .2)')'
   * @return {String} HEX color
   */
  static rgbaToHex(rgba) {
    const trim = (str) => str.replace(/^\s+|\s+$/gm, "");
    const inParts = rgba.substring(rgba.indexOf("(")).split(","),
      r = parseInt(trim(inParts[0].substring(1)), 10),
      g = parseInt(trim(inParts[1]), 10),
      b = parseInt(trim(inParts[2]), 10),
      a = parseFloat(
        trim(inParts[3].substring(0, inParts[3].length - 1))
      ).toFixed(2);
    const outParts = [
      r.toString(16),
      g.toString(16),
      b.toString(16),
      Math.round(a * 255)
        .toString(16)
        .substring(0, 2),
    ];

    outParts.forEach(function (part, i) {
      if (part.length === 1) {
        outParts[i] = "0" + part;
      }
    });
    return `#${outParts.join("")}`;
  }

  /**
   * Returns either a positive or negative
   * @param {Number} number - number value
   * @param {any} positive - value that return when positive
   * @param {any} negative - value that return when negative
   * @return {any} positive or negative value based on param
   */
  static getSignNum(number, positive, negative) {
    if (number > 0) {
      return positive;
    }
    if (number < 0) {
      return negative;
    }
    return null;
  }

  /**
   * Returns either ascending or descending value
   * @param {Object} a - antd Table sorter param a
   * @param {Object} b - antd Table sorter param b
   * @param {String} key - object key for compare
   * @return {any} a value minus b value
   */
  static antdTableSorter(a, b, key) {
    if (typeof a[key] === "number" && typeof b[key] === "number") {
      return a[key] - b[key];
    }

    if (typeof a[key] === "string" && typeof b[key] === "string") {
      a = a[key].toLowerCase();
      b = b[key].toLowerCase();
      return a > b ? -1 : b > a ? 1 : 0;
    }
    return;
  }

  /**
   * Filter array of object
   * @param {Array} list - array of objects that need to filter
   * @param {String} key - object key target
   * @param {any} value  - value that excluded from filter
   * @return {Array} a value minus b value
   */
  static filterArray(list, key, value) {
    let data = list;
    if (list) {
      data = list.filter((item) => item[key] === value);
    }
    return data;
  }

  /**
   * Remove object from array by value
   * @param {Array} list - array of objects
   * @param {String} key - object key target
   * @param {any} value  - target value
   * @return {Array} Array that removed target object
   */
  static deleteArrayRow(list, key, value) {
    let data = list;
    if (list) {
      data = list.filter((item) => item[key] !== value);
    }
    return data;
  }

  /**
   * Wild card search on all property of the object
   * @param {Number | String} input - any value to search
   * @param {Array} list - array for search 6646650
   * @return {Array} array of object contained keyword
   */
  static wildCardSearch(list, input) {
    const searchText = (item) => {
      for (let key in item) {
        if (item[key] == null) {
          continue;
        }
        if (
          item[key]
            .toString()
            .toUpperCase()
            .indexOf(input.toString().toUpperCase()) !== -1
        ) {
          return true;
        }
      }
    };
    list = list.filter((value) => searchText(value));
    return list;
  }

  /***
   * resolve idUser x cedula
   */
  static resolveUserIdxCI(userId, userList) {
    if (!userId) {
      return "";
    }
    const filteredData = userList?.filter(function (val) {
      return val.ci == userId;
    });
    if (!filteredData[0]?._id) {
      return "";
    }
    ///	console.log(filteredData[0].apellidos + ", " + filteredData[0].nombres, 'utils');
    return filteredData[0]?._id;
  }

  /**
   * Resuelve nombre y apellido de usuario a partir de su id
   * @param {String} userId - id de usuario
   * @return {String} listado de usuarios
   */
  static resolveUserNames(userId, userList) {
    if (!userId) {
      return "";
    }
    const filteredData = userList?.filter(function (val) {
      return val._id == userId;
    });
    if (!filteredData[0]?.apellidos) {
      return "";
    }
    ///	console.log(filteredData[0].apellidos + ", " + filteredData[0].nombres, 'utils');
    return filteredData[0]?.apellidos + ", " + filteredData[0].nombres;
  }
  /**
   * Formatea fecha para mostrar en datatables
   * @param {Date} date - Fecha
   */
  static getFullDate(date) {
    const dateAndTime = date.split("T");

    return dateAndTime[0].split("-").reverse().join("-");
  }

  /**
   * Resuelve id de usuario a partir de su nombre y apellido
   * @param {String} userData - Nombre y Apellido en formato <Apellido, Nombre>
   * @param {String} - Listado de usuarios
   */
  static resolveUserId(userData, users) {
    if (!userData) {
      return null;
    }
    const filteredData = users.filter(function (val) {
      return val.apellidos + ", " + val.nombres == userData;
    });
    return filteredData[0]._id;
  }

  /**
   * Realiza una operación entre 2 fechas y lo convierte a números.
   * @param {Date} row - Fecha
   */
  static dateToNumber = (row) => {
    const day1 = new Date(row).getTime();
    const day2 = new Date(Date.now()).getTime();
    if (day1 > day2) {
      const subtraction = Math.round((day1 - day2) / (1000 * 3600 * 24));
      return subtraction;
    }
    const subtraction = Math.round((day2 - day1) / (1000 * 3600 * 24));
    return subtraction;
  };

  /**
   * Realiza una operación entre 2 fechas y lo convierte a números es utilizado para los porcentajes.
   * @param {Date} row - Fecha
   */
  static dateToNumberPercentage = (dayOne) => {
    const day1 = new Date(dayOne).getTime();
    const day2 = new Date(Date.now()).getTime();
    const subtraction = Math.round(
      Math.abs((day2 - day1) / (1000 * 3600 * 24))
    );
    return subtraction;
  };

  /**
   *
   * @param {Funcion que convierte numeros con separadores de miles} data
   * @param {*} usuarios
   * @returns
   */
  ///Funcion que convierte numeros a decimales
  static formatNumberMiles = (num) => {
    if (!num || num == "NaN") return "-";
    if (num == "Infinity") return "&#x221e;";
    num = num.toString().replace(/\$|\,/g, "");
    if (isNaN(num)) num = "0";
    let sign = num == (num = Math.abs(num));
    num = Math.floor(num * 100 + 0.50000000001);
    let cents = num % 100;
    num = Math.floor(num / 100).toString();
    if (cents < 10) cents = "0" + cents;
    for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++)
      num =
        num.substring(0, num.length - (4 * i + 3)) +
        "." +
        num.substring(num.length - (4 * i + 3));
    ///	return (((sign) ? '' : '-') + num + ',' + cents); retorna con gs ejemplo 1.000,5gs
    return (sign ? "" : "-") + num;
  };

  /**
   *
   * @param {resulve problemas} dateTime
   * @returns
   */
  static dataModulosUser = (data, usuarios) => {
    if (!usuarios) {
      return null;
    }
    const filterModulo = usuarios?.filter(function (v) {
      const eva = v.ci === data;
      return eva;
    });
    return filterModulo;
  };
  /* data limpia*
   *
   * @param {conversor} dateTime
   * @returns
   */

  static converArrayObjeto = (dataRecibida) => {
    let aux = [];
    for (const [key, value] of Object.entries(dataRecibida)) {
      aux.push(value);
    }
    return aux;
  };

  /**
   *
   * @param {retorna valor unico objeto de un array} dateTime
   * @returns
   */

  static valorUnicoObjeto = (valor) => {
    const unicos = valor.reduce((accArr, valor) => {
      if (accArr.indexOf(valor) < 0) {
        console.log(valor, "valor");
        accArr.push(valor);
      }
      return accArr;
    }, []);
    return unicos;
  };

  /**
   * Realiza una operación de fechas y lo convierte al formato de SQL.
   * @param {Date} dateTime - Fecha
   */
  static sqlDateTime = (dateTime) => {
    const operation = new Date(dateTime)
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    const newFormat = operation.slice(0, 10).concat(" 00:00:00");
    return newFormat;
  };

  /**
   * Get Breakpoint
   * @param {Object} screens - Grid.useBreakpoint() from antd
   * @return {Array} array of breakpoint size
   */
  static getBreakPoint(screens) {
    let breakpoints = [];
    for (const key in screens) {
      if (screens.hasOwnProperty(key)) {
        const element = screens[key];
        if (element) {
          breakpoints.push(key);
        }
      }
    }
    return breakpoints;
  }

  /**
   * Función para sumar dias a la fecha recibida
   * @param {Date} date - fecha recibida para la suma de dias
   * @param {Number} days - Recibe la cantidad de dias
   * @return {String} 2 characters string
   */
  static sumDays = (date, days) => {
    date.setDate(date.getDate() + days);
    return date;
  };

  /**
   * Función para crear un array de objetos de rangos de dias
   * @param {Object} object - cantidad de solicitudes anteriores
   * @param {Date} date1 - fecha inicio de vacacion
   * @param {Date} date2 - fecha final de vacacion
   * @return {Array}  -  Retorna un array de objetos 
   * de fehcas
   * Esta funcion será utilizada para la lógica M
   */
  static arrayVacationRange = (object, date1, date2) => {

    let arrVacation = [];
    let arrVacationSelection = {
      vacationStart: moment(date1).format("DD-MM-YYYY"),
      vacationEnd: moment(date2).format("DD-MM-YYYY"),
    };

    object.map((e) => {
      const saveRangeVacation = {
        vacationStart: moment(e.vacationStart).format("DD-MM-YYYY"),
        vacationEnd: moment(e.vacationEnd).format("DD-MM-YYYY"),
      };

      arrVacation.push(saveRangeVacation);
    });
    arrVacation.push(arrVacationSelection);

    return arrVacation;
  };


  /**
   * Función para crear un nuevo formato de fecha final de vacaciones 
   * @param {Date} dateTime - fecha recibida para la manipulacion
   * @param {Date} dateTime2 - fecha recibida para la manipulacion
   * @return {Date}  -  Retorna un nueva fecha
   * Esta funcion será utilizada para la lógica M
   */
  static MDateTime = (dateTime,) => {
    const operation = new Date(dateTime)
      .toISOString();
    const newFormat = operation.slice(0, 11).concat("19:00:00.000Z");
    return new Date(newFormat);
  };

  /**
   * Función para crear un nuevo formato de fecha final de vacaciones 
   * @param {Object} userInformation - objecto de usuarios
   * @param {String} userIdToken - id del usuario
   * @return {String}  -  Retorna id del leader
   * Esta funcion será utilizada para la lógica M
   */
  static getLeaderId = (userInformation, userIdToken) => {

    // Obtiene los datos del usuario
    const getCurrentUser = userInformation.filter(function (val) {
      return val._id === userIdToken;
    });

    // Obtiene los datos del jefe directo
    const leaderCi = userInformation.filter(function (val) {
      return val.ci === getCurrentUser[0].jefe_directo;
    });

    return leaderCi[0] ? leaderCi[0]._id : null;

  }

  /**
   * Función para crear un array un objecto con los datos personales del usuario
   * @param {String} valor - id del usuario
   * @param {Object} users - objeto de usuarios
   * @return {Array}  -  Retorna un array de datos personales
   * Esta funcion será utilizada para la lógica M
   */
  static resolveIngreso = (valor, users) => {
    const evaluar = users.filter(function (v) {
      return v._id == valor;
    });

    return [
      evaluar[0]?.employeeStartDate,
      evaluar[0]?.nif,
      evaluar[0]?.idAplicacion,
    ];
  };

  /**
   * Función que extrae el nombre y ci del usuario
   * @param {String} userId - id del usuario
   * @param {Object} userInformation - objecto de usuarios
   * @return {String}  -  Retorna un el nombre, apellido y ci concatenado
   * Esta funcion será utilizada para la lógica M
   * eliminar mas adelante
   */
  static resolveUserNames = (userId, userInformation) => {
    if (!userId) {
      return "";
    }
    const filteredData = userInformation.filter(function (val) {
      return val._id == userId;
    });

    if (!filteredData[0]) {
      return "";
    }
    return (
      filteredData[0].apellidos +
      ", " +
      filteredData[0].nombres +
      " - " +
      filteredData[0].ci
    );
  }

  /**
   * Función para condicionar las aprobaciones en forma ascendente
   * @param {Array} value1 - Array de fechas
   * @param {Date} value2 - fecha de la solictud a comparar
   * @return {Boolean}  -  Retorna un booleano
   */
  static isTrue = (value1, value2) => {
    const solicitudFiltered = value1.filter(data => data.flag === false && data.state === "Pendiente");
    let arr = [];

    solicitudFiltered.map((v) => {
      return arr.push(new Date(v.vacationEnd).getTime());
    })

    const valueIsTrue = arr.every((isTrue) => new Date(value2).getTime() < isTrue || new Date(value2).getTime() === isTrue);

    return valueIsTrue;
  }

  /**
   * Función para calcular el día mayor entre fechas
   * @param {Object} countSolicitudes - objeto de solicitudes
   * @param {Object} payload - fecha de la solictud a comparar
   * @return {Date}  -  Retorna una fecha mayor a todos
   */
  static calculateMaxDate = (countSolicitudes, payload) => {

    const isTrue = countSolicitudes.msg ? false : true;
    let arr = [];

    arr.push(new Date(payload.vacationStart).getTime())

    if (isTrue) {
      countSolicitudes.map((v) => {
        return arr.push(new Date(v.vacationStart).getTime());
      })
    }

    let maxDate = Math.max(...arr);

    return new Date(maxDate).toISOString();
  }

  /**
   * Función para calcular la el día mayor
   * @param {Date} dateStart - fecha que el será sumado
   * @return {Date}  -  Retorna una fecha nueva
   */
  static sumMonth = (dateStart) => {
    const newDate = new Date(new Date(dateStart).setDate(1)).setMonth(new Date(dateStart).getMonth() + 3);
    return new Date(newDate);
  }


  /**
    * Función para retornar el nro de cédula del usuario
    * @param {String} data - nro de ci
    * @param {Array} userListData - lista de usuarios
    * @return {String}  -  Retorna el nro de CI
    */
  static resolveCi = (data, userListData) => {
    let v = userListData.filter((v) => v._id === data);
    return v[0]?.ci;
  };


  /**
  * Función para retornar el correo electrónico del usuario
  * @param {String} valor - correo electrónico
  * @param {Array} users - lista de usuarios
  * @return {String}  -  Retorna el correo electrónico
  */
  static resolveEmail = (valor, users) => {
    const evaluar = users.filter(function (op) {
      return op._id == valor;
    });

    return evaluar[0]?.correo;
  };

  /**
  * Función para filtar las firmas de los comprobantes del usuario
  * @param {Array} array1 - estado d firmas de las solicitudes
  * @param {Array} array2 - lista de solicitudes
  * @return {Object}  -  Retorna el comprobante con el estado firmado
  */
  static filtraFirmas = (array1, array2) => {
    var resultado = [];
    for (var i = 0; i < array1?.length; i++) {
      for (var j = 0; j < array2?.length; j++) {
        const validar = array1.filter((v) => v.origin === array2[j]._id);
        if (validar[i]?.state == "Firmado") {
          let estadoFirma = validar[i].state;
          let id = validar[i].origin;
          let tipo = validar[i].type;

          resultado.push({ id, estadoFirma, tipo });
        }
      }

      return resultado;
    }
  };

  static formattedUserName = (user) => {
    return user && user.nombres + ", " + user.apellidos + " - " + user.ci;
  }

  static formattedLeaderName = (leader) => {
    return leader.leader?.nombres + ", " + leader.leader?.apellidos + " - " + leader.leader?.ci;
  }

  static returnLeaderId = (leader) => {
    return leader.leader?._id;
  }

  static conditionalVacation = (rangePickerValue, solicitud) => {
    const object = {
      date1: typeof rangePickerValue === 'undefined' ? solicitud.vacationStart : rangePickerValue[0],
      date2: typeof rangePickerValue === 'undefined' ? solicitud.vacationEnd : rangePickerValue[1]
    }
    return object
  }

  /**
 * Objeto que retorna la reglas para los inputs
 * @return {Object}  -  Retorna las reglas de los inputs
 */
  static rules = {
    name: [
      {
        required: true,
        message: "Por Favor Ingrese el nombre del rol",
      },
    ],
    permisos: [
      {
        required: true,
        message: "Agregar los permisos",
      },
    ],
    usuario: [
      {
        required: true,
        message: "Por favor seleccione el usuario",
      },
    ],

    tipo: [
      {
        required: true,
        message: "Por favor seleccione el tipo de comprobante",
      },
    ],
    periodo: [
      {
        required: true,
        message: "Por favor seleccione el rango de fechas",
      },
    ],
    cantidadDias: [
      {
        required: true,
        message: "Por favor ingrese la cantidad de días",
        pattern: new RegExp(/^[0-9]+$/),
      },
    ],
    validation: [
      {
        required: true,
        message: "Favor agregar los datos solicitados",
      },
    ],
    validationNumber: [
      {
        pattern: new RegExp(/^\d{1,2}(\.\d{1,2})?$/),
        required: true,
        message: "Favor agregar una tasa válida",
      },
    ],
    ValidationPresupuesto:[
      {
        required: true,
        message: "Favor complete todos los campos",
      },
    ]
  };

  /**
  * Función para retornar el periodo actual del usuario
  * @param {Date} employeeStartDate - fecha de la contratacion del  usuario
  * @return {Object}  -  Retorna de fechas
  */
  static getCurrentPeriod =(employeeStartDate)=>{
    const employeeStartDateFmt = new Date(employeeStartDate);
   
    const currYear = new Date().getFullYear();
    const yearToAdd = currYear - employeeStartDateFmt.getFullYear()
    let now = new Date(); // Fecha actual
    now.setHours(0,0,0,0) // Cerea la parte horaria de la fecha
    let employeeStartDateFmtCopy0 = new Date(employeeStartDate);

    const isNewPeriod = now >= new Date(employeeStartDateFmtCopy0.setFullYear(employeeStartDateFmtCopy0.getFullYear() + yearToAdd)) ? true : false;
    
    let employeeStartDateFmtCopy1 = new Date(employeeStartDate);
    
    let employeeStartDateFmtCopy2 = new Date(employeeStartDate);
    
    const empStartDateNew = isNewPeriod ? new Date(employeeStartDateFmtCopy1.setFullYear(employeeStartDateFmtCopy1.getFullYear() + yearToAdd)) :
    
    new Date(employeeStartDateFmtCopy1.setFullYear(employeeStartDateFmtCopy1.getFullYear() + (yearToAdd - 1)));
    
    const empEndDateNew = isNewPeriod ? new Date(employeeStartDateFmtCopy2.setFullYear(employeeStartDateFmtCopy2.getFullYear() + (yearToAdd + 1))) :
    
    new Date(employeeStartDateFmtCopy2.setFullYear(employeeStartDateFmtCopy2.getFullYear() + yearToAdd));
    
    return {empStartDateNew:empStartDateNew,empEndDateNew: empEndDateNew}
  }

  /**
  * Función para retornar el periodo actual del usuario
  * @param {Date} fechaIngreso - fecha de ingreso
  * @param {Array} validationState - Array de contratos
  * @param {Function} utils - Funcion del util
  * @param {Date} fechaAcreditacion - Fecha de acreditacion
  * @param {Date} mesCertificado - Mes del certificado
  * @return {Object}  -  Retorna un objeto de datos
  */
  static utilSetData = (fechaIngreso, validationState, utils, fechaAcreditacion, mesCertificado) => {
    //? Se realizan algunos calculos para obtener el valor de dias Imputables al Contratista
    const newFechaPlazo = utils.sumDays(new Date(fechaIngreso), validationState[0]?.diasVencimiento);

    const subtractionOperation = fechaAcreditacion?.getTime() - newFechaPlazo.getTime();

    const resultSubstracion = Math.round(subtractionOperation / (1000 * 60 * 60 * 24));

    const resultImptContratista = resultSubstracion > 0 ? resultSubstracion : 0;

    //? se obtiene la fecha de pago de factura
    const resultFechaPagoFactura = moment(newFechaPlazo);

    //? Se calcula la fecha anterior
    const calculeDate = new Date(mesCertificado);
    calculeDate.setMonth(calculeDate.getMonth() - 1);

    const resultMesAnteriorObligacion = moment(calculeDate.getFullYear() + "/" + (calculeDate.getMonth() + 1) + "/" + (calculeDate.getDay() + 1));
    const object = { resultImptContratista, resultFechaPagoFactura, resultMesAnteriorObligacion };

    return object
  }

/**
 * Retorna de forma condicional el renderizado del componente SnowFall
 * @param {component} 
 * @returns {component}
 */
  static renderSnowFall = (component) => {
    const date = new Date(Date.now()).getMonth() + 1;
    return date === 12 ? component : null;
  }


  /**
 * Retorna un array de imagenes para el menu principal a modo de optimizar
 * @returns {array}
 */
  static carouselImgPrincipal = () => {
    return [
      'https://images.pexels.com/photos/14821282/pexels-photo-14821282.jpeg',
      'https://images.pexels.com/photos/14821286/pexels-photo-14821286.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
      'https://images.pexels.com/photos/14821285/pexels-photo-14821285.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
      'https://images.pexels.com/photos/14821283/pexels-photo-14821283.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
      'https://images.pexels.com/photos/14821289/pexels-photo-14821289.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
      'https://images.pexels.com/photos/14831072/pexels-photo-14831072.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'

    ]
  }
}
export default Utils;