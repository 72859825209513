///CONSTANTES MODULOS
export const GOT_POSTS = 'GOT_POSTS';
export const CONSULTA_MODULOS_GET = 'CONSULTA_MODULOS_GET'
export const ADD_MODULOS_POST = 'ADD_MODULOS_POST'
export const DELETE_MODULOS_POST = 'DELETE_MODULOS_POST'
export const UPDATE_MODULOS_POST = 'UPDATE_MODULOS_POST'
export const MODULOS_SUCCESS = 'MODULOS_SUCCESS'
export const CONSULTA_MODULOS_EXITOSO = 'CONSULTA_MODULOS_EXITOSO'
export const SHOW_AUTH_MESSAGE = 'SHOW_AUTH_MESSAGE'
export const SHOW_LOADING = 'SHOW_LOADING'


export default {
    GOT_POSTS,
    CONSULTA_MODULOS_GET,
    ADD_MODULOS_POST,
    DELETE_MODULOS_POST,
    UPDATE_MODULOS_POST,
    MODULOS_SUCCESS,
    CONSULTA_MODULOS_EXITOSO,
    SHOW_AUTH_MESSAGE,
    SHOW_LOADING
}