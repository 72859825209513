import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';

const Apps = ({ match }) => (
  <Suspense fallback={<Loading cover="content"/>}>
    <Switch>
      <Route path={`${match.url}/portal-reportes/reportes`} component={lazy(() => import(`./reportes`))} />
      {/* <Route path={`${match.url}/portal-admin/roles`} component={lazy(() => import(`./roles`))} /> */}
      <Route path={`${match.url}/portal-reportes/usuarios`} component={lazy(() => import(`./usuarios`))} />
      <Route path={`${match.url}/portal-reportes/visualizar`} component={lazy(() => import(`./reportes-utils`))} />
      <Redirect from={`${match.url}`} to={`${match.url}/portal-reportes/reportes`} />
    </Switch>
  </Suspense>
);

export default Apps;