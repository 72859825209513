import { put, call } from "redux-saga/effects";
import ReportesActions from '../../actions/servicio-reportes/Reportes'
import { message } from "antd";
import servicioReportes from "axios/servicio-reportes/Reportes";


//? Listar Reportes de reportes
export function* listReportes () {
  try {
    const listReportes = yield call( servicioReportes.getReportesAxios );
    if (!listReportes.error) {
      yield put(ReportesActions.successReportesAction(listReportes));
    }
  } catch (err) {
    yield message.error('Hubo un error al listar los reportes');
  }
}


//? Agregar Reportes de reportes
export function* addReportes ({ payload }) {
  try {
    const addReportes = yield call(
      servicioReportes.addReportesAxios,
      payload
    );
    
    if (addReportes.msg) {
      yield message.error(addReportes.msg);
    } else {
      
      yield put(ReportesActions.SuccessAddReportesAction());
      yield put(ReportesActions.listReportesAction())
    }
  } catch (err) {
    yield message.error(err.message);
  }
}

//? Eliminar Reportes de reportes
export function* deleteReportes ({ payload }) {
  try {
    const deleteReportes = yield call(
      servicioReportes.deleteReportesAxios,
      payload
    );
    
    console.log('deleteReportes: ', deleteReportes)
    if (deleteReportes.msg) {
      message.success("¡Reporte Eliminado!");
      yield put(ReportesActions.successDeleteReportesAction());
      yield put(ReportesActions.listReportesAction())
    }
  } catch (err) {
    message.error(err.message);
  }
}

//? Eliminar Actualizar de reportes
export function* updateReportes({ payload }) {
  try {
    const updateCertifcados = yield call(
      servicioReportes.updateReportesAxios,
      payload
    );

    if (updateCertifcados) {
      message.success("¡Reporte Editado!");

      yield put(ReportesActions.successUpdateReportesAction());
    }
  } catch (err) {
    message.error(err.message);
  }
}

export default {
    listReportes,
    addReportes,
    deleteReportes,
    updateReportes
}