import usersConstants from '../../constants/servicio-gestion-vacaciones/Users';
  
  const initState = {
    role: '',
    payload: [],
    ci: '',
    user:{},
    loading: false,
    update: false,
    dataUserVaca:[]
  };

  
    const solicitudesUsersReducer =(state = initState, action)=>{
        const { type, payload } = action
        switch(type){
          
          case usersConstants.CONSULTA_USERS_SUCCESS: {
            return {
              ...state,
              payload: payload,
              loading: false
            };
          }
          
            case usersConstants.GET_USER: {
              return {
                ...state,
                loading:false
              };
            }
            case usersConstants.GET_USER_SUCCESS: {
              return {
                ...state,
                role: payload,
                loading: false
              };
            }
            
            case usersConstants.USER_SUCCESS_ONLY_VACACACIONES: {
              console.log('USER_SUCCESS_ONLY_VACACACIONES: ', payload)
              return {
                ...state,
                ci: payload,
                user: state.dataUserVaca.map( e => e.ci === payload && e ).filter(Boolean)[0],
                loading: false
                 
              };
            }
            case usersConstants.GET_FULL_USERS: {
              return {
                ...state,
                loading: true,
              };
            }
            case usersConstants.GET_FULL_USERS_SUCCESS: {
              return {
                ...state,
                dataUserVaca: payload,
                loading: false,
             
              };
            }
                    
            case usersConstants.EDIT_USER_SUCCESS:
            case usersConstants.CREATE_USER_SUCCESS: {
              return {
                ...state,
                loading: false,
                update: false
                
              };
            }
            case usersConstants.CREATE_USER:
            case usersConstants.EDIT_USER: {
              return {
                ...state,
                loading: true,
		            update: true
              }; 
            }
            
            default:
              return state
          }
        }

 
export default solicitudesUsersReducer
