import homeConstants from '../../constants/servicio-autenticacion/Home';
  
  export const getModulesAction = () => {
    return {
      type: homeConstants.GET_MODULES,
    }
  };


  export const modulesSuccessAction = (data) => {
    return {
      type: homeConstants.MODULES_SUCCESS,
      payload: data
    }
  };