import  userAdminConstants from "../../constants/servicio-autenticacion/UserAdmin";

const initState = {
  payload: [],
  loadingA: false,
  redirect: "",
  ci:"",
  role:""
};

const userAdminReducer = (state = initState, action) => {
 const { type, payload } = action

  switch (type) {
    
    case userAdminConstants.USER_SUCCESS_EDIT_ADMIN: 
    case userAdminConstants.USER_SUCCESS_ADMIN: {
      return {
        ...state,
        payload: payload,
        loadingA: false,
       
      };
    }

    case userAdminConstants.USER_EDIT_ADMIN: 
    case userAdminConstants.USER_GET_ADMIN:
    case userAdminConstants.USER_ONLY_ADMIN: {
      return {
        ...state,
        loadingA: true,
       
      };
    }
    case userAdminConstants.USER_SUCCESS_ONLY_ADMIN_CI: {
      return {
        ...state,
        ci: payload,
        loadingA: false,
       
      };
    }
    case userAdminConstants.USER_SUCCESS_ONLY_ADMIN: {
   
      return {
        ...state,
        role: payload,
        loadingA: false,
       
      };
    }
    
    default:
      return state;
  }
};
export default userAdminReducer;
