import axios from "axios";
axios.defaults.headers.common['Authorization'] = localStorage.getItem('auth_token');

const serviceUsers = {};
const { REACT_APP_API_URL } = process.env;

//? Esta solicitud trae todos los usuarios de dominio -> Endpoint de Servicio de Autenticacion
//! Verificar para mover o no
serviceUsers.consultUsersDomainAxios = async () => {
  return await axios({
    method: "get",
    url: `${REACT_APP_API_URL}/admin/api/user/list`,
    responseType: "json",
  })
    .then((response) => response.data)
    .catch((err) => err);
};

//? lista todo los usuarios dde contratos
serviceUsers.listUsersContratosAxios = async () => {
  return await axios({
    method: "get",
    url: `${REACT_APP_API_URL}/contratos/api/userscontratos/listAll`,
    responseType: "json",
  })
    .then((response) => response.data)
    .catch((err) => err);
};

//? Lista solo un usuario
serviceUsers.listOneUsersContratosAxios = async (data) => {
  const ci = data
  return await axios({
    method: "post",
    url: `${REACT_APP_API_URL}/contratos/api/userscontratos/list/`,
    data: {
      ci: ci,
    },
  })
    .then((response) => response.data.msg)
    .catch((err) => err);
};

//? Crea nuevo usuario
serviceUsers.createUserContratosAxios = async (data) => {
  const { correo, nombres, apellidos, ci, rol } = data;
  return await axios({
    method: "post",
    url: `${REACT_APP_API_URL}/contratos/api/userscontratos/create`,

    data: {
      correo: correo,
      nombres: nombres,
      apellidos: apellidos,
      ci: ci,
      rol: rol,
    },
  })
    .then((response) => response.data)
    .catch((err) => err);
};

//? Edita los usuarios
serviceUsers.editUserContratosAxios = async (data) => {
  const { _id, correo, nombres, apellidos, ci, rol } = data;
  return await axios({
    method: "post",
    url: `${REACT_APP_API_URL}/contratos/api/userscontratos/edit/${_id}`,
    data: {
      correo: correo,
      nombres: nombres,
      apellidos: apellidos,
      ci: ci,
      rol: rol,
    },
  })
    .then((response) => response)
    .catch((err) => err);
};

//? Eliminar  usuarios
serviceUsers.deleteUserContratosAxios = async (data) => {
  return await axios({
    method: "delete",
    url: `${REACT_APP_API_URL}/contratos/api/userscontratos/delete/${data}`,
  })
    .then((response) => response)
    .catch((err) => err);
};

//? servicio de recuperar datos de un usuario por id -> Endpoint del servicio de vacaciones
//!Verficar para mover o no
serviceUsers.listOneUserDataAxios = async (data)=>{ 
  const idUser= data;
    return await   axios({
        method: "get",
        url: `${REACT_APP_API_URL}/admin/api/user/list/${idUser}`,
        responseType: "json",
      }).then((response) => response.data);

}

export default serviceUsers;
