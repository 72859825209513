import axios from "axios";
axios.defaults.headers.common['Authorization'] = localStorage.getItem('auth_token');

const serviceUsers = {};

const { REACT_APP_API_URL } = process.env;
serviceUsers.consultaSolicitudesAxios = async () => {
  return await axios({
    method: "get",
    url: `${REACT_APP_API_URL}/admin/api/user/list`,
    responseType: "json", 
  })
    .then((response) => response.data)
    .catch((err) => err);
};


serviceUsers.getUserDataSolicitudesAxios = async (data) => {
  return await axios({
    method: "post",
    url: `${REACT_APP_API_URL}/admin/api/user/oneUser`,
    responseType: "json", 
    data:{ id: data }
  })
    .then((response) => response.data)
    .catch((err) => err);
}



///listado de usuarios de en bd de vacaciones
serviceUsers.listadoUsuariosVacaciones = async () => {
  
  return await axios({
    method: "get",
    url: `${REACT_APP_API_URL}/vacaciones/api/usersvacaciones/listAll`,
    responseType: "json",
  })
    .then((response) => response.data.data)
    .catch((err) => err);
};

////listado de usuario por ci
serviceUsers.listadoOneUsuariosVacaciones = async (data) => {
  return await axios({
    method: "post", // Cambiar a GET
    url: `${REACT_APP_API_URL}/vacaciones/api/usersvacaciones/list`,
    data: {
      ci: data
    }
  })
    .then((response) => response.data.msg)
    .catch((err) => err);
};

serviceUsers.createUserVaciones = async( data ) => {
  const { correo, nombres, apellidos, ci, rol, canDelegate } =  data;
  
  return await axios({
    method: "post",
    url: `${REACT_APP_API_URL}/vacaciones/api/usersvacaciones/create`,
    // url: 'http://localhost:4002/api/usersvacaciones/create', 
    data: { correo, nombres, apellidos, ci, rol, canDelegate },
  }).then( ( response ) => response.data )
  .catch((err) => err);

}

serviceUsers.editUserVaciones = async (data) => {
  const { _id,correo, nombres,apellidos,ci,rol, inactivo=false,canDelegate} =  data;
  
  return await axios({
    method: "post",
    url: `${REACT_APP_API_URL}/vacaciones/api/usersvacaciones/edit/${_id}`,
    // url: `http://localhost:4002/api/usersvacaciones/edit/${_id}`,
    data: { correo, nombres, apellidos, ci, rol, canDelegate  },
  }).then((response) => response)
  .catch((err) => err);
};


serviceUsers.deleteUserVacacion = async (data) => {
 
  console.log(data, 'data axios')
  
  return await axios({
    method: "delete",
    url: `${REACT_APP_API_URL}/vacaciones/api/usersvacaciones/delete/${data}`,
 
  }).then((response) => response)
  .catch((err) => err);
};



export default serviceUsers;