import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom';
import PresupuestoProcesar from "./procesar-presupuesto";
import withRole from '../HOC/withRoles';

//? Definicion de reglas de roles
const restrictedProcesar = withRole(true, ['admin'], PresupuestoProcesar)

const Comprobantes = props => {
	const { match } = props
	return (
		<Switch>
			<Redirect exact from={`${match.url}`} to={`${match.url}/list-presupuesto`} />
			<Route path={`${match.url}/list-presupuesto`} component={restrictedProcesar} />
	
		</Switch>
	)
}

export default Comprobantes

