import { createSelector } from 'reselect'

export const selectUsers = (state) => state.user
export const selectUserscurrentUser = (state) => state.currentUser

export const selectUserLoading = createSelector(
   [selectUsers],
    user => user.loaging
)

export const selectUserMessage = createSelector(
    [selectUsers],
     user => user.message
 )
 
export const selectUserShowMessage = createSelector(
    [selectUsers],
    user => user.showMessage
)

 
export const selectUserRedirect = createSelector(
    [selectUsers],
    user => user.redirect
)
 
export const selectedUserModulos = createSelector(
    [selectUsers],
    user => user.selectedUserModulos
)

export const selectLoadingModulos = createSelector(
    [selectUsers],
    user => user.loadingModule
)

export const selectAllUsers = createSelector(
    [selectUsers],
    user => user.payload
)

export const selectCurrentUser = createSelector(
    [selectUsers],
    user => user.currentUser
)


export const selectOneUser = id => createSelector(
    [selectUsers],
    user => user.payload.map( e => e._id === id && e ).filter(Boolean)[0]
)

