import axios from "axios";
axios.defaults.headers.common['Authorization'] = localStorage.getItem('auth_token') ;

const ServicioReportes = {};
const { REACT_APP_API_URL } = process.env;

//? Listar Reportes.
ServicioReportes.getReportesAxios = async () => {
  return await axios({
    method: "get",
    url: `${REACT_APP_API_URL}/reportes/api/reportes/list`,
    responseType: "json",
  }).then(response => response.data)
    .catch(err => err);
};


//? Listar Un Reportes.
ServicioReportes.getOneReporteAxios = async (data) => {
  const { id } = data
  return await axios({
    method: "get",
    url: `${REACT_APP_API_URL}/reportes/api/reporte/listone`,
    params: { _id: id},
    responseType: "json",
  })
    .then((response) => response.data)
    .catch((err) => err);
};


//? Agregar Reportes.
ServicioReportes.addReportesAxios = async (data) => {
  return await axios({
    method: "post",
    url: `${REACT_APP_API_URL}/reportes/api/reportes/create`,
    // url: `http://localhost:4003/api/reportes/create`,
    data: { ...data },
  })
    .then((response) => response.data)
    .catch((err) => err);
};

//? Eliminar Reportes.
ServicioReportes.deleteReportesAxios = async (data) => {
  return await axios({
    method: "delete",
    url: `${REACT_APP_API_URL}/reportes/api/reportes/delete`,
    // url: `http://localhost:4003/api/reportes/delete`,
    params: {_id:data},
  }).then((response) => response.data);
};

//? Actualizar Reportes.
ServicioReportes.updateReportesAxios = async (data) => {

  return await axios({
    method: "post",
    url: `${REACT_APP_API_URL}/reportes/api/reportes/edit`,
    // url: `http://localhost:4003/api/reportes/edit`,
    data: { ...data },
  })
    .then((response) => response)
    .catch((err) => err);
};

export default ServicioReportes;
