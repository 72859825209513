import rolesConstants from '../../constants/servicio-autenticacion/Roles';

const initState = {
  payload:[],
  loading: false,
  message: '',
  showMessage: false,
  redirect: '',

};

  const rolesReducer =(state = initState, action)=>{
    const { type, payload } = action
      switch(type){

        case rolesConstants.DELETE_ROLES_POST:
        case rolesConstants.ADD_ROLES_POST: {
          return {
            ...state,
            payload: payload,
            loading: false
          };
        }
        case rolesConstants.CONSULTA_ROLES_GET: {
          return {
            ...state,
            loading: true,
            message:'',
            redirect:'/app/apps/roles'
          };
        }
        case rolesConstants.SHOW_LOADING: {
          return {
            ...state,
            loading: true
          }
        }
        case rolesConstants.ROLES_SUCCESS: {
          return {
            ...state,
            loading: true,
            payload: payload,
            redirect:'/app/apps/portal-admin/roles',
          }
        }
        case rolesConstants.SHOW_AUTH_MESSAGE: 
        return {
          ...state,
          message: payload,
          showMessage: true,
          loading: false
        }
        case rolesConstants.UPDATE_ROLES_POST: {
           return {
             ...state,
             loading: false
           };
        }

        default:
          return state;
      }
    }
  export default rolesReducer;