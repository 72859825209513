//CONSTANTES AUTH
export const SIGNIN = 'SIGNIN'; 
export const AUTHENTICATED = 'AUTHENTICATED';
export const SIGNOUT = 'SIGNOUT';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS'
export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SHOW_AUTH_MESSAGE = 'SHOW_AUTH_MESSAGE'
export const HIDE_AUTH_MESSAGE = 'HIDE_AUTH_MESSAGE'
export const SHOW_LOADING = 'SHOW_LOADING'
export const AUTH_TOKEN = 'auth_token'
export const SIGNIN_WITH_GOOGLE = 'SIGNIN_WITH_GOOGLE'
export const SIGNIN_WITH_GOOGLE_AUTHENTICATED = 'SIGNIN_WITH_GOOGLE_AUTHENTICATED'
export const SIGNIN_WITH_FACEBOOK = 'SIGNIN_WITH_FACEBOOK'
export const SIGNIN_WITH_FACEBOOK_AUTHENTICATED = 'SIGNIN_WITH_FACEBOOK_AUTHENTICATED'
export const SIGNIN_WITH_API = 'SIGNIN_WITH_API';
export const USER_NAME = 'USER_NAME';
export const USER_ID = 'USER_ID';


export default {
    SIGNIN ,
    AUTHENTICATED, 
    SIGNOUT,
    SIGNOUT_SUCCESS, 
    SIGNUP,
    SIGNUP_SUCCESS, 
    SHOW_AUTH_MESSAGE, 
    HIDE_AUTH_MESSAGE, 
    SHOW_LOADING,
    AUTH_TOKEN, 
    SIGNIN_WITH_GOOGLE,
    SIGNIN_WITH_GOOGLE_AUTHENTICATED, 
    SIGNIN_WITH_FACEBOOK,
    SIGNIN_WITH_FACEBOOK_AUTHENTICATED, 
    SIGNIN_WITH_API,
    USER_NAME, 
    USER_ID, 
}