import axios from "axios";
axios.defaults.headers.common['Authorization'] = localStorage.getItem('auth_token') ;

const ServicioCertificados = {};
const { REACT_APP_API_URL } = process.env;

//? Listar Certificados.
ServicioCertificados.getCertificadosAxios = async () => {
  return await axios({
    method: "get",
    url: `${REACT_APP_API_URL}/contratos/api/certificado/list`,
    responseType: "json",
  })
    .then((response) => response.data)
    .catch((err) => err);
};

//? Agregar Certificados.
ServicioCertificados.addCertificadosAxios = async (data) => {
  return await axios({
    method: "post",
    url: `${REACT_APP_API_URL}/contratos/api/certificado/create`,
    // url: `http://localhost:4003/api/certificado/create`,
    data: data,
  })
    .then((response) => response.data)
    .catch((err) => err);
};

//? Eliminar Certificados.
ServicioCertificados.deleteCertificadosAxios = async (_id) => {
  return await axios({
    method: "delete",
    url: `${REACT_APP_API_URL}/contratos/api/certificado/delete`,
    data: {_id: _id},
  }).then((response) => response.data)
  .catch((err) => err);
};

//? Actualizar Certificados.
ServicioCertificados.updateCertificadosAxios = async (data) => {
  const { _id } = data;
  return await axios({
    method: "post",
    url: `${REACT_APP_API_URL}/contratos/api/certificado/edit/${_id}`,
    // url: `http://localhost:4003/api/certificado/edit/${_id}`,
    data: data,
 
  })
    .then((response) => response)
    .catch((err) => err);
};

export default ServicioCertificados;
