export const UPDATE_COMPROBANTE = 'UPDATE_COMPROBANTE' 
export const UPDATE_COMPROBANTE_SUCCESS = 'UPDATE_COMPROBANTE_SUCCESS' 

export const GET_COMPROBANTE ='GET_COMPROBANTE'
export const COMPROBANTE_SUCCESS = 'COMPROBANTE_SUCCESS'

export const GET_COMPROBANTE_CURRENT_USER = 'GET_COMPROBANTE_CURRENT_USER' 
export const GET_COMPROBANTE_CURRENT_USER_SUCCESS = 'GET_COMPROBANTE_CURRENT_USER_SUCCESS'

export const GET_STATE_COMPROBANTE = 'GET_STATE_COMPROBANTE'
export const GET_STATE_COMPROBANTE_SUCCESS = 'GET_STATE_COMPROBANTE_SUCCESS'

export const GET_STATE_FIRMA_COMPROBANTE = 'GET_STATE_FIRMA_COMPROBANTE'
export const GET_STATE_FIRMA_COMPROBANTE_SUCCESS = 'GET_STATE_FIRMA_COMPROBANTE_SUCCESS'
export const GET_FIRMA_ALL = 'GET_FIRMA_ALL'

export const ADD_COMPROBANTE = 'ADD_COMPROBANTE'
export const ADD_COMPROBANTE_SUCCESS = 'ADD_COMPROBANTE_SUCCESS'

export const DELETE_COMPROBANTE = 'DELETE_COMPROBANTE' 
export const DELETE_COMPROBANTE_SUCCESS = 'DELETE_COMPROBANTE_SUCCESS' 


export default {
    UPDATE_COMPROBANTE,UPDATE_COMPROBANTE_SUCCESS,
    GET_COMPROBANTE, COMPROBANTE_SUCCESS,
    GET_COMPROBANTE_CURRENT_USER, GET_COMPROBANTE_CURRENT_USER_SUCCESS,
    GET_STATE_COMPROBANTE, GET_STATE_COMPROBANTE_SUCCESS,
    GET_STATE_FIRMA_COMPROBANTE, GET_STATE_FIRMA_COMPROBANTE_SUCCESS, GET_FIRMA_ALL,
    ADD_COMPROBANTE, ADD_COMPROBANTE_SUCCESS,
    DELETE_COMPROBANTE, DELETE_COMPROBANTE_SUCCESS
}