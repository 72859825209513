//? Se importan las constantes
import presupuestoConstants from "../../constants/servicio-presupuesto/presupuesto";

//? Accion de Listar presupuestos
export const listPresupuestosAction = () => {
  return {
    type: presupuestoConstants.GET_PRESUPUESTOS,
  };
};

export const successPresupuestoAction = (data) => {
  return {
    type: presupuestoConstants.GET_PRESUPUESTOS_SUCCESS,
    payload: data,
  };
};

//? Accion de Listar centros
export const listCentrosAction = () => {
  return {
    type: presupuestoConstants.GET_CENTROS,
  };
};

export const successCentrosAction = (data) => {
  return {
    type: presupuestoConstants.GET_CENTROS_SUCCESS,
    payload: data,
  };
};

//? Accion de enviar datos del presupuestos
export const postPresupuestoDataAction = (data) => {
  return {
    type: presupuestoConstants.POST_PRESUPUESTO_DATA,
    payload: data,
  };
};

export const successPostPresupuestoDataAction = () => {
  return {
    type: presupuestoConstants.POST_PRESUPUESTO_DATA_SUCCESS,
  };
};

//? Accion de procesar SAC
export const postProcesarSacAction = (data) => {
  return {
    type: presupuestoConstants.POST_PROCESAR_SAC,
    payload: data,
  };
};

export const successPostProcesarSacAction = () => {
  return {
    type: presupuestoConstants.POST_PROCESAR_SAC_SUCCESS,
  };
};

export default {
  listPresupuestosAction,
  successPresupuestoAction,
  listCentrosAction,
  successCentrosAction,
  postPresupuestoDataAction,
  successPostPresupuestoDataAction,
  postProcesarSacAction,
  successPostProcesarSacAction
};
