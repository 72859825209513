///CONSTANTES CERTIFICADOS 
export const GET_CERTIFICADOS = 'GET_CERTIFICADOS'
export const GET_CERTIFICADOS_SUCCESS = 'GET_CERTIFICADOS_SUCCESS'

export const ADD_CERTIFICADOS = 'ADD_CERTIFICADOS'
export const ADD_CERTIFICADOS_SUCCESS = 'ADD_CERTIFICADOS_SUCCESS'

export const DELETE_CERTIFICADOS = 'DELETE_CERTIFICADOS'
export const DELETE_CERTIFICADOS_SUCCESS = 'DELETE_CERTIFICADOS_SUCCESS'

export const UPDATE_CERTIFICADOS = 'UPDATE_CERTIFICADOS'
export const UPDATE_CERTIFICADOS_SUCCESS = 'UPDATE_CERTIFICADOS_SUCCESS'

export default {
    GET_CERTIFICADOS,
    GET_CERTIFICADOS_SUCCESS,
    ADD_CERTIFICADOS,
    ADD_CERTIFICADOS_SUCCESS,
    DELETE_CERTIFICADOS,
    DELETE_CERTIFICADOS_SUCCESS,
    UPDATE_CERTIFICADOS,
    UPDATE_CERTIFICADOS_SUCCESS
}