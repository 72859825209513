import  modulosConstants from "../../constants/servicio-autenticacion/Modulos";


const initState = {
  payload: [],
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
};

const modulosReducer = (state = initState, action) => {
  const { type, payload } = action
  switch (type) {

    case modulosConstants.CONSULTA_MODULOS_GET: {
  
      return {
        ...state,
        loading: true,
        message: "",
        redirect: "/app/apps/modulos",
      };
    }
    case modulosConstants.SHOW_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case modulosConstants.MODULOS_SUCCESS: {
      return {
        ...state,
        loading: false,
        payload: payload,
      };
    }
    case modulosConstants.SHOW_AUTH_MESSAGE:
      return {
        ...state,
        message: payload,
        showMessage: true,
        loading: false,
      };

    case modulosConstants.ADD_MODULOS_POST: 
    case modulosConstants.UPDATE_MODULOS_POST:
    case modulosConstants.DELETE_MODULOS_POST: {
      return {
        ...state,
        loading: false
      };
    }
    default:
      return state;
  }
};
export default modulosReducer;
