import { all, takeEvery, fork } from "redux-saga/effects";
import { SIGNOUT, SIGNIN_WITH_API } from "../../constants/servicio-autenticacion/Auth";
import authFunctions from './functions/Auth.functions'

// Se realiza peticiones a la api para la autenticación
export function* signInApiSaga() {
  yield takeEvery(SIGNIN_WITH_API, authFunctions.signInApi );
}

// Se realiza peticiones a la api para el deslogueo
export function* signOutSaga() {
  yield takeEvery(SIGNOUT, authFunctions.signOut);
}

export default function* rootSaga() {
  yield all([
    fork(signInApiSaga), 
    fork(signOutSaga)
  ]);
}