import axios from "axios";
axios.defaults.headers.common['Authorization'] = localStorage.getItem('auth_token') ;

const userAdminService={} 
const {REACT_APP_API_URL} = process.env;


userAdminService.listUserAdmin = async () => {
 
    return await axios({
      method: "get",
      url: `${REACT_APP_API_URL}/admin/api/useradmin/list/`,
      responseType: "json",
    })
      .then((response) => response.data.data)
      .catch((err) => err);
  };

  userAdminService.addUserAdmin = async (data) => {
    const { correo,nombres, apellidos,ci,rol } = data;
   
    return await axios({
      method: "post",
      url: `${REACT_APP_API_URL}/admin/api/useradmin/created/`,
      data: {
        correo: correo,
        nombres: nombres,
        apellidos: apellidos,
        ci: ci,
        rol: rol,
      },
    }).then((response) => response.data);
  };

  userAdminService.listarOneUSer = async (data) => {
 
    return await axios({
      method: "post",
      url: `${REACT_APP_API_URL}/admin/api/useradmin/listOne`,
      responseType: "json",
      data: {
        ci: data,
      },
    }).then((response) => response.data);
  };
    ///servicio de recuperar datos de un usuario por id
    userAdminService.listOneUserDataAxios = async (data)=>{ 
      const idUser= data;
   
        return await   axios({
            method: "get",
            url: `${REACT_APP_API_URL}/admin/api/user/list/${idUser}`,
            responseType: "json",
          }).then((response) => response.data);
    
    }

    userAdminService.editUserAdminAxios = async (data) => {
      const { _id,correo, nombres,apellidos,ci,rol}=  data;
      
      return await axios({
        method: "post",
      url: `${REACT_APP_API_URL}/admin/api/useradmin/edit/${_id}`,
       
        data: {
          correo: correo,
          nombres: nombres,
          apellidos: apellidos,
          ci: ci,
          rol: rol,
        },
      }).then((response) => response)
      .catch((err) => err);
    };
    
    
export default userAdminService;