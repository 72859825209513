import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import TicketsProcesar from "./procesar-tickets";
import withRole from "../HOC/withRoles";

//? Definicion de reglas de roles
const restrictedProcesar = withRole(true, ["admin"], TicketsProcesar);

const Playero = (props) => {
  const { match } = props;
  return (
    <Switch>
      <Redirect exact from={`${match.url}`} to={`${match.url}/procesar`} />
      <Route path={`${match.url}/procesar`} component={restrictedProcesar} />
    </Switch>
  );
};

export default Playero;
