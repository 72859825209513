import { put,  call } from "redux-saga/effects";
import { message } from "antd";
//? Se importan las constantes
import ServicioHistorial from "axios/servicio-gestion-vacaciones/Movimiento";
import userService from "axios/servicio-autenticacion/UserServices";
import movimientosActions from '../../../actions/servicio-gestion-vacaciones/Movimientos'


//? Funcion  para listar todo los usuarios del historial
function* historial() {
    try {
      const historial = yield call(ServicioHistorial.getHistorial);
      const dataEnvio = historial.data;

      if (dataEnvio.estado === true) {
        yield put(movimientosActions.historialSuccessAction(dataEnvio.data));
      }
    } catch (err) {
      message.error(err.message);
    }
}


//? Funcion  para listar todo los usuarios del historial
function* historialCurrentUser({ payload }) {
    try {
      const historial = yield call(ServicioHistorial.getHistorialCurrentUser, payload);
       console.log('historialCurrentUserSaga: ', historial)
      if (historial) {
        yield put(movimientosActions.consultaHistorialCurrentUserSuccess(historial));
      }
    } catch (err) {
      message.error(err.message);
    }
}


//? Funcion para listar el historial de saldos de dias
function* historialSum({ payload }) {
    try {
      const data = yield call(ServicioHistorial.getHistorialSum, payload);

      if (data.msg) {
        message.error(data.msg);
      }

      yield put(movimientosActions.consultaHistorialSumAgregadoAction(data));
    } catch (err) {
      message.error(err.message);
    }
}


//? Accion para listar solo un usuario del historial
function* historialOne({ payload }) {
    try {

      const historial = yield call(ServicioHistorial.getHistorialOne, payload);
      
      if (historial.msg) {
        message.error(historial.msg);
        return;
      } 
      
      yield put(movimientosActions.historialSuccessOneAction( historial.data));

    } catch (err) {
      message.error(err.message);
    }
}


//? Funcion para listar todo los usuarios -> Endpoint Servicio de Autenticacion
function* listUserVacaciones() {
    try {
      const user = yield call(userService.listUserAxios);

      if (user) {
        yield put(movimientosActions.listadoSuccess(user));
      }
    } catch (err) {
      message.error(err.message);
    }
}



//? Funcion para crear los movimientos de solicitudes
function* generarMovimiento({ payload }) {
    try {
      if (payload.employeeStartDate != null) {
        const historial = yield call(
          ServicioHistorial.generarMovimiento,
          payload
        );

        yield put(movimientosActions.generarMovimientoSuccessAction());
      }

      yield put(movimientosActions.generarMovimientoSuccessAction());
    } catch (err) {
      message.error(err.message);
    }
  }



//? Funcion para contar las solicitudes con estados aprobadas y flag false
function* deleteMovimientosM({ payload }) {
    try {
      const deleteMovimientos = yield call(ServicioHistorial.deleteMovimientosMAxios, payload);
     
      yield put(movimientosActions.deleteMovimientosMSuccessAction(deleteMovimientos));
    } catch (err) {
      message.error(err.message);
    }
}


export default {
    historial,
    historialCurrentUser,
    historialSum,
    historialOne,
    listUserVacaciones,
    generarMovimiento,
    deleteMovimientosM
}
