import React,{ useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import { listadoOneUserVacacion } from "redux/actions/servicio-gestion-vacaciones/Users";
import {compose} from 'redux';
import { selectCurrentUser } from "redux/selectors/servicio-autenticacion/user.selector";
import {  selectCurrentUserRoleSolicitudes, selectLoadingSolicitudesUsers } from "redux/selectors/servicio-gestion-vacaciones/solicitudes.users.selectors";

/*** Este componente de alto nivel verifica qué puede ver el usuario dentro del módulo ***/
const withRole = (data=false) => (roles) => (Component) => (props) => {
  
  const dispatch = useDispatch()
  
  const role = useSelector(selectCurrentUserRoleSolicitudes)
  const loading = useSelector(selectLoadingSolicitudesUsers)
  const currentUser = useSelector(selectCurrentUser)
 
  console.log("que trae el role",role)
  useEffect(()=>{
      < Spin/>
      dispatch(listadoOneUserVacacion(currentUser.ci))
  },[loading])
  

    const userRoles = roles  ? roles : 'colaborador' ? roles: 'lider'

    for (var i = 0; i < userRoles.length; i++) {
      if (userRoles[i] === role) {
       return   <Component {...props} />
     }
   }
   if (userRoles[i] !== role)  {
     
     return data ? <h1>Acceso denegado</h1> : null
   }


}
const withRoleHoc = compose( (data, role, Component) => withRole(data)(role)(Component));

export default withRoleHoc;