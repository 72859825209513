import { all, takeEvery, fork } from "redux-saga/effects";
import {
  GET_CERTIFICADOS,
  ADD_CERTIFICADOS,
  DELETE_CERTIFICADOS,
  UPDATE_CERTIFICADOS,
} from "../../constants/servicio-contratos/Certificados";
import certificados_functions from './functions/Certficados.functions'

//? Listar Certificados de contratos
export function* listCertificadosSagas() {
  yield takeEvery(GET_CERTIFICADOS, certificados_functions.listCertificados );
}

//? Agregar Certificados de contratos
export function* addCertificadosSagas() {
  yield takeEvery(ADD_CERTIFICADOS, certificados_functions.addCertificados );
}

//? Eliminar Certificados de contratos
export function* deleteCertificadosSagas() {
  yield takeEvery(DELETE_CERTIFICADOS, certificados_functions.deleteCertificados);
}

//? Eliminar Actualizar de contratos
export function* updateCertificadosSagas() {
  yield takeEvery(UPDATE_CERTIFICADOS, certificados_functions.updateCertificados );
}

export default function* rootSagaModulos() {
  yield all([
    fork(listCertificadosSagas),
    fork(addCertificadosSagas),
    fork(deleteCertificadosSagas),
    fork(updateCertificadosSagas),
  ]);
}
