import { Menu, Dropdown, Avatar } from "antd";
import { connect } from "react-redux";
import {EditOutlined, LogoutOutlined } from "@ant-design/icons";
import Icon from "components/util-components/Icon";
import { signOutAction, authenticatedAction } from "redux/actions/servicio-autenticacion/Auth";
import { USER_ID, USER_NAME,AUTH_TOKEN } from "redux/constants/servicio-autenticacion/Auth";
import { concat } from "lodash-es";
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from "configs/AppConfig";
import jwt_decode from "jwt-decode";
import { Link } from 'react-router-dom'

// Obtiene el ID del localStorage
const getId = localStorage.getItem(USER_ID);

// Contenido editar del perfil
const menuItem = [
  {
    title: "Ver Perfil",
    icon: EditOutlined,
    path: `${APP_PREFIX_PATH}/profile/profile-edit/${getId}`,
  },
];


export const NavProfile = ({ signOutAction }) => {

  // jwt decode
  const token = localStorage.getItem(AUTH_TOKEN);
  const decode = (token)? jwt_decode(token):localStorage.clear();
 
  // Email split
  const correo = decode.name.toUpperCase();
  const correoBeforeAt = correo.substring(0, correo.indexOf('@'));
  const firstSplit = correoBeforeAt.split(".");
  const firstInitial = firstSplit[0].charAt(0);
  const secondInitialMail = firstSplit[1];
  const initialsEmails = firstInitial + (!secondInitialMail  ? '' : secondInitialMail);

  //username split
  const user = localStorage.getItem(USER_NAME);
  const userSplit = user.split(" ");
  const userFirstInitial = userSplit[0].charAt(0).toUpperCase();
  const userSecondInitial = userSplit[1].charAt(0).toUpperCase();
  const userInitials = concat(userFirstInitial, userSecondInitial);
 
  // condicional para corregir el undefined en el navprofile
  const conditionInitials = userSplit[0] != 'undefined' ? userInitials : initialsEmails
  const conditionUsers = userSplit[0] != 'undefined' ? user : correoBeforeAt;

  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45}>{conditionInitials}</Avatar>
          <div className="pl-3 pt-2">
            <h4 className="mb-0">{conditionUsers}</h4>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <Link to={el.path}>
                <Icon className="mr-3" type={el.icon} />
                <span className="font-weight-normal">{el.title}</span>
                  </Link>
              </Menu.Item>
            );
          })}
          <Menu.Item key={menuItem.length + 1} onClick={(e) => signOutAction()}>
            <span>
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">Cerrar Sesión</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          <Avatar>{conditionInitials}</Avatar>
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default connect(null, { signOutAction })(NavProfile);
