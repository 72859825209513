import { put, call } from "redux-saga/effects";
import facturasActions from "../../actions/servicio-vista-factura/vistaFactura";
import { message } from "antd";
import servicioFactura from "axios/servicio-vista-factura/VistaFactura";

//? Listar Facturas
export function* listFacturas() {
  try {
    const listFactura = yield call(
      servicioFactura.getFacturasAxios
    );

    if (!listFactura.error) {
      yield put(facturasActions.successFacturasAction(listFactura));
    }
  } catch (err) {
    yield message.error("Hubo un error al listar las facturas");
  }
}

//? Listar todos los pdfs
export function* listFiles() {
  try {
    const listFiles = yield call(
      servicioFactura.getFilePdf
    );

    if (!listFiles.error) {
      yield put(facturasActions.successFileAction(listFiles));
    }
  } catch (err) {
    yield message.error("Hubo un error al listar los PDFs");
  }
}

// Listar solo pdfs por parametros
export function* listOneFile({ payload }) {
  try {
    const listOneFile = yield call(
      servicioFactura.getFileByParams, payload
    );

    if (!listOneFile.error) {
      yield put(facturasActions.successOneFileAction(listOneFile));
    }
  } catch (err) {
    yield message.error("Hubo un error al Listar el PDF's");
  }
}

export default {
  listFacturas,
  listFiles,
  listOneFile
};
