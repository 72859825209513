//? Se importan las constantes
import certificadosConstants from "../../constants/servicio-contratos/Certificados";

//? Accion de Listar certificados
export const listCertificadosAction = () => {
  return {
    type: certificadosConstants.GET_CERTIFICADOS,
  };
};

export const successCertificadosAction = (data) => {
  return {
    type: certificadosConstants.GET_CERTIFICADOS_SUCCESS,
    payload: data,
  };
};

//? Accion de Agregar certificados
export const addCertificadosAction = (data) => {
  return {
    type: certificadosConstants.ADD_CERTIFICADOS,
    payload: data,
  };
};

export const SuccessAddCertificadosAction = () => {
    return {
      type: certificadosConstants.ADD_CERTIFICADOS_SUCCESS
    };
  };
  
//? Accion de Actualizar certificados 
export const updateCertificadosAction = (data) => {
  return {
    type: certificadosConstants.UPDATE_CERTIFICADOS,
    payload: data,
  };
};


export const successUpdateCertificadosAction = () => {
    return {
      type: certificadosConstants.UPDATE_CERTIFICADOS_SUCCESS
    };
  };

//? Accion de eliminar certificados
export const deleteCertificadosAction = (data) => {
  return {
    type: certificadosConstants.DELETE_CERTIFICADOS,
    payload: data,
  };
};

export const successDeleteCertificadosAction = () => {
    return {
      type: certificadosConstants.DELETE_CERTIFICADOS_SUCCESS,
    };
  };
  

export default {
  listCertificadosAction,
  successCertificadosAction,
  addCertificadosAction,
  SuccessAddCertificadosAction,
  updateCertificadosAction,
  successUpdateCertificadosAction,
  deleteCertificadosAction,
  successDeleteCertificadosAction
}