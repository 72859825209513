import { put, call } from "redux-saga/effects";
//? Se importan las constantes

import { message } from "antd";
import serviceUsersVacaciones from "axios/servicio-gestion-vacaciones/Users"; // Importamos el servicio de axios de users del servicio vacaciones
import userService from "axios/servicio-autenticacion/UserServices"; // Importamos el servicio de axios de autenticacion
import usersVacacionesActions from '../../../actions/servicio-gestion-vacaciones/Users'


//? Lista todos los usuarios -> Endpoint Servicio de autenticacion
function* listSolicitudesUsers() {
    try {
      const users = yield call(serviceUsersVacaciones.consultaSolicitudesAxios);

      if (users.error === "true") {
        message.error(users.error);
      } else {
        yield put(usersVacacionesActions.consultaSolicitudesUsersActionSuccess(users));
      }

    } catch (err) {
      message.error(err.message);
    }
  }
//? Lista por id de usuarios de vacaciones -> obtiene el rol de cada usuario
function* GetOneUsuarioVacaciones({ payload }) {
    try {
      const dataUser = yield call(
        serviceUsersVacaciones.listadoOneUsuariosVacaciones,
        payload
      );

      if (dataUser) {
        yield put(usersVacacionesActions.listadoUserVacacionActionSuccess(dataUser));
      }

    } catch (err) {
      message.error(err.message);
    }
  }

//? Obtiene datos de un usuario por id -> Endpoint de autenticacion
function* listOneUser({ payload }) {
    try {
      const dataUser = yield call(userService.listOneUserDataAxios, payload);

      if (dataUser.msg) {
        message.error(dataUser.msg);
      } else {
        const { ci } = dataUser;
        yield put(usersVacacionesActions.consultaOneUserSuccess(ci));
      }

    } catch (err) {
      message.error(err.message);
    }
  }

//? Lista todos los usuarios de vacaciones
function* listAllUser() {
    try {
      const dataUser = yield call(serviceUsersVacaciones.listadoUsuariosVacaciones);

      if (dataUser.msg) {
        message.error(dataUser.msg);
      }

      yield put(usersVacacionesActions.listadoAllUserVacacionAllSuccess(dataUser));
    } catch (err) {
      message.error(err.message);
    }

  }

//? Crea usuarios de vacaciones
function* createUserVacacion({ payload }) {
    try {
      const dataUser = yield call(serviceUsersVacaciones.createUserVaciones, payload);

      if (dataUser.msg) {
        message.error(dataUser.msg);
      } else {
        message.success("Registrado");
      }

      yield put(usersVacacionesActions.creacionUserVacacionSuccess());

    } catch (err) {
      message.error(err.message);
    }
  }

//? Edita usuarios de vacaciones
function* editUserVacacion({ payload }) {
    try {
      const dataUser = yield call(serviceUsersVacaciones.editUserVaciones, payload);

      if (dataUser.msg) {
        message.error(dataUser.msg);
      } else {
        message.success(`¡Datos del usuario editados exitosamente!`);
        yield put(usersVacacionesActions.editUserVacacionSuccess());
      }

    } catch (err) {
      message.error(err.message);
    }
  }

//? Elimina usuarios de vacaciones
function* deleteUserVacacion({ payload }) {
    try {
      const dataUser = yield call(serviceUsersVacaciones.deleteUserVacacion, payload);

      if (dataUser.msg) {
        message.success(dataUser.msg);
      }
      yield put(usersVacacionesActions.deleteUserVacaionActionSuccess());

    } catch (err) {
      message.error(err.message);
    }
  }

export default {
    listSolicitudesUsers,
    GetOneUsuarioVacaciones,
    listOneUser,
    listAllUser,
    createUserVacacion,
    editUserVacacion,
    deleteUserVacacion
}
