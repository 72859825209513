//? Se importan las constantes
import playeroConstants from "../../constants/servicio-playero/Playero";

//? Accion de enviar datos del presupuestos
export const postBeachSapDataAction = (data) => {
  return {
    type: playeroConstants.POST_BEACH_SAP_DATA,
    payload: data,
  };
};

export const successPostBeachSapDataAction = () => {
  return {
    type: playeroConstants.POST_BEACH_SAP_DATA_SUCCESS,
  };
};

export default {
  postBeachSapDataAction,
  successPostBeachSapDataAction,
};
