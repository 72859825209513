import React from 'react';
import PresupuestoForm from '../PresupuestoForm';

const ProcesarPresupuesto = () => {
	return (
		<PresupuestoForm mode="ADD"/>
	)
}

export default ProcesarPresupuesto
