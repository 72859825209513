//? Se importan las constantes 
import reportesUsersConstants from "../../constants/servicio-reportes/Users";

//? Accion de listar usuarios de dominio
export const listUsersReportesAction = () => {
  return {
    type: reportesUsersConstants.GET_USERS_DOMAIN_REPORTES,
  };
};

export const listUsersReportesSuccessAction = (data) => {
  return {
    type: reportesUsersConstants.GET_USERS_DOMAIN_REPORTES_SUCCESS,
    payload: data,
  };
};

//? Accion de listar todo los usuarios de contratos
export const listAllUserReportesAction = () => {
  return {
    type: reportesUsersConstants.GET_ALL_USERS_REPORTES,
  };
};

export const listAllUserReportesSuccessAction = (data) => {
  return {
    type: reportesUsersConstants.GET_ALL_USERS_REPORTES_SUCCESS,
    payload: data,
  };
};

//? Accion de crear usuarios de contratos
export const createUserReportes = (data) => {
  return {
    type: reportesUsersConstants.CREATE_USER_REPORTES,
    payload: data,
  };
};

export const createUserReportesSuccessAction = () => {
  return {
    type: reportesUsersConstants.CREATE_USER_REPORTES_SUCCESS,
  };
};

//? Accion editar usuarios de contratos
export const editUserReportesAction = (dataUser) => {
  return {
    type: reportesUsersConstants.EDIT_USER_REPORTES,
    payload: dataUser,
  };
};

export const editUserReportesSucessAcction = () => {
  return {
    type: reportesUsersConstants.EDIT_USER_REPORTES_SUCCESS,
  };
};

//? Accion de eliminar usuarios de contratos
export const deleteUserReportesAction = (data) => {
  console.log('deleteUserReportesAction: ', data)
  return {
    type: reportesUsersConstants.DELETE_USER_REPORTES,
    payload: data,
  };
};

export const deleteUserReportesSuccessAction = () => {
  return {
    type: reportesUsersConstants.DELETE_USER_REPORTES_SUCCESS,
  };
};

 //? Accion de listar solo un usuario de contratos
 export const listOneUserReportesAction = (data) => {
  return {
    type: reportesUsersConstants.GET_ONE_USER_REPORTES,
    payload: data,
  };
};

export const listOneUserReportesSuccessAction = (data) => {
  return { 
    type: reportesUsersConstants.GET_ONE_USER_REPORTES_SUCCESS,
    payload: data
  };
};

//? Accion de listar solo un usuario utilizando endpoint de servicio de autenticacion
  export const listUserAdminAction = (data) => {
    return {
      type: reportesUsersConstants.GET_USER_ADMIN,
      payload: data,
    };
  };

  export const listUserAdminSuccessAction = (data) => {
    return {
      type: reportesUsersConstants.GET_USER_ADMIN_SUCCESS,
      payload: data
    };
  };


  export default {
    listUsersReportesAction, listUsersReportesSuccessAction,
    listAllUserReportesAction, listAllUserReportesSuccessAction,
    createUserReportes, createUserReportesSuccessAction,
    editUserReportesAction, editUserReportesSucessAcction,
    deleteUserReportesAction, deleteUserReportesSuccessAction,
    listOneUserReportesAction, listOneUserReportesSuccessAction,
    listUserAdminAction, listUserAdminSuccessAction
  }