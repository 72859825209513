import axios from "axios";
axios.defaults.headers.common['Authorization'] = localStorage.getItem('auth_token');

const ServicioComprobante = {}
const { REACT_APP_API_URL } = process.env;

// Servicio para listar comprobantes
ServicioComprobante.getComprobantesAxios = async () => {
  return await axios({
    method: 'get',
    url: `${REACT_APP_API_URL}/vacaciones/api/comprobante/list`,
    responseType: 'json'
  }).then((response) => response.data)
    .catch(err => err)

}

// Servicio para listar comprobantes
ServicioComprobante.getCurrentUserComprobantesAxios = async (data) => {
  return await axios({
    method: 'get',
    url: `${REACT_APP_API_URL}/vacaciones/api/comprobante/user`,
    responseType: 'json',
    params: data
  }).then((response) => response.data)
    .catch(err => err)
}

// Servicio para agregar comprobantes
ServicioComprobante.addComprobantesAxios = async (data) => {
  console.log('ServicioComprobante.addComprobantesAxios: ', data)
  const {
    rol, dateSigned, type, vacationAmmount, userId, leaderId, employeeStartDate,
    currentPeriodStart, currentPeriodEnd, vacationPeriod
  } = data;

  const [vacationStart, vacationEnd] = vacationPeriod;
  return await axios({
    method: "post",
    url: `${REACT_APP_API_URL}/vacaciones/api/comprobante/create`,
    data: {
      userId: userId,
      leaderId: leaderId,
      employeeStartDate: employeeStartDate,
      dateSigned: dateSigned,
      type: type,
      vacationAmmount: vacationAmmount,
      vacationStart: vacationStart,
      vacationEnd: vacationEnd,
      currentPeriodStart: currentPeriodStart,
      currentPeriodEnd: currentPeriodEnd,
      rol: rol
    },
  }).then((response) => response.data)
    .catch((err) => err);
};

// Servicio para eliminar comprobantes
ServicioComprobante.deleteComprobantesAxios = async (data) => {

  return await axios({
    method: "delete",
    url: `${REACT_APP_API_URL}/vacaciones/api/comprobantes/delete`,
    data: {
      _id: data,
    },
  }).then((response) => response);
};

// Servicio para editar comprobantes
ServicioComprobante.EditComprobantesAxios = async (data) => {

  return await axios({
    method: "post",
    url: `${REACT_APP_API_URL}/vacaciones/api/comprobantes/edit/`,
    data: {
      data: data
    },
  }).then((response) => response);
};

// Servicio para cambiar estado de pdf de los comprobante.
ServicioComprobante.changeStateComprobantesPdfAxios = async (data) => {
  return await axios({
    method: "post",
    url: `${REACT_APP_API_URL}/vacaciones/api/comprobantes/estado/pdf`,
    data: {
      _id: data,
    },
  }).then((response) => response.data);
};

// Servicio para cambiar estados comprobantes
ServicioComprobante.changeStateComprobantesFirmasAxios = async (data) => {
  return await axios({
    method: "post",
    url: `${REACT_APP_API_URL}/vacaciones/api/comprobantes/estado/firma`,
    data: {
      _id: data,
    },
  }).then((response) => response.data);
};

// Servicio de firma general con estado Fimado pasa a Impreso
ServicioComprobante.firmarAllAxios = async (data) => {
  return await axios({
    method: "post",
    url: `${REACT_APP_API_URL}/vacaciones/api/comprobante/firmaAll`,
    data: {
      rol: data,
    },
  }).then((response) => response.data);
};
export default ServicioComprobante;