import { put, call } from "redux-saga/effects";
import { message } from "antd";
import ServicioModulos from "axios/servicio-autenticacion/ModuloService";
import modulosActions from '../../../actions/servicio-autenticacion/Modulos'

function* listModulos() {
  try {
    const modulos = yield call(ServicioModulos.getModulosAxios);

    if (modulos.error) {
      message.error('Hubo un error al traer los Modulos');
    } else {
      yield put(modulosActions.modulosSuccessAction(modulos));
    }
  } catch (err) {
    message.error(err.message);
  }
}

function* addModulos({ payload }) {
  
  try {
    const datosPermisos = yield call(
      ServicioModulos.addModulosAxios,
      payload
    );

    if (datosPermisos.msg) {
      message.error(datosPermisos.msg);
    } else {
      yield put(modulosActions.listModulosAction());
      message.success("¡Módulo Creado!");
    }
  } catch (err) {
    message.error(err.message);
  }
}

function* deleteModulos({ payload }) {
  try {
    const datosEliminad = yield call(
      ServicioModulos.deleteModulosAxios,
      payload
    );

    if (datosEliminad) {
      message.success("¡Módulo Eliminado!");
      yield put(modulosActions.listModulosAction());
    }
  } catch (err) {
    message.error(err.message);
  }
}

function* updateModulos({ payload }) {
  // Desesctructuramos el request, incluido el campo files
  try {
    const datosEditados = yield call(
      ServicioModulos.updateModulosAxios,
      payload
    );

    if (datosEditados) {
      message.success("¡Módulo Editado!");

      yield put(modulosActions.listModulosAction());
    }
  } catch (err) {
    message.error(err.message);
  }
}


export default {
    listModulos,
    addModulos,
    deleteModulos,
    updateModulos
}
