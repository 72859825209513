import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  CALCULATE_HABERES,
  CALCULATE_HABERES_SUCCESS,
} from "../../constants/servicio-gestion-unysoft/ServiceUnysoft";
import { message } from "antd";
import serviceUnysoft from "axios/servicio-gestion-unysoft/ServiceUnysoft";

//? Funcion para listar haberes
export function* calculoHaberesUnysoftSaga() {
  yield takeEvery(CALCULATE_HABERES, function* ({ payload }) {
    try {
      const haberes = yield call(serviceUnysoft.listarHaberesAxios, payload);
      yield put({ type: CALCULATE_HABERES_SUCCESS, data: haberes });
    } catch (err) {
      message.error(err.message);
    }
  });
}

export default function* rootSagaUnysoft() {
  yield all([fork(calculoHaberesUnysoftSaga)]);
}
