import axios from "axios";
axios.defaults.headers.common['Authorization'] = localStorage.getItem('auth_token') ;

const userService={} 
const {REACT_APP_API_URL} = process.env;


///envio de correo y token para verificar envio de correo
userService.sendEmailAxios = async (data)=>{ 
 
  console.log(data,'axiossssss email')
  return await axios({
      method: "post",
     
      url: `${REACT_APP_API_URL}/admin/api/user/email/pass/`,
      data: {
        username: data
       },
    }).then((response) => response.data);

}

userService.getOnlyOneUser = async (data)=>{ 
  return await axios({
      method: "post",
      url: `${REACT_APP_API_URL}/admin/api/user/oneUser/`,
      data: {
        id: data,
       },
    }).then((response) => response.data);

}

///consulta datos por email
userService.getEmailAxios = async (data)=>{ 
 const {username, tokenUrl}= data;
 
  return await axios({
      method: "post",
      url: `${REACT_APP_API_URL}/admin/api/user/list/email/`,
      data: {
        username: username,
        tokenUrl: tokenUrl,
      }
    }).then((response) => response.data);

}
///crear token para generar password
userService.createPassTokenAxios = async (data)=>{ 
 
  return  await axios({
      method: "post",
      url: `${REACT_APP_API_URL}/admin/api/user/create/token`,
      data: {
        username: data,
       },
    }).then((response) => response.data);

}

///Servicio para actualizar datos usuario
userService.updateDataUserAxios = async (data)=>{
  const {_id, nombres,apellidos,cargo, telefono}= data
  return await  axios({
      method: "post",
      url: `${REACT_APP_API_URL}/admin/api/user/edit/userdatos/${_id}`,
      data:{
        nombres: nombres,
        apellidos: apellidos,
        cargo: cargo,
        telefono: telefono,
      }    
    }).then((response) => response.data);
  }

///Servicio para actualizar el password
userService.updatePassUserAxios = async (data)=>{
    const {_id, password}= data

    return await  axios({
        method: "post",
        url: `${REACT_APP_API_URL}/admin/api/user/edit/pass/${_id}`,
        data:{
          password: password
        }    
      }).then((response) => response.data);
    }

    ///Servicio para eliminar usuarios
    userService.deleteUserAxios = async (data)=>{
       return await axios({
            method: "post",
            url: `${REACT_APP_API_URL}/admin/api/user/delete/${data}`,
          }).then((response) => response.data);
    }

    ///Servicio para crear un usuario
    userService.createUserAxios = async (data)=>{ 
        const { nombres, apellidos,fecha_nac,cargo,telefono,username } = data;
        return await axios({
            method: "post",
            url: `${REACT_APP_API_URL}/admin/api/user/create`,
            data: {
              nombres: nombres,
              apellidos: apellidos,
              fecha_nac: fecha_nac,
              cargo: cargo,
              telefono: telefono,
              username: username,
              password: '0',
              origen: 'creado'
            },
          }).then((response) => response.data);

    }

    // Elimina modulo del usuario
    userService.deleteModuleUserAxios = async (data)=>{ 
        const { _id, modulo, rol } = data;
        return await axios({
            method: "post",
            url: `${REACT_APP_API_URL}/admin/api/permisos/delete/${_id}`,
            data: {
              modulo: modulo,
              rol: rol,
            },
          }).then((response) => response);
    

    }

    // Crea los modulos al usuario
    userService.createModuleUserAxios = async (data)=>{ 
        const { _id, modulo } = data;
        return await axios({
            method: "post",
            url: `${REACT_APP_API_URL}/admin/api/permisos/add/${_id}`,
            data: {
              modulo: modulo,
            },
          }).then((response) => response);

    }

    // Lista los modulos agregados por el usuario
    userService.getModuleUserAxios = async (data)=>{ 
        return await  axios({
            method: "get",
            url: `${REACT_APP_API_URL}/admin/api/user/list/${data}`,
            responseType: "json",
          }).then((response) => response.data.modulos);
    
    }


    ///servicio de recuperar datos de un usuario por id
    userService.listOneUserDataAxios = async (data)=>{ 
      const idUser = data;
        return await   axios({
            method: "get",
            url: `${REACT_APP_API_URL}/admin/api/user/list/${idUser}`,
            responseType: "json",
          }).then((response) => response.data);
    
    }

    ///Servicio de listado de usuarios
    userService.listUserAxios = async ()=>{ 
        return await axios({
            method: "get",
            url: `${REACT_APP_API_URL}/admin/api/user/list/`,
            responseType: "json",
          }).then((response) => response.data);

    }

    ///Servicio de listado de Roles
    userService.listRolesAxios = async ()=>{ 
        return await axios({
            method: "get",
            url: `${REACT_APP_API_URL}/admin/api/roles/register/`,
            responseType: "json",
          }).then((response) => response.data.datos);
    }

    ///Servicio de listado de Modulos
    userService.listModulosAxios = async ()=>{ 
        return await axios({
            method: "get",
            url: `${REACT_APP_API_URL}/admin/api/modulos/register/`,
            responseType: "json",
          }).then((response) => response.data.datos);
    }

 export default userService;