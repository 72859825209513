import { put, call } from "redux-saga/effects";
//? Se importan las constantes
import ContratosUsersActions from '../../../actions/servicio-contratos/Users'
import { message } from "antd";
import serviceUsers from "axios/servicio-contratos/Users"; // Importamos el servicio de axios de users del servicio Contratos

//? Lista todos los usuarios del dominio -> Endpoint de Servicio de autenticacion
export function* listUsersDomain  () {
  try {
    const users = yield call(serviceUsers.consultUsersDomainAxios);

    if (users.error == "true") {
      message.error(users.error);
    } else {
      yield put(ContratosUsersActions.listUsersContratosSuccessAction(users) );
    }

  } catch (err) {
    message.error(err.message);
  }
  }

//? Lista por id de usuarios de contratos -> obtiene el rol de cada usuario
export function* listOneUsersContratos ({ payload }) {
  try {
    const dataUser = yield call(
      serviceUsers.listOneUsersContratosAxios,
      payload
    );

    if (dataUser) {
      yield put(ContratosUsersActions.listOneUserContratosSuccessAction(dataUser));
    }

  } catch (err) {
    message.error(err.message);
  }
  }

//? Listar todos los usuarios de contratos
export function* listAllUser () {
    try {
      const dataUser = yield call(serviceUsers.listUsersContratosAxios);
      if (dataUser.msg) {
        message.error(dataUser.msg);
      }

      yield put(ContratosUsersActions.listAllUserContratosSuccessAction(dataUser));

    } catch (err) {
      message.error(err.message);
    }
  }

//? Crea Usuarios de contratos
export function* createUserContratos ({ payload }) {
    try {

      const dataUser = yield call(
        serviceUsers.createUserContratosAxios,
        payload
      );

      if (dataUser.msg) {
        message.error(dataUser.msg);
      } else {
        message.success("Registrado");
      }

      yield put(ContratosUsersActions.createUserContratosSuccessAction());

    } catch (err) {
      message.error(err.message);
    }
  }

//? Edita Usuarios de contratos
export function* editUserContratos ({ payload }) {
    try {
      const dataUser = yield call(serviceUsers.editUserContratosAxios, payload);

      if (dataUser.msg) {
        message.error(dataUser.msg);
      } else {
        message.success(`¡Datos del usuario editados exitosamente!`);
        yield put(ContratosUsersActions.editUserContratosSucessAcction());
      }

    } catch (err) {
      message.error(err.message);
    }
  }

//? Elimina usuarios de contratos
export function* deleteUserContratos ({ payload }) {
    try {
      const dataUser = yield call(
        serviceUsers.deleteUserContratosAxios,
        payload
      );

      if (dataUser.msg) {
        message.success(dataUser.msg);
      }
      yield put(ContratosUsersActions.deleteUserContratosSuccessAction());

    } catch (err) {
      message.error(err.message);
    }
  }

//? Lista datos de un usuario por id -> Endpoint de Servicio de Autenticacion
    
    export  function* listOneUser ({ payload }) {
    try {
      const dataUser = yield call(serviceUsers.listOneUserDataAxios, payload);

      if (dataUser.msg) {
        message.error(dataUser.msg);
      } else {
        const { ci } = dataUser;
        const dataCi = ci;
        yield put(ContratosUsersActions.listUserAdminSuccessAction(dataCi));
      }

    } catch (err) {
      message.error(err.message);
    }
  }

  

export default {
    listUsersDomain,
    listOneUsersContratos,
    listAllUser,
    createUserContratos,
    editUserContratos,
    deleteUserContratos,
    listOneUser
}