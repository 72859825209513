import { createStore, applyMiddleware, compose } from "redux";
import reducers from "../reducers/index"; // Se importan los reducers creados
import createSagaMiddleware from "redux-saga"; // se importan las saga principal (index.js)

//Redux Persist
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import rootSaga from "../sagas/index";
import logger from 'redux-logger';


// WHITELIST
const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['user','userAdminReducer'] // only navigation will be persisted
};

const persistedReducer = persistReducer(persistConfig, reducers)

// crea un middleware de saga
const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware,  process.env.NODE_ENV === 'development' && logger].filter(Boolean);

// configuración del de store
function configureStore(preloadedState) {

  const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

  const store = createStore(
    persistedReducer, preloadedState, 
    composeEnhancers( applyMiddleware(...middlewares))
  );

  // Ejecuta la saga dinamicamente
  sagaMiddleware.run(rootSaga);

  // Los reducers se actualizan en tiempo de ejecución sin necesidad de una actualización completa
  if (module.hot) {
    module.hot.accept("../reducers/index", () => {
      const nextRootReducer = require("../reducers/");
      store.replaceReducer(nextRootReducer);
    });
  }
  
  // retorna el store modificado
  return store;
}

const store = configureStore();
export const persistor = persistStore(store)
export default store;
