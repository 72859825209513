import { put, call } from "redux-saga/effects";
import CertificadosActions from '../../../actions/servicio-contratos/Certificados'
import { message } from "antd";
import servicioCertificados from "axios/servicio-contratos/Certificados";


//? Listar Certificados de contratos
export function* listCertificados () {
  try {
    const listCertificados = yield call( servicioCertificados.getCertificadosAxios );

    if (listCertificados.error) {
      yield message.error(listCertificados.error);
    } else {
      yield put(CertificadosActions.successCertificadosAction(listCertificados));
    }
  } catch (err) {
    yield message.error(err.message, "error");
  }
}


//? Agregar Certificados de contratos
export function* addCertificados ({ payload }) {
  try {
    const addCertificados = yield call(
      servicioCertificados.addCertificadosAxios,
      payload
    );
    
    if (addCertificados.msg) {
      yield message.error(addCertificados.msg);
    } else {
      
      yield put(CertificadosActions.SuccessAddCertificadosAction());
    }
  } catch (err) {
    yield message.error(err.message);
  }
}

//? Eliminar Certificados de contratos
export function* deleteCertificados ({ payload }) {
  try {
    const deleteCertificados = yield call(
      servicioCertificados.deleteCertificadosAxios,
      payload
    );
    if (deleteCertificados.msg) {
      message.success("¡Certificado Eliminado!");
      yield put(CertificadosActions.successDeleteCertificadosAction());
    }else{
      message.error("Hubo un error al eliminar el certificado");
    }
  } catch (err) {
    message.error(err.message);
  }
}

//? Eliminar Actualizar de contratos
export function* updateCertificados({ payload }) {
  try {
    const updateCertifcados = yield call(
      servicioCertificados.updateCertificadosAxios,
      payload
    );

    if (updateCertifcados) {
      message.success("¡Certificado Editado!");

      yield put(CertificadosActions.successUpdateCertificadosAction());
    }
  } catch (err) {
    message.error(err.message);
  }
}

export default {
    listCertificados,
    addCertificados,
    deleteCertificados,
    updateCertificados
}