const detalleListObj =  {
      SALARIOBASICO: {
        concepto: "salario",
        valor: 0,
        cantidad: 30
      },
      bonifamiliar: {
        concepto: "bonifamiliar",
        valor: 0,
        cantidad: "5,00%"
      },
      IPS: {
        concepto: "ips",
        valor: 0,
        cantidad: "9,00%"
      },
      comision: {
        concepto: "comision",
        valor: 0,
        cantidad: "12,00%"
      },
      DESCUENTOS: {
        concepto: "descuento",
        valor: 0,
        cantidad: "12,00%"
      },
      vacacion: {
        concepto: "vacacion",
        valor: 0,
        cantidad: "13,00%"
        
      },
      descatraso: {
        concepto: "descatraso",
        valor: 0,
        cantidad: "14,00%"
        
      },
      subfamiliar: {
        concepto: "subfamiliar",
        valor: 0,
        cantidad: "15,00%"
        
      },
      horaextraordinaria: {
        concepto: "horaextraordinaria",
        valor: 0,
        cantidad: "15,00%"
        
      },
      COMPLEMENTOSALARIAL: {
        concepto: "complesalarial",
        valor: 0,
        cantidad: "15,00%"
        
      },
      compleajuste: {
        concepto: "compleajuste",
        valor: 0,
        cantidad: "15,00%"
        
      },
      gratificaextraordinaria: {
        concepto: "gratificaextraordinaria",
        valor: 0,
        cantidad: "15,00%"
        
      },
      INDEMNIZACIONESSUPLIDOS: {
        concepto: "indemosuplido",
        valor: 0,
        cantidad: "15,00%"
        
      },
      plustranspote: {
        concepto: "plustranspote",
        valor: 0,
        cantidad: "15,00%"
        
      },
      prestacargoempresa: {
        concepto: "prestacargoempresa",
        valor: 0,
        cantidad: "15,00%"
        
      },
      prestacargosegsocial: {
        concepto: "prestacargosegsocial",
        valor: 0,
        cantidad: "15,00%"
        
      },
      OTRASPERCEPCIONESNOSALARIALES: {
        concepto: "otrapercepnosalarial",
        valor: 0,
        cantidad: "15,00%"
        
      },
      incentivodocente: {
        concepto: "incentivodocente",
        valor: 0,
        cantidad: "15,00%"
        
      },
      antiguedad: {
        concepto: "antiguedad",
        valor: 0,
        cantidad: "16,00%"
      },
      meterialdidactico: {
        concepto: "meterialdidactico",
        valor: 0,
        cantidad: "17,00%"
        
      },
      retroremunerativo: {
        concepto: "retroremunerativo",
        valor: 0,
        cantidad: "18,00%"
        
      },
      DESCUENTOSPORALMUERZO: {
        concepto: "descalmuerzo",
        valor: 0,
        cantidad: "19,00%"
        
      },
      descdesayuno: {
        concepto: "descdesayuno",
        valor: 0,
        cantidad: "20,00%"
        
      },
      descuniforme: {
        concepto: "descuniforme",
        valor: 0,
        cantidad: "21,00%"
        
      },
      seguroprivado: {
        concepto: "seguroprivado",
        valor: 0,
        cantidad: "22,00%"
        
      },
      AGUINALDO: {
        concepto: "aguinaldo",
        valor: 0,
        cantidad: "22,00%"
        
      },
      descdonacion: {
        concepto: "descdonacion",
        valor: 0,
        cantidad: "23,00%"
        
      },
      DESCUENTOPAGOPRESTAMOS: {
        concepto: "descpagoprestamo",
        valor: 0,
        cantidad: "18,00%"
        
      },
      descconsumotelefonia: {
        concepto: "descconsumotelefonia",
        valor: 0,
        cantidad: "28,00%"
        
      },
      descanticiposalarial: {
        concepto: "descanticiposalarial",
        valor: 0,
        cantidad: "18,00%"
        
      },
      jubilacion: {
        concepto: "jubilacion",
        valor: 0,
        cantidad: "28,00%"
        
      },
      pension: {
        concepto: "pension",
        valor: 0,
        cantidad: "18,00%"

      },
      VACACIONESREMUNERADAS: {
        concepto: "vacacionremunerada",
        valor: 0,
        cantidad: "18,00%"
      },
      gradoacademico: {
        concepto: "gradoacademico",
        valor: 0,
        cantidad: "18,00%"
      },
      HORASEXTRA50: {
        concepto: "horaextra50",
        valor: 0,
        cantidad: "30,00%"
      },
      HORASEXTRA100: {
        concepto: "horaextra100",
        valor: 0,
        cantidad: "41,00%"
      }
}



const detalleList = async (arg) => {
    
   const {
        SALARIOBASICO, bonifamiliar, IPS, comision, DESCUENTOS, 
        vacacion, descatraso, subfamiliar, horaextraordinaria, VALEBONO,
        COMPLEMENTOSALARIAL, compleajuste, gratificaextraordinaria, INDEMNIZACIONESSUPLIDOS, 
        plustranspote, prestacargoempresa, prestacargosegsocial, OTRASPERCEPCIONESNOSALARIALES,
        incentivodocente, antiguedad, meterialdidactico, retroremunerativo,
        DESCUENTOSPORALMUERZO, descdesayuno, descuniforme, seguroprivado, 
        AGUINALDO, descdonacion, DESCUENTOPAGOPRESTAMOS, descconsumotelefonia, 
        descanticiposalarial, jubilacion, pension, VACACIONESREMUNERADAS,
        gradoacademico, HORASEXTRA50, HORASEXTRA100, DIASTRABAJADOS } = arg

    const listaDetalles = []
    
    for (const key of Object.keys(detalleListObj)) {
        if(  Object.keys(arg).includes(key) )
                detalleListObj[key].valor = arg[key]
                detalleListObj["SALARIOBASICO"].cantidad = DIASTRABAJADOS
    }

    for (const key of Object.keys(detalleListObj)) {
        listaDetalles.push(detalleListObj[key])
    }

    return listaDetalles;

}

export default detalleList