import { all, takeEvery, fork,  } from "redux-saga/effects";
import {USER_GET_ADMIN,USER_ONLY_ADMIN,USER_ONLY_GET_ADMIN, USER_EDIT_ADMIN, USER_ADD_ADMIN } from "../../constants/servicio-autenticacion/UserAdmin"
import UserAdminFunctions from "./functions/UserAdmin.functions";
 

export  function*  listUserAdminSaga () {
  yield takeEvery(USER_GET_ADMIN, UserAdminFunctions.listUserAdmin);
}

//actualizacion de datos usuarios
export function* addUserAdminSaga() {
  yield takeEvery(USER_ADD_ADMIN, UserAdminFunctions.addUserAdmin);
}

//lista por ci datos usuarios
export function* listOneUserAdminSaga() {
  yield takeEvery(USER_ONLY_ADMIN, UserAdminFunctions.listOneUserAdmin) 
}

export function* listOneUserCiSaga() {
  yield takeEvery(USER_ONLY_GET_ADMIN, UserAdminFunctions.listOneUserAdminCI );
}

export function* editUserAdminSaga() {
  yield takeEvery(USER_EDIT_ADMIN, UserAdminFunctions.editUserAdmin );
}

  export default function* rootSagaRoles() {
    yield all([
      fork(listUserAdminSaga),
      fork(addUserAdminSaga),
      fork(listOneUserAdminSaga),
      fork(listOneUserCiSaga),
      fork(editUserAdminSaga)
       
    ]);
  }
  
