///CONSTANTES LIQUIDACIONES
const LiquidacionesActionTypes = { 
    
  GET_LIQUIDACIONES: 'GET_LIQUIDACIONES',
  GET_LIQUIDACIONES_SUCCESS : 'GET_LIQUIDACIONES_SUCCESS',

  SEND_LIQUIDACIONES : 'SEND_LIQUIDACIONES',
  SEND_LIQUIDACIONES_SUCCESS : 'SEND_LIQUIDACIONES_SUCCESS',
  SEND_LIQUIDACIONES_FAILURE : 'SEND_LIQUIDACIONES_FAILURE',

  REGISTER_COMPROBANTE_SENDED : 'REGISTER_COMPROBANTE_SENDED',
  REGISTER_COMPROBANTE_SENDED_SUCCESS : 'REGISTER_COMPROBANTE_SENDED_SUCCESS',

  GENERATE_LIQUIDACIONES : 'GENERATE_LIQUIDACIONES',
  GENERATE_LIQUIDACIONES_SUCCESS : 'GENERATE_LIQUIDACIONES_SUCCESS',
  GENERATE_LIQUIDACIONES_FAILURE : 'GENERATE_LIQUIDACIONES_FAILURE',

  GENERATE_ONE_LIQUIDACIONES : 'GENERATE_ONE_LIQUIDACIONES',
  GENERATE_ONE_LIQUIDACIONES_SUCCESS : 'GENERATE_ONE_LIQUIDACIONES_SUCCESS',
  GENERATE_ONE_LIQUIDACIONES_FAILURE : 'GENERATE_ONE_LIQUIDACIONES_FAILURE',

  INIT_PROGRESS_BAR:'INIT_PROGRESS_BAR',
  INIT_FAILURE_PROGRESS_BAR: 'INIT_FAILURE_PROGRESS_BAR',
  UPDATE_PROGRESS_BAR: 'UPDATE_PROGRESS_BAR',
  END_PROGRESS_BAR: 'END_PROGRESS_BAR'

}

export default LiquidacionesActionTypes;