import axios from "axios";
axios.defaults.headers.common['Authorization'] = localStorage.getItem('auth_token');

const serviceSolicitudes = {};

const { REACT_APP_API_URL } = process.env;

serviceSolicitudes.consultaSolicitudesAxios = async () => {
  return await axios({
    method: "get",
    url: `${REACT_APP_API_URL}vacaciones/api/solicitudes/listado`,
    responseType: "json",
  })
    .then((response) => response.data.data)
    .catch((err) => err);
};


serviceSolicitudes.consultaCurrentUserSolicitudesPropiasAxios = async (data) => {
  return await axios({
    method: "get",
    url: `${REACT_APP_API_URL}/vacaciones/api/solicitudes/propias`,
    // url: 'http://localhost:4002/api/solicitudes/propias',
    responseType: "json",
    params: data
  })
    .then((response) => response.data)
    .catch((err) => err);
};


serviceSolicitudes.consultaCurrentUserSolicitudesTercerosAxios = async (data) => {
  return await axios({
    method: "get",
    url: `${REACT_APP_API_URL}/vacaciones/api/solicitudes/terceros`,
    responseType: "json",
    params: data
  })
    .then((response) => response.data)
    .catch((err) => err);
};



serviceSolicitudes.calculoDiasAxios= async (data) => {
  
  return await axios({
    method: "post",
    url: `${REACT_APP_API_URL}/vacaciones/api/solicitudes/dias`,
     
    data:{
      day1: data[0]._d, 
      day2: data[1]._d, 
    }
  })
    .then((response) => response.data)
    .catch((err) => err);
};

serviceSolicitudes.addSolicitudAxios = async (data) => {
  const {
    userId,
    leaderId,
    vacationPeriod,
    extraordiary,
    delegated,
    employeeStartDate,
    createdById,
    correoLider,
    correoEmpleado,
    datosEmpleado,
    idAplicacion,
    nif,
    ci,
    flag,
    diasReales

  } = data;

  const [vacationStart, vacationEnd] = vacationPeriod;
  return await axios({
    method: "post",
    url: `${REACT_APP_API_URL}/vacaciones/api/solicitudes/create`,
    data: {
      userId: userId,
      leaderId: leaderId,
      vacationStart: vacationStart,
      vacationEnd: vacationEnd,
      extraordiary: extraordiary,
      delegated: delegated,
      employeeStartDate: employeeStartDate, //employeeStartDate,
      createdById: createdById,
      correoLider: correoLider,
      correoEmpleado: correoEmpleado,
      datosEmpleado: datosEmpleado,
      idAplicacion: idAplicacion,
      nif: nif,
      ci: ci,
      flag: flag,
      diasReales: diasReales
    },
  })
    .then((response) => response.data)
    .catch((err) => err);
};

serviceSolicitudes.editSolicitudAxios = async (data) => {
  const {
    _id,
    userId,
    leaderId,
    vacationPeriod,
    extraordiary,
    delegated,
    createdById,
    correoLider,
    correoEmpleado,
    datosEmpleado,
    employeeStartDate,
    idAplicacion,
    nif,
    ci,
    sync
  } = data;
  const [vacationStart, vacationEnd] = vacationPeriod;
  return await axios({
    method: "post",
    url: `${REACT_APP_API_URL}/vacaciones/api/solicitudes/edit/${_id}`,
    data: {
      userId: userId,
      leaderId: leaderId,
      vacationStart: vacationStart,
      vacationEnd: vacationEnd,
      extraordiary: extraordiary,
      delegated: delegated,
      employeeStartDate: employeeStartDate, //employeeStartDate,
      createdById: createdById,
      correoLider: correoLider,
      correoEmpleado: correoEmpleado,
      datosEmpleado: datosEmpleado,
      idAplicacion: idAplicacion,
      nif: nif,
      ci: ci,
      sync: sync
    },
  })
    .then((response) => response.data)
    .catch((err) => err);
};

serviceSolicitudes.editSolicitudStatusAxios = async (data) => {
  const { _id, state, correoLider, correoEmpleado, datoEmpleado, isM, flag } = data;
  return await axios({
    method: "post",
    url: `${REACT_APP_API_URL}/vacaciones/api/solicitudes/updstatus/${_id}`,
    data: {
      state: state,
      correoLider,
      correoEmpleado,
      datoEmpleado,
      isM: isM,
      flag: flag
    },
  })
    .then((response) => response)
    .catch((err) => err);
};

///servicio de firma general con estado Fimado pasa a Aprobado
serviceSolicitudes.updateSolicitudStatusAxios= async (data) => {
 
  return await axios({
    method: "post",
      url: `${REACT_APP_API_URL}/vacaciones/api/solicitudes/terceros/changeState`,
    data: {
      id: data,
    },
  }).then((response) => response.data);
};

//? Servicio para sumar la cantidad de dia de solicitudes para la nueva logica.
serviceSolicitudes.sumDaysAxios= async (data) => {
  return await axios({
    method: "post",
      url: `${REACT_APP_API_URL}/vacaciones/api/solicitudes/terceros/sumardiasM`,
    data: {
      id: data,
    },
  }).then((response) => response.data);
};


//? Servicio para contar la cantidad de solicitudes para la nueva logica.
serviceSolicitudes.countSolicitudesAxios= async (data) => {
  return await axios({
    method: "post",
      url: `${REACT_APP_API_URL}/vacaciones/api/solicitudes/terceros/contarsolicitudesM`,
    data: {
      id: data,
    },
  }).then((response) => response.data);
};

//? Servicio para eliminar las solicitudes M para la nueva logica.
serviceSolicitudes.deleteSolicitudesMAxios= async (data) => {
  return await axios({
    method: "post",
      url: `${REACT_APP_API_URL}/vacaciones/api/solicitudes/terceros/eliminarsolicitudesM`,
    data: {
      id: data,
    },
  }).then((response) => response.data);
};




export default serviceSolicitudes;

