import userConstants from "../../constants/servicio-autenticacion/Users";
import rolesConstants from "../../constants/servicio-autenticacion/Roles";
import authConstants from "../../constants/servicio-autenticacion/Auth";

const initState = {
  payload: [],
  currentUser: {},
  selectedUserModulos: [],
  loading: false,
  loadingModule: false,
  updating: false,
  message: "",
  showMessage: false,
  showMessageOK: false,
  redirect: "",
};

const userReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    
    // Agrega modulos al usuario
    case userConstants.CREATE_MODULE_USER: {
      return {
        ...state,
        updating: true,
      };
    }
    case userConstants.CREATE_MODULE_USER_SUCCESS: {
      return {
        ...state,
        updating: false,
      };
    }

    case userConstants.CONSULTA_USER_GET: {
      return {
        ...state,
        loading: true,
        message: "",
        redirect: "/app/apps/portal-admin/user/user-list/",
      };
    }

    // case modulosConstants.CONSULTA_MODULOS_GET:
    case rolesConstants.CONSULTA_ROLES_GET:
    case authConstants.SHOW_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case userConstants.USER_SUCCESS_ONLY: {
      return {
        ...state,
        loadingModule: true,
        selectedUserModulos: payload,
      };
    }

    case userConstants.USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        payload: payload,
        redirect: "/app/apps/portal-admin/user/user-list",
      };
    }

    case authConstants.SHOW_AUTH_MESSAGE:
      return {
        ...state,
        message: payload,
        showMessage: true,
        loading: false,
      };

    case userConstants.UPDATE_USER_POST: {
      return {
        ...state,
        loading: false,
      };
    }

    case authConstants.HIDE_AUTH_MESSAGE: {
      return {
        ...state,
        message: "",
        showMessage: false,
        showMessageOK: false,
      };
    }

    case userConstants.GET_ONE_USER_DATA:
    case userConstants.GET_ONLY_ONE_USER: {
      return {
        ...state,
      };
    }

    case userConstants.USER_SUCCESS_ONLY_DATA:
    case userConstants.GET_ONLY_ONE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: payload,
      };

    case userConstants.DELETE_USER_POST: {
      return {
        ...state,
        loading: false,
        payload: payload,
      };
    }

    case userConstants.CREATE_USER_TOKEN_PASS_SUCCESS: {
      return {
        ...state,
        loading: false,
        showMessageOK: true,
      };
    }

    case userConstants.USER_EMAIL_SUCCESS_ONLY: {
      return {
        ...state,
        loadingModule: true,
      };
    }

    // Elimina el modulo del usuario
    case userConstants.DELETE_MODULE_USER: {
      return {
        ...state,
        updating: true,
      };
    }

    case userConstants.DELETE_MODULE_USER_SUCCESS: {
      return {
        ...state,
        updating: false,
      };
    }

    // Lista los modulos que tiene agregado el usuario
    case userConstants.GET_MODULE_USER: {
      return {
        ...state,
        loadingModule: true,
      };
    }

    case userConstants.GET_MODULE_USER_SUCCESS: {
      return {
        ...state,
        loadingModule: false,
        selectedUserModulos: payload,
      };
    }

    default:
      return state;
  }
};
export default userReducer;
