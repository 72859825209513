import { all, takeEvery, fork } from "redux-saga/effects";
import utilsConstants from "../../constants/servicio-gestion-vacaciones/Utils";
import utilsFunctions from "./functions/utils.functions";

export function* calculateVacacionesSaga() {
  yield takeEvery(utilsConstants.POST_CALCULATE_VACACIONES, utilsFunctions.calculateVacaciones);
}

export default function* rootUtilsSaga() {
  yield all([fork(calculateVacacionesSaga)]);
}
