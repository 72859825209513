import { combineReducers } from "redux";
import authReducer from "./servicio-autenticacion/Auth";
import themeReducer from "./servicio-autenticacion/Theme";
import homeReducer from "./servicio-autenticacion/Home";
import rolesReducer from "./servicio-autenticacion/Roles";
import userReducer from "./servicio-autenticacion/User";
import modulosReducer from "./servicio-autenticacion/Modulos";
import profileReducer from "./servicio-autenticacion/Profile";
import userAdminReducer from "./servicio-autenticacion/UserAdmin";

// Servicio de gestion de vacaciones
import movimientosReducer from "./servicio-gestion-vacaciones/Movimientos";
import solicitudesVacaciones from "./servicio-gestion-vacaciones/Solicitudes";
import solicitudesUsersReducer from "./servicio-gestion-vacaciones/Users";
import comprobanteReducer from "./servicio-gestion-vacaciones/Comprobantes";

// Servicio de gestion de vacaciones
import certificadosReducer from "./servicio-contratos/Certificados";
import contratosReduce from "./servicio-contratos/Contratos";
import contratosUserReducer from "./servicio-contratos/Users";

// Servicio de Liquidacion Digital
import liquidacionesReducer from "./servicio-liquidacion-digital/Liquidaciones";

// Servicio de Unysoft
import unysoftReducer from "./servicio-gestion-unysoft/ServiceUnysoft";

// Servicio de Reportes
import reportesReducer from "./servicio-reportes/Reportes";
import reportesUsersReducer from "./servicio-reportes/Users";

// Servicio de Utils
import utilsReducer from "./servicio-gestion-vacaciones/Utils";

// Servicio de Presupuesto
import presupuestoReducer from "./servicio-presupuesto/Presupuesto";

// Servicio de factura
import facturaReducer from "./servicio-vista-factura/VistaFactura";

// Servicio de Presupuesto
import playeroReducer from "./servicio-playero/Playero";

const reducers = combineReducers({
  theme: themeReducer,
  auth: authReducer,
  home: homeReducer,
  roles: rolesReducer,
  user: userReducer,
  modulos: modulosReducer,
  profile: profileReducer,
  solicitudes: solicitudesVacaciones,
  solicitudesUsers: solicitudesUsersReducer,
  movimientosReducer: movimientosReducer,
  comprobanteReducer: comprobanteReducer,
  userAdminReducer: userAdminReducer,
  certificados: certificadosReducer,
  contratosReduce: contratosReduce,
  userContratos: contratosUserReducer,
  liquidaciones: liquidacionesReducer,
  unysoft: unysoftReducer,
  reportes: reportesReducer,
  userReportes: reportesUsersReducer,
  utils: utilsReducer,
  presupuesto: presupuestoReducer,
  vistaFactura: facturaReducer,
  playero: playeroReducer
});

export default reducers;
