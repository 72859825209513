export const GET_USERS_DOMAIN_CONTRATOS = "GET_USERS_DOMAIN_CONTRATOS";
export const GET_USERS_DOMAIN_CONTRATOS_SUCCESS = "GET_USERS_DOMAIN_CONTRATOS_SUCCESS";

export const CREATE_USER_CONTRATOS = "CREATE_USER_CONTRATOS";
export const CREATE_USER_CONTRATOS_SUCCESS = "CREATE_USER_CONTRATOS_SUCCESS";

export const DELETE_USER_CONTRATOS = "DELETE_USER_CONTRATOS";
export const DELETE_USER_CONTRATOS_SUCCESS = "DELETE_USER_CONTRATOS_SUCCESS";

export const EDIT_USER_CONTRATOS = "EDIT_USER_CONTRATOS";
export const EDIT_USER_CONTRATOS_SUCCESS = "EDIT_USER_CONTRATOS_SUCCESS";

export const GET_ALL_USERS_CONTRATOS = "GET_ALL_USERS_CONTRATOS";
export const GET_ALL_USERS_CONTRATOS_SUCCESS = "GET_ALL_USERS_CONTRATOS_SUCCESS";

export const GET_USER_ADMIN = "GET_USER_ADMIN";
export const GET_USER_ADMIN_SUCCESS = "GET_USER_ADMIN_SUCCESS ";

export const GET_ONE_USER_CONTRATOS = "GET_ONE_USER_CONTRATOS";
export const GET_ONE_USER_CONTRATOS_SUCCESS = "GET_ONE_USER_CONTRATOS_SUCCESS";


export default {
    GET_USERS_DOMAIN_CONTRATOS,
    GET_USERS_DOMAIN_CONTRATOS_SUCCESS,
    CREATE_USER_CONTRATOS,
    CREATE_USER_CONTRATOS_SUCCESS,
    DELETE_USER_CONTRATOS,
    DELETE_USER_CONTRATOS_SUCCESS,
    EDIT_USER_CONTRATOS,
    EDIT_USER_CONTRATOS_SUCCESS,
    GET_ALL_USERS_CONTRATOS,
    GET_ALL_USERS_CONTRATOS_SUCCESS,
    GET_USER_ADMIN,
    GET_USER_ADMIN_SUCCESS,
    GET_ONE_USER_CONTRATOS,
    GET_ONE_USER_CONTRATOS_SUCCESS
}
