import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import PortalAdmin from "./apps/servicio-autenticacion";
import PortalContratos from "./apps/servicio-contratos"
import PortalLiquidaciones from "./apps/servicio-liquidacion-digital"
import PortalReportes from "./apps/servicio-reportes"
import PortalPresupuesto from "./apps/servicio-gestion-presupuesto"
import PortalBill from "./apps/servicio-gestion-bill"
import PortalPlayero from "./apps/servicio-gestion-playero";
import withModulos from './hocModulos/withModulos';

//Restrincciones
const PrincipalRestricted = withModulos(true, ['PORTAL ADMIN'], PortalAdmin);
const contratosRestricted = withModulos(true, ['CONTRATOS'], PortalContratos);
const LiquidacionesRestricted = withModulos(true, ['LIQUIDACIONES'], PortalLiquidaciones);
const ReportesRestricted = withModulos(true, ['REPORTES'], PortalReportes)
const PresupuestoRestricted = withModulos(true, ['PROCESAR PRESUPUESTO'], PortalPresupuesto)
const billRestricted = withModulos(true, ['VISUALIZAR FACTURAS'], PortalBill)
const beachRestricted = withModulos(true, ['PLAYERO'], PortalPlayero)

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/dashboards`} component={lazy(() => import(`./dashboards`))} />
        <Route path={`${APP_PREFIX_PATH}/apps`} component={PrincipalRestricted} />
        <Route path={`${APP_PREFIX_PATH}/apps1`} component={lazy(() => import(`./apps/servicio-gestion-vacaciones`))} />
        <Route path={`${APP_PREFIX_PATH}/apps2`} component={contratosRestricted} />
        <Route path={`${APP_PREFIX_PATH}/apps3`} component={LiquidacionesRestricted} />
        <Route path={`${APP_PREFIX_PATH}/apps4`} component={ReportesRestricted} />
        <Route path={`${APP_PREFIX_PATH}/apps5`} component={PresupuestoRestricted} />
        <Route path={`${APP_PREFIX_PATH}/apps6`} component={billRestricted} />
        <Route path={`${APP_PREFIX_PATH}/apps7`} component={beachRestricted} />
        <Route path={`${APP_PREFIX_PATH}/profile`} component={lazy(() => import(`./apps/servicio-autenticacion/profile`))} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/dashboards/inicio`} />
      </Switch>
    </Suspense>
  )
}


export default React.memo(AppViews);
