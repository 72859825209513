import axios from "axios";
const {REACT_APP_API_URL} = process.env;

axios.defaults.headers.common['Authorization'] = localStorage.getItem('auth_token') ;

export const homeSaga = () => {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/admin/api/modulos/register/`,
  })
    .then((response) => response.data)

    .catch((err) => err);
};
