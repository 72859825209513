import { all, takeEvery, fork } from "redux-saga/effects";
//? Se importan las constantes
import {
  CONSULTA_HISTORIAL, HISTORIAL_CONSULTA_AGREGADO,
  CONSULTA_HISTORIAL_ONE, GENERAR_MOVIMIENTO,
  DELETE_M_MOVIMIENTOS, CONSULTA_HISTORIAL_CURRENT_USER,
} from "../../constants/servicio-gestion-vacaciones/Movimientos";
import { CONSULTA_USER_GET } from "../../constants/servicio-autenticacion/Users";
import movimientosFunctions from './functions/Movimientos.functions'

//? Funcion  para listar todo los usuarios del historial
export function* historialSaga() {
  yield takeEvery(CONSULTA_HISTORIAL, movimientosFunctions.historial );
}

//? Funcion  para listar todo los usuarios del historial
export function* historialCurrentUserSaga() {
  yield takeEvery(CONSULTA_HISTORIAL_CURRENT_USER, movimientosFunctions.historialCurrentUser );
}

//? Funcion para listar el historial de saldos de dias
export function* historialSumSaga() {
  yield takeEvery(HISTORIAL_CONSULTA_AGREGADO, movimientosFunctions.historialSum );
}

//? Accion para listar solo un usuario del historial
export function* historialSagaOne() {
  yield takeEvery(CONSULTA_HISTORIAL_ONE, movimientosFunctions.historialOne );
}

//? Funcion para listar todo los usuarios -> Endpoint Servicio de Autenticacion
export function* listUserSaga() {
  yield takeEvery(CONSULTA_USER_GET, movimientosFunctions.listUserVacaciones );
}

//? Funcion para crear los movimientos de solicitudes
export function* generarMovimientoSaga() {
  yield takeEvery(GENERAR_MOVIMIENTO, movimientosFunctions.generarMovimiento );
}

//? Funcion para contar las solicitudes con estados aprobadas y flag false
export function* deleteMovimientosMSaga() {
 
  yield takeEvery(DELETE_M_MOVIMIENTOS, movimientosFunctions.deleteMovimientosM );
}


export default function* rootSaga() {
  yield all([
    fork(historialSaga),
    fork(historialCurrentUserSaga),
    fork(listUserSaga),
    fork(historialSagaOne),
    fork(historialSumSaga),
    fork(generarMovimientoSaga),
    fork(deleteMovimientosMSaga)
  ]);
}
