import { all, takeEvery, fork } from "redux-saga/effects";
//? Se importan las constantes
import {
  GET_USERS_DOMAIN_REPORTES,
  GET_ALL_USERS_REPORTES,
  CREATE_USER_REPORTES,
  DELETE_USER_REPORTES,
  EDIT_USER_REPORTES,
  GET_USER_ADMIN,
  GET_ONE_USER_REPORTES,
} from "../../constants/servicio-reportes/Users";
import user_reportes_func from './Users.functions'

//? Lista todos los usuarios del dominio -> Endpoint de Servicio de autenticacion
export function* listUsersDomainSagas() {
  yield takeEvery(GET_USERS_DOMAIN_REPORTES, user_reportes_func.listUsersDomain)
}

//? Lista por id de usuarios de reportes -> obtiene el rol de cada usuario
export function* listOneUsersReportesSagas() {
  yield takeEvery(GET_ONE_USER_REPORTES, user_reportes_func.listOneUsersReportes);
}

//? Listar todos los usuarios de reportes
export function* listAllUserSagas() {
  yield takeEvery(GET_ALL_USERS_REPORTES, user_reportes_func.listAllUser);
}

//? Crea Usuarios de reportes
export function* createUserReportesSagas() {
  yield takeEvery(CREATE_USER_REPORTES, user_reportes_func.createUserReportes);
}

//? Edita Usuarios de reportes
export function* editUserReportesSagas() {
  yield takeEvery(EDIT_USER_REPORTES, user_reportes_func.editUserReportes);
}

//? Elimina usuarios de reportes
export function* deleteUserReportesSagas() {
  yield takeEvery(DELETE_USER_REPORTES, user_reportes_func.deleteUserReportes );
}

//? Lista datos de un usuario por id -> Endpoint de Servicio de Autenticacion
export function* listOneUserSagas() {
  yield takeEvery(GET_USER_ADMIN, user_reportes_func.listOneUser);
}

export default function* rootUsersReportesSaga() {
  yield all([
    fork(listOneUserSagas),
    fork(listAllUserSagas),
    fork(listUsersDomainSagas),
    fork(editUserReportesSagas),
    fork(createUserReportesSagas),
    fork(deleteUserReportesSagas),
    fork(listOneUsersReportesSagas),
  ]);
}
