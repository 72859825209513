import { all, takeEvery, fork } from "redux-saga/effects";
import { UPDATE_PROFILE, GET_PROFILE } from "../../constants/servicio-autenticacion/Profile";
import profileFunctions from './functions/Profile.functions'


export function* listProfileSaga() {
  yield takeEvery( GET_PROFILE, profileFunctions.listProfile );
}

export function* updateProfileSaga() {
    yield takeEvery( UPDATE_PROFILE, profileFunctions.updateProfile );
}
  
export default function* rootSagaProfile() {
  yield all([
    fork(updateProfileSaga),
    fork(listProfileSaga)
  ]);
}
  