import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';

const Apps = ({ match }) => (
  <Suspense fallback={<Loading cover="content"/>}>
    <Switch>
      <Route path={`${match.url}/portal-admin/principal`} component={lazy(() => import(`../../dashboards/analytic`))} />
      {/* <Route path={`${match.url}/portal-admin/roles`} component={lazy(() => import(`./roles`))} /> */}
      <Route path={`${match.url}/portal-admin/usuarios`} component={lazy(() => import(`./usuarios`))} />
      <Route path={`${match.url}/portal-admin/user`} component={lazy(() => import(`./user`))} />
      <Route path={`${match.url}/portal-admin/modulos`} component={lazy(() => import(`./modulos`))} />
      <Route path={`${match.url}/profile`} component={lazy(() => import(`./profile`))} />
      <Redirect from={`${match.url}`} to={`${match.url}/portal-admin/principal`} />
    </Switch>
  </Suspense>
);

export default Apps;