import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';
import Presupuesto from "./presupuesto";
import  withRole  from './HOC/withRoles';

const PrincipalRestricted = withRole(false,['admin'],Presupuesto)

const Apps = ({ match }) => (
  <Suspense fallback={<Loading cover="content"/>}>
    <Switch>
      <Route path={`${match.url}/portal-presupuesto/presupuesto`} component={lazy(() => import(`./presupuesto`))} />
      <Redirect from={`${match.url}`} to={`${match.url}/portal-presupuesto/presupuesto`} component = {PrincipalRestricted} />
    </Switch>
  </Suspense>
);

export default Apps;