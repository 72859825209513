import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  CONSULTA_SOLICITUDES,
  CONSULTA_SOLICITUDES_SUCCESS,
  ADD_SOLICITUDES,
  ADD_SOLICITUDES_SUCCESS,
  UPDATE_SOLICITUDES,
  UPDATE_SOLICITUDES_SUCCESS,
  UPDATE_STATUS,
  UPDATE_STATUS_SUCCESS,
  GET_DIAS,
  GET_DIAS_SUCCCESS,
  CONSULTA_SOLICITUDES_CONTROL,
  CONSULTA_CONTROL,
  UPDATE_STATUS_SOLICITUD,
  UPDATE_STATUS_SOLICITUD_SUCCESS,
  SUM_DAYS,
  SUM_DAYS_SUCCESS,
  COUNT_SOLICITUDES_DAYS,
  COUNT_SOLICITUDES_DAYS_SUCCESS,
  DELETE_M_SOLICITUDES,
  CONSULTA_SOLICITUDES_CURRENT_USER,
  CONSULTA_SOLICITUDES_CURRENT_USER_SUCCESS,
  CONSULTA_SOLICITUDES_TERCEROS_CURRENT_USER,
  CONSULTA_SOLICITUDES_TERCEROS_CURRENT_USER_SUCCESS,
} from "../../constants/servicio-gestion-vacaciones/Solicitudes";

import { message } from "antd";
import serviceSolicitudes from "axios/servicio-gestion-vacaciones/Solicitudes";
import ServicioHistorial from "axios/servicio-gestion-vacaciones/Movimiento";
import ServicioComprobante from "axios/servicio-gestion-vacaciones/Comprobantes";
import serviceUnysoft from "axios/servicio-gestion-unysoft/ServiceUnysoft";

// ***ALGUNAS FUNCIONES AUXILIARES***

//? Inserta a formulario carga de datos en UnyRem
const insertCargaDatos = async (value) => {
  //? Recibe como argumento un array de objetos/registros a cargar
  let resultado = ""; // Resultado final

  for (let v in value) {
    resultado = await serviceUnysoft.addRemcontAxios(value[v]); // Por cada registro, lo inserta a UnyRem

    if (resultado.msg) {
      // Chequea si ocurrió un error
      return resultado; // Si ocurrió un error, retorna el mensaje de error devuelto por el request
    }
  }
  return resultado; // Si no, retorna el resultado devuelto por el request
};

//? Elimina del formulario carga de datos en UnyRem
//? Es igual a la función anterior, pero llama al servicio deleteRemcontAxios
const deleteCargaDatos = async (value) => {
  let resultado = "";

  for (let v in value) {
    resultado = await serviceUnysoft.deleteRemcontAxios(value[v]);

    if (resultado.msg) {
      return resultado;
    }
  }
  return resultado;
};

//? Funcion para listar todas las solicitudes
export function* listSolicitudesSaga() {
  yield takeEvery(CONSULTA_SOLICITUDES, function* () {
    try {
      const solicitudes = yield call(
        serviceSolicitudes.consultaSolicitudesAxios
      );

      if (solicitudes.error === "true") {
        message.error(solicitudes.error);
      } else {
        yield put({ type: CONSULTA_SOLICITUDES_SUCCESS, payload: solicitudes });
      }
    } catch (err) {
      message.error(err.message);
    }
  });
}

//? Funcion para listar todas las solicitudes de Terceros
export function* listSolicitudesTercerosSaga() {
  yield takeEvery(
    CONSULTA_SOLICITUDES_TERCEROS_CURRENT_USER,
    function* ({ payload }) {
      try {
        const solicitudes = yield call(
          serviceSolicitudes.consultaCurrentUserSolicitudesTercerosAxios,
          payload
        );
        console.log("listSolicitudesTercerosSaga: ", solicitudes);
        if (solicitudes.msg) {
          message.error(solicitudes.msg);
        } else {
          yield put({
            type: CONSULTA_SOLICITUDES_TERCEROS_CURRENT_USER_SUCCESS,
            payload: solicitudes.data,
          });
        }
      } catch (err) {
        message.error(err.message);
      }
    }
  );
}

export function* listCurrentUserSolicitudes({ payload }) {
  try {
    const solicitudes = yield call(
      serviceSolicitudes.consultaCurrentUserSolicitudesPropiasAxios,
      payload
    );

    if (solicitudes.msg) {
      message.error(solicitudes.msg);
    } else {
      yield put({
        type: CONSULTA_SOLICITUDES_CURRENT_USER_SUCCESS,
        payload: solicitudes.data,
      });
    }
  } catch (err) {
    message.error(err.message);
  }
}

//? Funcion para listar todas las solicitudes del Usuario Actual
export function* listCurrentUserSolicitudesSaga() {
  yield takeEvery(
    CONSULTA_SOLICITUDES_CURRENT_USER,
    listCurrentUserSolicitudes
  );
}

//? Funcion para agregar solicitudes
export function* addSolicitudSaga() {
  yield takeEvery(ADD_SOLICITUDES, function* ({ payload }) {
    try {
      // Pasa payload a request
      const solicitud = yield call(
        serviceSolicitudes.addSolicitudAxios,
        payload
      );
      if (solicitud.msg) {
        message.error(solicitud.msg);
      } else {
        message.success("Solicitud creada!");
        yield put({ type: ADD_SOLICITUDES_SUCCESS });
      }
    } catch (err) {
      message.error(err.message);
    }
  });
}

//? Funcion para listar todas las solicitudes
export function* controFechaSaga() {
  yield takeEvery(CONSULTA_CONTROL, function* ({ payload }) {
    try {
      if (payload === "ExisteFecha") {
        yield put({ type: CONSULTA_SOLICITUDES_CONTROL });
      }
    } catch (err) {
      message.error(err.message);
    }
  });
}

//? Funcion para editar solicitudes
export function* editSolicitudSaga() {
  yield takeEvery(UPDATE_SOLICITUDES, function* ({ payload }) {
    try {
      console.log("PAYLOAD: ", payload);
      // Pasa payload a request
      const solicitud = yield call(
        serviceSolicitudes.editSolicitudAxios,
        payload
      );

      if (solicitud.msg) {
        message.error(solicitud.msg);
        yield put({ type: UPDATE_SOLICITUDES_SUCCESS });
      } else {
        yield put({ type: UPDATE_SOLICITUDES_SUCCESS });
        message.success("Solicitud editada!");
      }
    } catch (err) {
      message.error(err.message);
    }
  });
}

//? Funcion para editar el estado de la solicitudes
export function* editSolicitudStatusSaga() {
  yield takeEvery(UPDATE_STATUS, function* ({ payload }) {
    try {
      //? Asegura la atomicidad de la transacción para mantener la consistencia de los datos
      //? Si ocurre un error esta función se encarga de realizar un ROLLBACK
      async function ifFails(payload, reciboData, notaData) {
        try {
          await ServicioHistorial.deleteMovimientoAxios(payload);
          await ServicioComprobante.deleteComprobantesAxios(reciboData);
          await ServicioComprobante.deleteComprobantesAxios(notaData);
        } catch (error) {
          message.error(error.message);
          return;
        }
      }

      // Intenta cargar los movimientos
      const movimiento = yield call(
        payload.state === "Aprobada"
          ? ServicioHistorial.addMovimientoAxios
          : ServicioHistorial.deleteMovimientoAxios,
        payload
      );

      //? Si movimiento arroja un error se ejecuta isFail(Realiza un rollback)
      if (movimiento.msg) {
        message.error(movimiento.msg);
        yield put({ type: UPDATE_STATUS_SUCCESS });
        return;
      }

      //? Cambia estado de la solicitud solo si todo lo demás no falló
      const solicitud = yield call(
        serviceSolicitudes.editSolicitudStatusAxios,
        payload
      );

      //? Si solicitud arroja un error se ejecuta isFail(Realiza un rollback)
      if (solicitud.msg) {
        message.error(solicitud.msg);
        yield put({ type: UPDATE_STATUS_SUCCESS });
        return;
      }

      message.success(`Estado cambiado a ${payload.state}`);
      yield put({ type: UPDATE_STATUS_SUCCESS });
    } catch (err) {
      message.error(err.message);
    }
  });
}

//? Se realiza el calculo de dias de solicitudes
export function* calculoDiasSaga() {
  yield takeEvery(GET_DIAS, function* ({ payload }) {
    try {
      const dataDays = yield call(serviceSolicitudes.calculoDiasAxios, payload);

      if (dataDays.error === "true") {
        message.error(dataDays.error);
      }

      yield put({ type: GET_DIAS_SUCCCESS, data: dataDays });
    } catch (err) {
      message.error(err.message);
    }
  });
}

//? Funcion para cambiar el estado del comprobante
export function* updateSolicitudStatusSaga() {
  yield takeEvery(UPDATE_STATUS_SOLICITUD, function* ({ payload }) {
    try {
      const stateSolicitud = yield call(
        serviceSolicitudes.updateSolicitudStatusAxios,
        payload
      );

      yield put({
        type: UPDATE_STATUS_SOLICITUD_SUCCESS,
        data: stateSolicitud,
      });
    } catch (err) {
      message.error(err.message);
    }
  });
}

//-------------------------Nueva logica -> M -----------------
//? Funcion para cambiar el estado del comprobante de Firmas
export function* sumDaysSolicitudSaga() {
  yield takeEvery(SUM_DAYS, function* ({ payload }) {
    try {
      const sumDays = yield call(serviceSolicitudes.sumDaysAxios, payload);
      yield put({ type: SUM_DAYS_SUCCESS, data: sumDays });
    } catch (err) {
      message.error(err.message);
    }
  });
}

//? Funcion para contar las solicitudes con estados aprobadas y flag false
export function* countSolicitudSaga() {
  yield takeEvery(COUNT_SOLICITUDES_DAYS, function* ({ payload }) {
    try {
      const countSolicitudes = yield call(
        serviceSolicitudes.countSolicitudesAxios,
        payload
      );
      yield put({
        type: COUNT_SOLICITUDES_DAYS_SUCCESS,
        data: countSolicitudes,
      });
    } catch (err) {
      message.error(err.message);
    }
  });
}

//? Funcion para contar las solicitudes con estados aprobadas y flag false
export function* deleteSolicitudMSaga() {
  yield takeEvery(DELETE_M_SOLICITUDES, function* ({ payload }) {
    try {
      const deleteSolicitudes = yield call(
        serviceSolicitudes.deleteSolicitudesMAxios,
        payload
      );

      yield put({ type: DELETE_M_SOLICITUDES, data: deleteSolicitudes });
    } catch (err) {
      message.error(err.message);
    }
  });
}

export default function* rootSagaRoles() {
  yield all([
    fork(listSolicitudesSaga),
    fork(listSolicitudesTercerosSaga),
    fork(addSolicitudSaga),
    fork(editSolicitudSaga),
    fork(editSolicitudStatusSaga),
    fork(calculoDiasSaga),
    fork(controFechaSaga),
    fork(updateSolicitudStatusSaga),
    fork(sumDaysSolicitudSaga),
    fork(countSolicitudSaga),
    fork(deleteSolicitudMSaga),
    fork(listCurrentUserSolicitudesSaga),
  ]);
}
