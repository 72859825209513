import reportesUsersConstants from "../../constants/servicio-reportes/Users";

const initState = {
  role: "",
  payload: [],
  ci: "",
  users:[],
  loading: false,
  update: false,
};

const reportesUsersReducer = (state = initState, action) => {
  const { type, payload } = action
  switch (type) {
    //? Reducer Lista usuarios de dominio
    case reportesUsersConstants.GET_USERS_DOMAIN_REPORTES: {
      return {
        ...state,
        loading: true,
      };
    }
    case reportesUsersConstants.GET_USERS_DOMAIN_REPORTES_SUCCESS: {
      return {
        ...state,
        payload: payload,
        loading: false,
      };
    }

    //? Reducer Listar usuarios
    case reportesUsersConstants.GET_ALL_USERS_REPORTES: {
      return {
        ...state,
        loading: true,
      };
    }
    case reportesUsersConstants.GET_ALL_USERS_REPORTES_SUCCESS: {
      return {
        ...state,
        users: payload,
        loading: false,
      };
    }

    //? Reducer Crear usuarios
    case reportesUsersConstants.CREATE_USER_REPORTES: {
      return {
        ...state,
        loading: true,
        update: true,
      };
    }

    case reportesUsersConstants.CREATE_USER_REPORTES_SUCCESS: {
      return {
        ...state,
        loading: false,
        update: false,
      };
    }

    //? Reducer editar usuarios
    case reportesUsersConstants.EDIT_USER_REPORTES: {
      return {
        ...state,
        loading: true,
        update: true,
      };
    }
    case reportesUsersConstants.EDIT_USER_REPORTES_SUCCESS: {
      return {
        ...state,
        loading: false,
        update: false,
      };
    }

    //? Reducer eliminar usuarios
    case reportesUsersConstants.DELETE_USER_REPORTES: {
      return {
        ...state,
        loading: true,
        update: true,
      };
    }

    case reportesUsersConstants.DELETE_USER_REPORTES_SUCCESS: {
      return {
        ...state,
        loading: false,
        update: false,
      };
    }

    //? Reducer para listar solamente un usuario -> obtiene el rol
    case reportesUsersConstants.GET_ONE_USER_REPORTES: {
      return {
        ...state,
        loading: false,
        update: false,
      };
    }
    case reportesUsersConstants.GET_ONE_USER_REPORTES_SUCCESS: {
      return {
        ...state,
        role: payload,
        loading: false,
        update: false,
      };
    }

    //? Reducer de listar solo un usuario -> Endpoint Servicio de Autenticacion
    case reportesUsersConstants.GET_USER_ADMIN: {
      return {
        ...state,
        loading: false,
        update: false,
      };
    }
    case reportesUsersConstants.GET_USER_ADMIN_SUCCESS: {
      return {
        ...state,
        ci: payload,
        loading: false,
        update: false,
      };
    }

    default:
      return state;
  }
};

export default reportesUsersReducer;
