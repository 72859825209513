import reportesConstants from "../../constants/servicio-reportes/Reportes";

const initState = {
  payload: [],
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
};

const reportesReducer = (state = initState, action) => {
  const { type, payload } = action  
  switch (type) {
    //? Reducer Listar Reportes
    case reportesConstants.GET_REPORTES: {
      return {
        ...state,
        loading: true,
      };
    }

    case reportesConstants.GET_REPORTES_SUCCESS: {
      return {
        ...state,
        payload: payload,
        loading: false,
      };
    }

    //? Reducer Agregar Reportes
    case reportesConstants.ADD_REPORTES: {
      return {
        ...state,
        loading: true,
      };
    }

    case reportesConstants.ADD_REPORTES_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    //? Reducer Actualizar Reportes
    case reportesConstants.UPDATE_REPORTES: {
      return {
        ...state,
        loading: true,
      };
    }


    case reportesConstants.UPDATE_REPORTES_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    //? Reducer Eliminar Reportes
    case reportesConstants.DELETE_REPORTES: {
      return {
        ...state,
        loading: true,
      };
    }

    case reportesConstants.DELETE_REPORTES_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }
    default:
      return state;
  }
};
export default reportesReducer;
