import axios from "axios";
const ServiceUtils = {};
const { REACT_APP_API_URL } = process.env;

// Servicio que retorna los calculos de vacaciones.
ServiceUtils.postCalculoVacaciones = async (data) => {
  return await axios({
    method: "post",
    url: `${REACT_APP_API_URL}/vacaciones/api/calculateDateVacation`,
    params: {
      date1: data.date1,
      date2: data.date2,
    },
  })
    .then((response) => response.data)
    .catch((err) => err);
};

export default ServiceUtils;
