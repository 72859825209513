import { all, call, takeLatest } from "redux-saga/effects";
import LiquidacionesActionTypes from "../../constants/servicio-liquidacion-digital/Liquidaciones";
import liquidaciones_functions from './Liquidaciones.functions'

//? Funcion para listar los Liquidaciones
export function* ListLiquidacionesActionSaga(){
  yield takeLatest(LiquidacionesActionTypes.GET_LIQUIDACIONES, liquidaciones_functions.ListLiquidaciones )
}

//? Funcion para Generar Liquidaciones
export function* GenerateLiquidacionesActionSaga(){
  yield takeLatest(LiquidacionesActionTypes.GENERATE_LIQUIDACIONES, liquidaciones_functions.GenerateLiquidaciones )
}

//? Funcion para Enviar Liquidaciones
export function* SendLiquidacionesActionSaga(){
  yield takeLatest(LiquidacionesActionTypes.SEND_LIQUIDACIONES, liquidaciones_functions.SendLiquidaciones )
}

//? Funcion para Generar Liquidaciones
export function* GenerateOneLiquidacionesActionSaga(){
  yield takeLatest(LiquidacionesActionTypes.GENERATE_ONE_LIQUIDACIONES, liquidaciones_functions.GenerateOneLiquidacion )
}

export default function* rootSagaLiquidaciones() {
  yield all([
    call(ListLiquidacionesActionSaga),
    call(GenerateLiquidacionesActionSaga),
    call(SendLiquidacionesActionSaga),
    call(GenerateOneLiquidacionesActionSaga)
  ]);
}
