import { createSelector } from "reselect";

const selectPresupuestos = (state) => state.presupuesto;

export const selectPresupuestoLoading = createSelector(
  [selectPresupuestos],
  (currentState) => currentState.loadingPresupuestos
);

export const selectCentrosLoading = createSelector(
  [selectPresupuestos],
  (currentState) => currentState.loadingCentros
);

export const selectCentrosPayload = createSelector(
  [selectPresupuestos],
  (currentState) => {
    return currentState.payloadCentros.map((value) => {
      // NIVEL3-CTODESCRIPCION-SK_DATASOURCE
      return {
        label: `${value.nivel3} - ${value.ctoDescripcion} - ${value.sk_datasource}`,
        value: JSON.stringify({
          centro: value.nivel3,
          datasource: value.sk_datasource,
        }),
      };
    });
  }
);

export const selectPresupuestoPayload = createSelector(
  [selectPresupuestos],
  (currentState) => {
    return currentState.payloadPresupuestos.map((value) => {
      return {
        label: `${value.key_esc} - ${value.descrip}`,
        value: value.key_esc,
      };
    });
  }
);

export const selectLoadingProcess = createSelector(
  [selectPresupuestos],
  (currentState) => currentState.loadingProcess
);


export const selectProcessSacLoading = createSelector(
  [selectPresupuestos],
  (currentState) => currentState.processSacloading
);