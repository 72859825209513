//? Se importan las constantes
import comprobantesConstants from "../../constants/servicio-gestion-vacaciones/Comprobantes";

//? Accion para listar comprobantes
export const getComprobanteAction = () => {
  return {
    type: comprobantesConstants.GET_COMPROBANTE
  };
};

//? Accion para listar comprobantes
export const getCurrentUserComprobanteAction = (data) => {
  return {
    type: comprobantesConstants.GET_COMPROBANTE_CURRENT_USER,
    payload: data
  };
};

export const getCurrentUserComprobanteSuccessAction = (data) => {
  return {
    type: comprobantesConstants.GET_COMPROBANTE_CURRENT_USER_SUCCESS,
    payload: data
  };
};

export const ComprobanteSuccessAction = (data) => {
  return {
    type: comprobantesConstants.COMPROBANTE_SUCCESS,
    payload: data,
  };
};


//? Accion para actualizar comprobantes
export const updateComprobanteAction = (data) => {
  return {
    type: comprobantesConstants.UPDATE_COMPROBANTE,
    payload: data,
  };
};

export const updateComprobanteSuccessAction = (data) => {
  return {
    type: comprobantesConstants.UPDATE_COMPROBANTE_SUCCESS
  };
};

//? Accion para eliminar comprobantes
export const deleteComprobanteAction = (data) => {
  return {
    type: comprobantesConstants.DELETE_COMPROBANTE,
    payload: data,
  };
};

export const deleteComprobanteSuccessAction = (data) => {
  return {
    type: comprobantesConstants.DELETE_COMPROBANTE_SUCCESS
  };
};

//? Accion para obtener estado del comprobantes de PDF
export const getStateComprobanteAction = (data) => {
  return {
    type: comprobantesConstants.GET_STATE_COMPROBANTE,
    payload: data,
  };
};

export const getStateComprobanteSuccessAction = () => {
  return {
    type: comprobantesConstants.GET_STATE_COMPROBANTE_SUCCESS
  };
};

//? Accion para obtener estado del comprobantes de FIRMAS
export const getStateFirmasComprobanteAction = (data) => {
  return {
    type: comprobantesConstants.GET_STATE_FIRMA_COMPROBANTE,
    payload: data,
  };
};

export const getStateFirmasComprobanteSuccessAction = () => {
  return {
    type: comprobantesConstants.GET_STATE_FIRMA_COMPROBANTE_SUCCESS
  };
};

export const firmaAllAction = (data) => {
  return {
    type: comprobantesConstants.GET_FIRMA_ALL,
    payload: data,
  };
};

//? Accion para listar comprobantes
export const addComprobanteAction = (data) => {
  return {
    type: comprobantesConstants.ADD_COMPROBANTE,
    payload: data
  };
};

export const addComprobanteSuccessAction = () => {
  return {
    type: comprobantesConstants.ADD_COMPROBANTE_SUCCESS
  };
};


export default {
  getComprobanteAction, ComprobanteSuccessAction,
  getCurrentUserComprobanteAction, getCurrentUserComprobanteSuccessAction,
  updateComprobanteAction, updateComprobanteSuccessAction,
  deleteComprobanteAction, deleteComprobanteSuccessAction,
  getStateComprobanteAction, getStateComprobanteSuccessAction,
  getStateFirmasComprobanteAction, getStateFirmasComprobanteSuccessAction,
  firmaAllAction,
  addComprobanteAction, addComprobanteSuccessAction
}