import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';
import Playero from "./playero";
import  withRole  from './HOC/withRoles';

const PrincipalRestricted = withRole(false,['admin'],Playero)

const Apps = ({ match }) => (
  <Suspense fallback={<Loading cover="content"/>}>
    <Switch>
      <Route path={`${match.url}/portal-playero/playero`} component={lazy(() => import(`./playero`))} />
      <Redirect from={`${match.url}`} to={`${match.url}/portal-playero/playero`} component = {PrincipalRestricted} />
    </Switch>
  </Suspense>
);

export default Apps;