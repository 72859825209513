import { put, call} from "redux-saga/effects";
import {modulesSuccessAction} from "../../../actions/servicio-autenticacion/Home";
import {homeSaga} from "axios/servicio-autenticacion/Home";
import { message } from "antd";

const axios = require("axios");

// Se realiza peticiones a la api para traer los modulos
function* listModules() {
    try {
      const module = yield call(homeSaga);
      if (module.error === false) {
        yield put(modulesSuccessAction(module.datos));
      }
    } catch (error) {
        message.error(error.message)
    }
  }

export default {
    listModules
}
