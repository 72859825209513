export const CONSULTA_USERS = 'CONSULTA_USERS'
export const CONSULTA_USERS_SUCCESS = 'CONSULTA_USERS_SUCCESS'
export const CREATE_USER  ='CREATE_USER'
export const CREATE_USER_SUCCESS  ='CREATE_USER_SUCCESS'
export const EDIT_USER  ='EDIT_USER'
export const EDIT_USER_SUCCESS  ='EDIT_USER_SUCCESS'
export const GET_USER  ='GET_USER'
export const GET_USER_SUCCESS  ='GET_USER_SUCCESS'
export const GET_FULL_USERS  ='GET_FULL_USERS'
export const GET_FULL_USERS_SUCCESS  ='GET_FULL_USERS_SUCCESS'
export const USER_SUCCESS_ONLY_VACACACIONES  ='USER_SUCCESS_ONLY_VACACACIONES'
export const GET_USER_ONLY_MODULOS_SUCCESS_VACACACIONES  ='GET_USER_ONLY_MODULOS_SUCCESS_VACACACIONES'
export const USER_ONLY_GET_VACACIONES = 'USER_ONLY_GET_VACACIONES'
export const DELETE_USER_VACACACION = 'DELETE_USER_VACACACION'
export const DELETE_USER_VACACACION_SUCCESS = 'DELETE_USER_VACACACION_SUCCESS'


export default {
    CONSULTA_USERS, CONSULTA_USERS_SUCCESS,
    CREATE_USER, CREATE_USER_SUCCESS,
    EDIT_USER, EDIT_USER_SUCCESS,
    GET_USER, GET_USER_SUCCESS,
    GET_FULL_USERS, GET_FULL_USERS_SUCCESS,
    USER_SUCCESS_ONLY_VACACACIONES, GET_USER_ONLY_MODULOS_SUCCESS_VACACACIONES,
    USER_ONLY_GET_VACACIONES, 
    DELETE_USER_VACACACION, DELETE_USER_VACACACION_SUCCESS
}