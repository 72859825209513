//CONSTANTES USUARIOS
export const CONSULTA_USER_GET = 'CONSULTA_USER_GET'
export const CREATE_MODULE_USER = 'CREATE_MODULE_USER'
export const DELETE_USER_POST = 'DELETE_USER_POST'
export const DELETE_MODULE_USER = 'DELETE_MODULE_USER'
export const DELETE_MODULE_USER_SUCCESS = 'DELETE_MODULE_USER_SUCCESS'
export const UPDATE_USER_POST = 'UPDATE_USER_POST'
export const USER_SUCCESS = 'USER_SUCCESS'
export const GET_MODULE_USER_SUCCESS = 'GET_MODULE_USER_SUCCESS'
export const GET_MODULE_USER = 'GET_MODULE_USER'
export const USER_SUCCESS_ONLY = 'USER_SUCCESS_ONLY'
export const CONSULTA_USER_EXITOSO = 'CONSULTA_USER_EXITOSO' 
export const CREATE_MODULE_USER_SUCCESS = 'CREATE_MODULE_USER_SUCCESS' 
export const CREATE_USER_EXITOSO = 'CREATE_USER_EXITOSO' 
export const DELETE_USER_EXITOSO = 'DELETE_USER_EXITOSO' 
export const UPDATE_USER_PASS = 'UPDATE_USER_PASS' 
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA' 
export const CREATE_USER_TOKEN_PASS = 'CREATE_USER_TOKEN_PASS' 
export const USER_EMAIL_SUCCESS_ONLY = 'USER_EMAIL_SUCCESS_ONLY' 
export const USER_SUCCESS_ONLY_DATA = 'USER_SUCCESS_ONLY_DATA' 
export const USER_SUCCESS_DATA = 'USER_SUCCESS_DATA' 
export const GET_ONLY_ONE_USER = 'GET_ONLY_ONE_USER'
export const GET_ONLY_ONE_USER_SUCCESS = 'GET_ONLY_ONE_USER_SUCCESS'
export const CREATE_USER_TOKEN_PASS_SUCCESS = 'CREATE_USER_TOKEN_PASS_SUCCESS'
export const GET_ONE_USER_DATA = 'GET_ONE_USER_DATA'
export const GET_ONE_USER_DATA_SUCCESS = 'GET_ONE_USER_DATA_SUCCESS'



export default {
    CONSULTA_USER_GET,
    CREATE_MODULE_USER,
    DELETE_USER_POST,
    DELETE_MODULE_USER,
    DELETE_MODULE_USER_SUCCESS,
    UPDATE_USER_POST,
    USER_SUCCESS,
    GET_MODULE_USER,
    GET_MODULE_USER_SUCCESS,
    USER_SUCCESS_ONLY,
    CONSULTA_USER_EXITOSO,
    CREATE_MODULE_USER_SUCCESS,
    CREATE_USER_EXITOSO,
    DELETE_USER_EXITOSO,
    UPDATE_USER_PASS,
    UPDATE_USER_DATA,
    CREATE_USER_TOKEN_PASS,
    USER_EMAIL_SUCCESS_ONLY,
    USER_SUCCESS_ONLY_DATA,
    USER_SUCCESS_DATA,
    GET_ONLY_ONE_USER,
    GET_ONLY_ONE_USER_SUCCESS,
    CREATE_USER_TOKEN_PASS_SUCCESS,
    GET_ONE_USER_DATA,
    GET_ONE_USER_DATA_SUCCESS
}