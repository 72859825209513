import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PageHeaderAlt from "components/layout-components/PageHeaderAlt";
import { useSelector, useDispatch } from "react-redux";

// Importaciones de componentes y librerías
import {
  Tabs,
  Form,
  Button,
  Select,
  TreeSelect,
  Row,
  Col,
  Spin,
  message,
  Checkbox
} from "antd";
import Flex from "components/shared-components/Flex";
import "moment/locale/es";

// importaciones del util
import util from "utils";

// importaciones de las acciones
import {
  listCentrosAction,
  listPresupuestosAction,
  postPresupuestoDataAction,
  postProcesarSacAction
} from "redux/actions/servicio-presupuesto/Presupuesto";

// importaciones de las selectors
import {
  selectCentrosPayload,
  selectPresupuestoPayload,
  selectLoadingProcess,
  selectProcessSacLoading
} from "redux/selectors/servicio-presupuesto/presupuesto.selectors";

const { TabPane } = Tabs;
const ADD = "ADD";

// Creación de componente
const ComprobanteForm = (props) => {
  const { mode = ADD } = props;
  let history = useHistory();
  let dispatch = useDispatch();

  const centros = useSelector(selectCentrosPayload);
  const presupuestos = useSelector(selectPresupuestoPayload);
  const isLoadingProcess = useSelector(selectLoadingProcess);
  const isLoadingProcessSac = useSelector(selectProcessSacLoading);

  const [form] = Form.useForm();

  // Se definen useState
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    dispatch(listCentrosAction());
    dispatch(listPresupuestosAction());
  }, []);

  const onFinish = (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    form
      .validateFields()
      .then((values) => {
        setTimeout(() => {
          setSubmitLoading(false);
          if (mode === ADD && values) {
            dispatch(postPresupuestoDataAction(values));
          }
        }, 1500);
      })
      .catch((info) => {
        setSubmitLoading(false);
        message.error("¡Por favor complete todos los campos! ");
      });
  };

  const allIdsCentros = centros.map(({ value }) => value);
  const [selectedCentros, setSelectedCentros] = useState([]);

  if (selectedCentros.length === centros.length) {
    form.setFieldsValue({
      centros: selectedCentros,
    });
  } else {
    form.setFieldsValue({
      centros: selectedCentros,
    });
  }

  return (
    <>
      <Form
        encType="multipart/form-data"
        layout="vertical"
        form={form}
        name="advanced_search"
        className="ant-advanced-search-form"
      >
        <PageHeaderAlt className="border-bottom" overlap>
          <div className="container">
            <Flex
              className="py-2"
              mobileFlex={false}
              justifyContent="between"
              alignItems="center"
            >
              <h2 className="mb-3">
                {mode === "ADD" ? "Procesar Presupuesto" : ""}
              </h2>
              <div className="mb-3">
                <Button
                  className="mr-2"
                  onClick={() => {
                    for (let index = 0; index < 2; index++) {
                      history.goBack();
                    }
                  }}
                >
                  Descartar
                </Button>
                <Button
                  type="primary"
                  onClick={(e) => onFinish(e)}
                  htmlType="button"
                  loading={submitLoading}
                  disabled={isLoadingProcess}
                >
                  {mode === "ADD" ? "Procesar" : ""}
                </Button>
              </div>
            </Flex>
          </div>
        </PageHeaderAlt>
        <div className="container">
          <Tabs defaultActiveKey="1" style={{ marginTop: 30 }}>
            <TabPane tab="General" key="1">
              <Spin
                tip="Procesando..."
                size="large"
                spinning={isLoadingProcess || isLoadingProcessSac}
              >
                <Row gutter={16}>
                  <Col xs={24} sm={15} md={15} lg={15} xl={20} xxl={8}>
                    <Form.Item
                      name="presupuestoAnterior"
                      label="Presupuesto Anterior"
                      rules={util.rules.ValidationPresupuesto}
                    >
                      <Select
                        allowClear={true}
                        name="presupuestoAnterior"
                        showSearch
                        placeholder="Seleccionar presupuesto"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {presupuestos.map((selection) => (
                          <Select.Option
                            value={selection.value}
                            key={selection.value}
                          >
                            {selection.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col xs={24} sm={15} md={15} lg={15} xl={20} xxl={8}>
                    <Form.Item
                      name="presupuestoActual"
                      label="Presupuesto Actual"
                      rules={util.rules.ValidationPresupuesto}
                    >
                      <Select
                        allowClear={true}
                        name="presupuestoActual"
                        showSearch
                        placeholder="Seleccionar presupuesto"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {presupuestos.map((selection) => (
                          <Select.Option
                            value={selection.value}
                            key={selection.value}
                          >
                            {selection.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item
                  name="centros"
                  label="Unidad de Negocio"
                  rules={util.rules.ValidationPresupuesto}
                >
                  <Row gutter={16}>
                    <Col xs={24} sm={15} md={15} lg={15} xl={20} xxl={8}>
                      <TreeSelect
                        popupClassName="centros"
                        allowClear={true}
                        placeholder="Seleccionar unidad de negocio"
                        treeCheckable={true}
                        showCheckedStrategy={TreeSelect.SHOW_CHILD}
                        onChange={(e) => setSelectedCentros(e)}
                        value={selectedCentros}
                        // maxTagCount={2}
                        // maxTagPlaceholder={(omittedValues) =>
                        //   `+ ${omittedValues.length} Centros ...`
                        // }
                        treeData={[
                          {
                            title:
                              selectedCentros.length === centros.length ? (
                                <span
                                  onClick={() => setSelectedCentros([])}
                                  style={{
                                    display: "inline-block",
                                    color: "#286FBE",
                                    cursor: "pointer",
                                  }}
                                >
                                  Deseleccionar todo
                                </span>
                              ) : (
                                <span
                                  onClick={() =>
                                    setSelectedCentros(allIdsCentros)
                                  }
                                  style={{
                                    display: "inline-block",
                                    color: "#286FBE",
                                    cursor: "pointer",
                                  }}
                                >
                                  Seleccionar todo
                                </span>
                              ),
                            value: "xxx",
                            disableCheckbox: true,
                            disabled: false,
                          },
                          ...centros,
                        ]}
                      />

                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item
                  name="sac"
                  label="¿Deseas cargar en el SAC?"
                  valuePropName="checked"
                  initialValue={true}
                >
                  <Checkbox checked={true} />
                </Form.Item>
              </Spin>
            </TabPane>
          </Tabs>
        </div>
      </Form>
    </>
  );
};

export default ComprobanteForm;
