import authConstants from '../../constants/servicio-autenticacion/Auth';

// acción de iniciar sesión
// type: Indica como debería cambiar el estado
// payload: Indica lo que deberia cambiar en estado
export const signInAction = (user) => {
  return {
    type: authConstants.SIGNIN,
    payload: user
  }
};

// acción de iniciar sesión con la api
export const signInWithApiAction = (user) => {
  return {
    type: authConstants.SIGNIN_WITH_API,
    payload: user
  }
};

// acción de autenticación
export const authenticatedAction = (user) => {
  const { token, username } = user
  return {
    type: authConstants.AUTHENTICATED,
    payload: { token, username }
  }
};

export const signOutAction = () => {
  return {
    type: authConstants.SIGNOUT
  };
};

// acción de desloguearse
export const signOutSuccessAction = () => {
  return {
    type: authConstants.SIGNOUT_SUCCESS,
    
  }
};

// acción de deslogueo exitoso
export const signUpAction = (user) => {
  return {
    type: authConstants.SIGNUP,
    payload: user
  };
};

export const signUpSuccessAction = (token) => {
  return {
    type: authConstants.SIGNUP_SUCCESS,
    payload: token
  };
};

export const signInWithGoogleAction = () => {
  return {
    type: authConstants.SIGNIN_WITH_GOOGLE
  };
};

export const signInWithGoogleAuthenticatedAction = token => {
  return {
    type: authConstants.SIGNIN_WITH_GOOGLE_AUTHENTICATED,
    payload: token
  };
};

export const signInWithFacebookAction = () => {
  return {
    type: authConstants.SIGNIN_WITH_FACEBOOK
  };
};

export const signInWithFacebookAuthenticatedAction = token => {
  return {
    type: authConstants.SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
    payload: token
  };
};

// acción de mostrar mensaje de autenticación
export const showAuthMessageAction = message => {
  return {
    type: authConstants.SHOW_AUTH_MESSAGE,
    payload: message
  };
};

export const hideAuthMessageAction = () => {
  return {
    type: authConstants.HIDE_AUTH_MESSAGE,
  };
};

export const showLoadingAction = () => {
  return {
    type: authConstants.SHOW_LOADING,
  };
};


export default {
  signInAction,
  signInWithApiAction,
  authenticatedAction,
  signOutAction,
  signOutSuccessAction,
  signUpAction,
  signUpSuccessAction,
  signInWithGoogleAction,
  signInWithGoogleAuthenticatedAction,
  signInWithFacebookAction,
  signInWithFacebookAuthenticatedAction,
  showAuthMessageAction,
  hideAuthMessageAction,
  showLoadingAction
}