import {all, takeEvery, fork,} from "redux-saga/effects";
import {GET_MODULES} from "../../constants/servicio-autenticacion/Home";
import homeFunctions from './functions/Home.functions'
const axios = require("axios");

// Se realiza peticiones a la api para traer los modulos
export function* listModulesSaga() {
  yield takeEvery( GET_MODULES, homeFunctions.listModules );
}

export default function* rootSagaModules() {
  yield all([
    fork(listModulesSaga)
  ]);
}
