import solicitudesConstants from "../../constants/servicio-gestion-vacaciones/Solicitudes";
 
const initState = {
  payload: [],
  statusLider: [],
  loading: false,
  updating: false,
  dias: 0,
  mensaje: "",
  daysInput: [],
  loadingM: false,
  payloadM: [],
};

const solicitudesReducer = (state = initState, action) => {
  switch (action.type) {
   
    case solicitudesConstants.UPDATE_STATUS_SOLICITUD:
    case solicitudesConstants.CONSULTA_SOLICITUDES: {
      return {
        ...state,
      };
    }

    case solicitudesConstants.CONSULTA_SOLICITUDES_CURRENT_USER:
    case solicitudesConstants.CONSULTA_SOLICITUDES_TERCEROS_CURRENT_USER:
    case solicitudesConstants.CONSULTA_CONTROL: {
      return {
        ...state,
        loading: true,
      };
    }

    case solicitudesConstants.CONSULTA_SOLICITUDES_CONTROL: {
      return {
        ...state,
        loading: false,
        mensaje: "ExisteFecha",
      };
    }
    case solicitudesConstants.GET_DIAS: {
      return {
        ...state,
        daysInput: action.data,
      };
    }

    case solicitudesConstants.GET_DIAS_SUCCCESS: {
      return {
        ...state,
        dias: action.data,
      };
    }

    case solicitudesConstants.CONSULTA_SOLICITUDES_SUCCESS:
    case solicitudesConstants.CONSULTA_SOLICITUDES_CURRENT_USER_SUCCESS:
    case solicitudesConstants.CONSULTA_SOLICITUDES_TERCEROS_CURRENT_USER_SUCCESS:{
      return {
        ...state,
        payload: action.payload,
        loading: false,
      };
    }
    case solicitudesConstants.ADD_SOLICITUDES:
    case solicitudesConstants.UPDATE_SOLICITUDES:
    case solicitudesConstants.UPDATE_STATUS: {
      return {
        ...state,
        loading: true,
        updating: true,
      };
    }

    case solicitudesConstants.ADD_SOLICITUDES_SUCCESS:
    case solicitudesConstants.UPDATE_SOLICITUDES_SUCCESS:
    case solicitudesConstants.UPDATE_STATUS_SUCCESS: {
      return {
        ...state,
        loading: false,
        updating: false,
      };
    }

    case solicitudesConstants.UPDATE_STATUS_SOLICITUD_SUCCESS: {
      return {
        ...state,
        statusPayload: action.data,
      };
    }

    //? ------------Nuevas logicas -> M------------------------------
    //? Reducer Sumar dias de solicitudes Aprobadas en flag false
    case solicitudesConstants.SUM_DAYS: {
      return {
        ...state,
      };
    }

    case solicitudesConstants.SUM_DAYS_SUCCESS: {
      return {
        ...state,
        payloadM: action.data,
      };
    }

    //? Reducer contar las solicitudes aprobadas y flag false
    case solicitudesConstants.COUNT_SOLICITUDES_DAYS: {
      return {
        ...state,
      };
    }

    case solicitudesConstants.COUNT_SOLICITUDES_DAYS_SUCCESS: {
      return {
        ...state,
        payloadM: action.data,
      };
    }

    //? Reducer Eliminar las solicitudes M
    case solicitudesConstants.DELETE_M_SOLICITUDES: {
      return {
        ...state,
      };
    }

    case solicitudesConstants.DELETE_M_SOLICITUDES_SUCCESS: {
      return {
        ...state,
      };
    }

    default:
      return state;
  }
};

export default solicitudesReducer;