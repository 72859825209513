

export const CONSULTA_SOLICITUDES = "CONSULTA_SOLICITUDES";
export const CONSULTA_SOLICITUDES_SUCCESS = "CONSULTA_SOLICITUDES_SUCCESS";

export const ADD_SOLICITUDES = "ADD_SOLICITUDES";
export const ADD_SOLICITUDES_SUCCESS = "ADD_SOLICITUDES_SUCCESS";

export const DELETE_SOLICITUDES = "DELETE_SOLICITUDES";
export const DELETE_SOLICITUDES_SUCCESS = "DELETE_SOLICITUDES_SUCCESS";

export const UPDATE_SOLICITUDES = "UPDATE_SOLICITUDES";
export const UPDATE_SOLICITUDES_SUCCESS = "UPDATE_SOLICITUDES_SUCCESS";

export const UPDATE_STATUS = "UPDATE_STATUS";
export const UPDATE_STATUS_SUCCESS = "UPDATE_STATUS_SUCCESS";

export const GET_DIAS = "GET_DIAS";
export const GET_DIAS_SUCCCESS = "GET_DIAS_SUCCCESS";

export const CONSULTA_SOLICITUDES_CONTROL = "CONSULTA_SOLICITUDES_CONTROL";
export const CONSULTA_CONTROL = "CONSULTA_CONTROL";

export const UPDATE_STATUS_SOLICITUD = "UPDATE_STATUS_SOLICITUD";
export const UPDATE_STATUS_SOLICITUD_SUCCESS = "UPDATE_STATUS_SOLICITUD_SUCCESS";

// Constantes utilizados para la nueva logica "M"
export const SUM_DAYS = "SUM_DAYS";
export const SUM_DAYS_SUCCESS = "SUM_DAYS_SUCCESS";

export const COUNT_SOLICITUDES_DAYS = "COUNT_SOLICITUDES_DAYS";
export const COUNT_SOLICITUDES_DAYS_SUCCESS = "COUNT_SOLICITUDES_DAYS_SUCCESS";

export const DELETE_M_SOLICITUDES = "DELETE_M_SOLICITUDES";
export const DELETE_M_SOLICITUDES_SUCCESS = "DELETE_M_SOLICITUDES_SUCCESS";


export const CONSULTA_SOLICITUDES_CURRENT_USER = "CONSULTA_SOLICITUDES_CURRENT_USER"
export const CONSULTA_SOLICITUDES_CURRENT_USER_SUCCESS = "CONSULTA_SOLICITUDES_CURRENT_USER_SUCCESS"

export const CONSULTA_SOLICITUDES_TERCEROS_CURRENT_USER = "CONSULTA_SOLICITUDES_TERCEROS_CURRENT_USER"
export const CONSULTA_SOLICITUDES_TERCEROS_CURRENT_USER_SUCCESS = "CONSULTA_SOLICITUDES_TERCEROS_CURRENT_USER_SUCCESS" 


export default {
    CONSULTA_SOLICITUDES, CONSULTA_SOLICITUDES_SUCCESS,
    ADD_SOLICITUDES, ADD_SOLICITUDES_SUCCESS,
    DELETE_SOLICITUDES, DELETE_SOLICITUDES_SUCCESS,
    UPDATE_SOLICITUDES, UPDATE_SOLICITUDES_SUCCESS,
    UPDATE_STATUS, UPDATE_STATUS_SUCCESS,
    GET_DIAS, GET_DIAS_SUCCCESS,
    CONSULTA_SOLICITUDES_CONTROL, CONSULTA_CONTROL,
    UPDATE_STATUS_SOLICITUD, UPDATE_STATUS_SOLICITUD_SUCCESS,
    SUM_DAYS, SUM_DAYS_SUCCESS,
    COUNT_SOLICITUDES_DAYS, COUNT_SOLICITUDES_DAYS_SUCCESS,
    DELETE_M_SOLICITUDES, DELETE_M_SOLICITUDES_SUCCESS,
    CONSULTA_SOLICITUDES_CURRENT_USER, CONSULTA_SOLICITUDES_CURRENT_USER_SUCCESS,
    CONSULTA_SOLICITUDES_TERCEROS_CURRENT_USER, CONSULTA_SOLICITUDES_TERCEROS_CURRENT_USER_SUCCESS
}