// Importaciones de componentes y librerías
import React, { useState, useEffect } from "react";
import {
  Card,
  Table,
  Select,
  Input,
  Spin,
  message,
  DatePicker,
  Checkbox,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Flex from "components/shared-components/Flex";
import { tableColumns } from "./vista.factura.utils";
import { useDispatch, useSelector } from "react-redux";
import "moment/locale/es";
import moment from "moment";
import locale from "antd/es/date-picker/locale/es_ES";

// importaciones del util
import utils from "utils";

// importaciones de las acciones
import {
  listFacturasAction,
  listFileAction,
} from "redux/actions/servicio-vista-factura/vistaFactura";

// importaciones de las selectors
import {
  selectFacturasLoading,
  selectFacturasPayload,
  selectFilePayload,
} from "redux/selectors/servicio-vista-factura/VistaFactura.selectors";

const { Option } = Select;
const { RangePicker } = DatePicker;

const DATEFORMAT = "DD/MM/YYYY";
const CATEGORIES = ["Cancelado", "Aprobado", "Rechazado", "Recibido"];
const CATEGORIESCONDITIONAL = ["No", "Si"];

// Creación del componente
const BillList = () => {
  let dispatch = useDispatch();

  const factura = useSelector(selectFacturasPayload);
  const loading = useSelector(selectFacturasLoading);
  const getAllPdf = useSelector(selectFilePayload);

  const [list, setList] = useState(factura);

  useEffect(() => {
    dispatch(listFacturasAction());
    dispatch(listFileAction());
  }, []);

  const handleDocumentClick = (ruta_doc) => {
    for (let i = 0; i < getAllPdf.length; i++) {
      const element = getAllPdf[i];
      if (ruta_doc === element.name) {
        const binaryData = Uint8Array.from(atob(element.file), (c) =>
          c.charCodeAt(0)
        );
        const pdfBlob = new Blob([binaryData], { type: "application/pdf" });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        const newWindow = window.open(pdfUrl, "_blank");
        if (!newWindow) {
          message.warning(
            "El bloqueador de ventanas emergentes impidió abrir el PDF. Por favor, habilite las ventanas emergentes."
          );
        }
      }
    }
  };

  useEffect(() => {
    setList(factura);
  }, [factura]);

  const onSearch = (e) => {
    const value = e.currentTarget.value;
    const isEmpty = () => {
      if (list == []) return true;
    };
    const searchArray = value && !isEmpty ? list : factura;
    const data = utils.wildCardSearch(searchArray, value);
    setList(data);
  };

  const handleShowCategory = (value) => {
    if (value !== "All") {
      const key = "estado_doc";
      const data = utils.filterArray(factura, key, value);
      setList(data);
    } else {
      setList(factura);
    }
  };

  const handleShowCategory2 = (value) => {
    if (value !== "All") {
      const key = "registo_unysoft";
      const data = utils.filterArray(factura, key, value);
      setList(data);
    } else {
      setList(factura);
    }
  };

  // Función para establecer la hora UTC a las 16:00 para una fecha
  function setUtcTimeTo16(date) {
    const dateMoment = moment(date).utc();
    return dateMoment.set({ hour: 16, minute: 0, second: 0, millisecond: 0 });
  }

  const applyFilter = (selectedDates) => {
    if (selectedDates?.length === 2) {
      const startDate = setUtcTimeTo16(moment(selectedDates[0]).startOf("day")); // Use 'startOf' to set time to midnight
      const endDate = setUtcTimeTo16(moment(selectedDates[1]).startOf("day")); // Use 'endOf' to set time to the end of the day

      const filteredDates = factura.filter((date) => {
        const dateMoment = setUtcTimeTo16(moment(date.fecha_emision));
        return (
          dateMoment.isSameOrAfter(startDate) &&
          dateMoment.isSameOrBefore(endDate)
        );
      });

      setList(filteredDates);
    } else {
      setList(factura);
    }
  };

  const [checkedCorreo, setCheckedCorreo] = useState(false);
  const [checkedMarangatu, setCheckedMarangatu] = useState(false);

  const BooleanFilter = (e, selected) => {
    const filteredRecCorreo = factura.filter((data) => data.rec_correo === 1);
    const filteredDescMarangatu = factura.filter(
      (data) => data.desc_marangatu === 1
    );

    const selectedData = selected ? filteredRecCorreo : filteredDescMarangatu;

    if (e.target.checked) {
      setList(selectedData);

      if (selected) {
        setCheckedCorreo(true);
        setCheckedMarangatu(false);
      } else {
        setCheckedCorreo(false);
        setCheckedMarangatu(true);
      }
    } else {
      setList(factura);
      setCheckedCorreo(false);
      setCheckedMarangatu(false);
    }
  };

  return (
    <Card>
      <Flex alignItems="center" justifyContent="between" mobileFlex={false}>
        <Flex className="mb-1" mobileFlex={false}>
          <div className="mr-md-3 mb-3">
            <Input
              placeholder="Buscar"
              prefix={<SearchOutlined />}
              onChange={(e) => onSearch(e)}
            />
          </div>

          <div className="mr-md-3 mb-3">
            <Select
              defaultValue="All"
              className="w-100"
              style={{ minWidth: 180 }}
              onChange={handleShowCategory}
              placeholder="Category"
            >
              <Option key="All" value="All">
                Todos los estados
              </Option>
              {CATEGORIES.map((elm) => (
                <Option value={elm} key={elm}>
                  {elm}
                </Option>
              ))}
            </Select>
          </div>

          <div className="mr-md-3 mb-3">
            <Select
              defaultValue="All"
              className="w-100"
              style={{ minWidth: 180 }}
              onChange={handleShowCategory2}
              placeholder="Category"
            >
              <Option key="All" value="All">
                Todos los registros
              </Option>
              {CATEGORIESCONDITIONAL.map((elm) => (
                <Option value={elm} key={elm}>
                  {elm}
                </Option>
              ))}
            </Select>
          </div>

          <div className="mr-md-3 mb-3">
            <RangePicker
              className="w-100"
              locale={locale}
              format={DATEFORMAT}
              onChange={(date) => applyFilter(date)}
            ></RangePicker>
          </div>

          <div className="mr-md-3 mb-3">
            <Checkbox
              className="w-100"
              disabled={checkedMarangatu}
              onChange={(e) => BooleanFilter(e, true)}
            >
              Rec. Correo
            </Checkbox>
          </div>
          <div className="mr-md-3 mb-3">
            <Checkbox
              className="w-100"
              disabled={checkedCorreo}
              onChange={(e) => BooleanFilter(e, false)}
            >
              Desc. Marangatu
            </Checkbox>
          </div>
        </Flex>
      </Flex>
      {loading ? (
        <div className="spin">
          <Spin />
        </div>
      ) : (
        <div className="table-responsive">
          <Table
            columns={tableColumns(handleDocumentClick, list)}
            dataSource={list}
            size="small"
            rowKey="_id"
            scroll={{
              x: 200,
            }}
            pagination={{
              pageSize: 10,
              pageSizeOptions: ["10"],
            }}
          />
        </div>
      )}
    </Card>
  );
};

export default BillList;
