import { all } from "redux-saga/effects";
import Auth from "./servicio-autenticacion/Auth";
import Home from "./servicio-autenticacion/Home";
import Roles from "./servicio-autenticacion/Roles";
import User from "./servicio-autenticacion/User";
import Profile from "./servicio-autenticacion/Profile";
import Modulos from "./servicio-autenticacion/Modulos";
import UserAdmin from "./servicio-autenticacion/UserAdmin";

import Movimientos from "./servicio-gestion-vacaciones/Movimientos";
import Solicitudes from "./servicio-gestion-vacaciones/Solicitudes";
import SolicitudesUsers from "./servicio-gestion-vacaciones/Users";
import Comprobante from "./servicio-gestion-vacaciones/Comprobantes";

import Certificados from "./servicio-contratos/Certificados";
import Contratos from "./servicio-contratos/Contratos";
import UserContratos from "./servicio-contratos/Users";

import Liquidaciones from "./servicio-liquidacion-digital/Liquidaciones";

import Unysoft from "./servicio-gestion-unysoft/ServiceUnysoft";

import Reportes from "./servicio-reportes/Reportes";
import UserReportes from "./servicio-reportes/Users";

import Utils from "./servicio-gestion-vacaciones/Utils";

import Presupuesto from "./servicio-presupuesto/Presupuesto";

import VistaFactura from "./servicio-vista-factura/vistaFactura";

import Playero from "./servicio-playero/Playero";

export default function* rootSaga() {
  yield all([
    Auth(),
    Home(),
    Roles(),
    User(),
    Modulos(),
    Profile(),
    Movimientos(),
    Solicitudes(),
    SolicitudesUsers(),
    Comprobante(),
    UserAdmin(),
    Certificados(),
    Contratos(),
    UserContratos(),
    Liquidaciones(),
    Unysoft(),
    Reportes(),
    UserReportes(),
    Utils(),
    Presupuesto(),
    VistaFactura(),
    Playero(),
  ]);
}
