export const GET_FACTURAS = "GET_FACTURAS";
export const GET_FACTURAS_SUCCESS = "GET_FACTURAS_SUCCESS";

export const GET_FILE = "GET_FILE";
export const GET_FILE_SUCCESS = "GET_FILE_SUCCESS";

export const GET_ONE_FILE = "GET_ONE_FILE";
export const GET_ONE_FILE_SUCCESS = "GET_ONE_FILE_SUCCESS";

export default {
  GET_FACTURAS,
  GET_FACTURAS_SUCCESS,
  GET_FILE,
  GET_FILE_SUCCESS,
  GET_ONE_FILE,
  GET_ONE_FILE_SUCCESS

};
