//? Se importan las constantes 
import usersConstants  from "../../constants/servicio-contratos/Users";

//? Accion de listar usuarios de dominio
export const listUsersContratosAction = () => {
  return {
    type: usersConstants.GET_USERS_DOMAIN_CONTRATOS,
  };
};

export const listUsersContratosSuccessAction = (data) => {
  return {
    type: usersConstants.GET_USERS_DOMAIN_CONTRATOS_SUCCESS,
    payload: data,
  };
};

//? Accion de listar todo los usuarios de contratos
export const listAllUserContratosAction = () => {
  return {
    type: usersConstants.GET_ALL_USERS_CONTRATOS,
  };
};

export const listAllUserContratosSuccessAction = (data) => {
  return {
    type: usersConstants.GET_ALL_USERS_CONTRATOS_SUCCESS,
    payload: data,
  };
};

//? Accion de crear usuarios de contratos
export const createUserContratos = (data) => {
  return {
    type: usersConstants.CREATE_USER_CONTRATOS,
    payload: data,
  };
};

export const createUserContratosSuccessAction = () => {
  return {
    type: usersConstants.CREATE_USER_CONTRATOS_SUCCESS,
  };
};

//? Accion editar usuarios de contratos
export const editUserContratosAction = (dataUser) => {
  return {
    type: usersConstants.EDIT_USER_CONTRATOS,
    payload: dataUser,
  };
};

export const editUserContratosSucessAcction = () => {
  return {
    type: usersConstants.EDIT_USER_CONTRATOS_SUCCESS,
  };
};

//? Accion de eliminar usuarios de contratos
export const deleteUserContratosAction = (data) => {
  return {
    type: usersConstants.DELETE_USER_CONTRATOS,
    payload: data,
  };
};

export const deleteUserContratosSuccessAction = () => {
  return {
    type: usersConstants.DELETE_USER_CONTRATOS_SUCCESS,
  };
};

 //? Accion de listar solo un usuario de contratos
 export const listOneUserContratosAction = (data) => {
  return {
    type: usersConstants.GET_ONE_USER_CONTRATOS,
    payload: data,
  };
};

export const listOneUserContratosSuccessAction = (data) => {
  return { 
    type: usersConstants.GET_ONE_USER_CONTRATOS_SUCCESS,
    payload: data
  };
};

//? Accion de listar solo un usuario utilizando endpoint de servicio de autenticacion
  export const listUserAdminAction = (data) => {
    return {
      type: usersConstants.GET_USER_ADMIN,
      payload: data,
    };
  };

  export const listUserAdminSuccessAction = (data) => {
    return {
      type: usersConstants.GET_USER_ADMIN_SUCCESS,
      payload: data
    };
  };


  export default {
    listUsersContratosAction,
    listUsersContratosSuccessAction,
    listAllUserContratosAction,
    listAllUserContratosSuccessAction,
    createUserContratos,
    createUserContratosSuccessAction,
    editUserContratosAction,
    editUserContratosSucessAcction,
    deleteUserContratosAction,
    deleteUserContratosSuccessAction,
    listOneUserContratosAction,
    listOneUserContratosSuccessAction,
    listUserAdminAction,
    listUserAdminSuccessAction
  }