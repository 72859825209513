import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';
import vistaFactura from "./vistaFactura";
import withRole from './HOC/withRoles';

const PrincipalRestricted = withRole(false, ['admin, colaboradores, lider'], vistaFactura)

const Apps = ({ match }) => (
  <Suspense fallback={<Loading cover="content" />}>
    <Switch>
      <Route path={`${match.url}/portal-bill/bill`} component={lazy(() => import(`./vistaFactura`))} />
      <Redirect from={`${match.url}`} to={`${match.url}/portal-bill/bill`} component={PrincipalRestricted} />
    </Switch>
  </Suspense>
);

export default Apps;