import { all, takeEvery, fork } from "redux-saga/effects";
import {
  UPDATE_COMPROBANTE, GET_COMPROBANTE,
  GET_STATE_COMPROBANTE, GET_STATE_FIRMA_COMPROBANTE,
  GET_FIRMA_ALL, GET_COMPROBANTE_CURRENT_USER,
  ADD_COMPROBANTE, DELETE_COMPROBANTE } from "../../constants/servicio-gestion-vacaciones/Comprobantes";
import comprobantesFunctions from './functions/Comprobantes.functions'


//? Funcion para agregar los comprobantes
export function* addComprobanteSaga() {
  yield takeEvery(ADD_COMPROBANTE, comprobantesFunctions.addComprobante );
}

//? Funcion para editar Comprobantes
export function* editComprobanteSaga() {
  yield takeEvery(UPDATE_COMPROBANTE, comprobantesFunctions.editComprobante );
}

//? Funcion para eliminar Comprobantes
export function* deleteComprobanteSaga() {
  yield takeEvery(DELETE_COMPROBANTE, comprobantesFunctions.deleteComprobante );
}

//? Funcion para listar los comprobantes
export function* listComprobanteSaga() {
  yield takeEvery(GET_COMPROBANTE, comprobantesFunctions.listComprobante );
}

export function* CurrentUserComprobanteSaga() {
  yield takeEvery(GET_COMPROBANTE_CURRENT_USER, comprobantesFunctions.CurrentUserComprobante );
}

//? Funcion para cambiar el estado del comprobante de PDF
export function* changeStatePdfComprobanteSaga() {
  yield takeEvery(GET_STATE_COMPROBANTE, comprobantesFunctions.changeStatePdfComprobante );
}

//? Funcion para cambiar el estado del comprobante de Firmas
export function* changeStateFirmaComprobanteSaga() {
  yield takeEvery(GET_STATE_FIRMA_COMPROBANTE, comprobantesFunctions.changeStateFirmaComprobante );
}

//? Funcion para cambiar el estado del comprobante
export function* firmaAllSaga() {
  yield takeEvery(GET_FIRMA_ALL, comprobantesFunctions.firmaAll );
}


export default function* rootSagaComprobante() {
  yield all([
    fork(listComprobanteSaga),
    fork(CurrentUserComprobanteSaga),
    fork(changeStatePdfComprobanteSaga),
    fork(changeStateFirmaComprobanteSaga),
    fork(listComprobanteSaga),
    fork(firmaAllSaga),
    fork(addComprobanteSaga),
    fork(deleteComprobanteSaga),
    fork(editComprobanteSaga)
  ]);
}
