import userAdminConstants from "../../constants/servicio-autenticacion/UserAdmin";
  
  export const listUserAdminAction = () => {
    return {
      type: userAdminConstants.USER_GET_ADMIN,
    };
  }
  
  export const addUserAdmin = data => {
    return {
      type: userAdminConstants.USER_ADD_ADMIN,
      payload: data,
    };
  };

  export const consultaOneUser = (data) => {
    return {
      type: userAdminConstants.USER_ONLY_GET_ADMIN,
      payload: data
    }
  };

  export const listOneUserAdmin=(data)=>{
    return {
      type: userAdminConstants.USER_ONLY_ADMIN,
      payload: data
    }
  }

  export const editUserAdmin=(data)=>{
    return {
      type: userAdminConstants.USER_EDIT_ADMIN,
      payload: data
    }
  }

  export const userSuccessAdmin = data =>{
    return{
      type:userAdminConstants.USER_SUCCESS_ADMIN,
      payload: data
    }
  }
  
  export const userSuccessOnlyAdmin = data =>{
    return{
      type:userAdminConstants.USER_SUCCESS_ONLY_ADMIN,
      payload: data
    }
  }

  export const userSuccessOnlyAdminCi = data =>{
    return{
      type:userAdminConstants.USER_SUCCESS_ONLY_ADMIN_CI,
      payload: data
    }
  }

  export const userSuccessEditAdmin = data =>{
    return{
      type:userAdminConstants.USER_SUCCESS_EDIT_ADMIN,
      payload: data
    }
  }

  export default {
    listUserAdminAction, 
    addUserAdmin, 
    consultaOneUser,
    listOneUserAdmin, 
    editUserAdmin,
    userSuccessAdmin,
    userSuccessOnlyAdmin,
    userSuccessOnlyAdminCi,
    userSuccessEditAdmin
  }