//? Se importan las constantes
import contratosConstants from "../../constants/servicio-contratos/Contratos";

// acción de iniciar sesión
// type: Indica como debería cambiar el estado
// payload: Indica lo que deberia cambiar en estado

//? Accion de listar contratos
export const listContratos = () => {
  return {
    type: contratosConstants.GET_CONTRATOS,
  };
};

export const listadoContratos = (data) => {
  //!Cambiar mas adelante listadoContratosSuccess
  return {
    type: contratosConstants.GET_CONTRATOS_SUCCESS,
    payload: data,
  };
};

//? Accion de agregar contratos
export const addContratos = (data) => {
  return {
    type: contratosConstants.ADD_CONTRATOS,
    payload: data,
  };
};

export const SuccessAddContratosAction = () => {
  return {
    type: contratosConstants.ADD_CONTRATOS_SUCCESS
  };
};

//? Accion de listar solo un contrato por id
export const listIDContratos = (data) => {
  return {
    type: contratosConstants.GET_CONTRATOS_ID,
    payload: data,
  };
};

//? Accion de listar solo un contrato por id
export const listIDContratosSuccess = (data) => {
  return {
    type: contratosConstants.GET_CONTRATOS_ID_SUCCESS,
    payload: data,
  };
};

//? Accion de eliminar contratos
export const deleteContratos = (data) => {
  return {
    type: contratosConstants.DELETE_CONTRATOS,
    payload: data,
  };
};

//? Accion de eliminar contratos
export const deleteContratoSuccess = () => {
  return {
    type: contratosConstants.DELETE_CONTRATOS_SUCCESS
  };
};

//? Accion de actualizar contratos
export const updateContrato = (data) => {
  return {
    type: contratosConstants.UPDATE_CONTRATOS,
    payload: data,
  };
};

//? Accion de actualizar contratos
export const updateContratoSuccess = () => {
  return {
    type: contratosConstants.UPDATE_CONTRATOS_SUCCESS
  };
};

//? Accion de carga de estado de contratos
export const showLoading = () => {
  return {
    type: contratosConstants.SHOW_LOADING,
  };
};

//? Accion de Agregar archivos
export const addFilesAction = (data) => {
  return {
    type: contratosConstants.POST_FILES,
    payload: data,
  };
};

export const SuccessAddFilesAction = () => {
  return {
    type: contratosConstants.POST_FILES_SUCCESS,
  };
};

//? Accion de eliminar Archivos
export const deleteFilesAction = (data) => {
  return {
    type: contratosConstants.DELETE_FILES,
    payload: data,
  };
};

export const successDeleteFilesAction = (data) => {
  return {
    type: contratosConstants.DELETE_FILES_SUCCESS,
    payload: data
  };
};

export default {
  listContratos,
  listadoContratos,
  addContratos,
  SuccessAddContratosAction,
  listIDContratos,
  listIDContratosSuccess,
  deleteContratos, deleteContratoSuccess,
  updateContrato, updateContratoSuccess,
  showLoading,
  addFilesAction,
  SuccessAddFilesAction,
  deleteFilesAction,
  successDeleteFilesAction
}