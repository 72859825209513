import { put, call} from "redux-saga/effects";
import { USER_NAME } from "../../../constants/servicio-autenticacion/Auth";
import {message} from "antd";
import serviceProfile from "axios/servicio-autenticacion/Profile";
import profileActions from '../../../actions/servicio-autenticacion/Profile'

function* listProfile() {
    try {
      const profile = yield call(serviceProfile.listprofileSaga);
   
      if (profile.error == "true") {
        message.error(profile.error);
      } else {
        yield put(profileActions.ProfileSuccessAction(profile));
      }
    } catch (err) {
      message.error(err.message);
    }
}

function* updateProfile({payload}) {
    try {
      const datosEditados = yield call  (serviceProfile.updateProfile, payload);

      if (datosEditados) {
        const username = datosEditados.data.nombres + ' ' + datosEditados.data.apellidos;
        localStorage.setItem(USER_NAME, username);

        message.success('Datos modificados correctamente');
      }
    } catch (err) {
      message.error(err.message);
    }
}

export default {
    listProfile,
    updateProfile 
}
  