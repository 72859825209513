import { put, call} from "redux-saga/effects";
import {message} from "antd";
import serviceRole from "axios/servicio-autenticacion/Roles";
import rolesActions from '../../../actions/servicio-autenticacion/Roles'

function* listRole() {
    try {
      const roles = yield call(serviceRole.listRoleAxios);
      console.log('SAGAS FUNCTIONS listRole: ', roles )
      if (roles.error == "true") {
        message.error(roles.error);
      } else {
        yield put(rolesActions.rolesSuccessAction(roles));
      }
    } catch (err) {
      message.error(err.message);
    }
}


function* addRole({payload}) {
    try {
      console.log('SAGAS FUNCTIONS addRole: ', payload )
      const datosPermisos = yield call(serviceRole.addRoleAxios, payload);
      if (datosPermisos.msg) {
        message.error(datosPermisos.msg);
      } else {
        yield put(rolesActions.listRolesAction(datosPermisos));
        message.success("Rol creado!");
      }
    } catch (err) {
      message.error(err.message);
       
    }
}


function* deleteRole({payload}) {
    
    try {
      const datosEliminad = yield call(serviceRole.deleteRole, payload);

      if (datosEliminad.data.estado == true) {
        message.success(datosEliminad.data.mensaje);
        yield put(rolesActions.listRolesAction());
      }
    } catch (err) {
      message.error(err.message);
    }
}


function* updateRole({payload}) {

    try {
      const datosEditados = yield call  (serviceRole.updateRole, payload);

      if (datosEditados.data.estado == true) {
        message.success(datosEditados.data.mensaje);
        yield put(rolesActions.listRolesAction());
      }
    } catch (err) {
      message.error(err.message);
    }
}


export default {
    listRole,
    addRole,
    deleteRole,
    updateRole
}
