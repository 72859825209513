import utilsConstants from "../../constants/servicio-gestion-vacaciones/Utils";

const initState = {
  payload: [],
  loading: false,
};

const utilsReducer = (state = initState, action) => {
  switch (action.type) {
    case utilsConstants.POST_CALCULATE_VACACIONES: {
      return {
        ...state,
        loading: true,
      };
    }

    case utilsConstants.POST_CALCULATE_VACACIONES_SUCCESS: {
      return {
        ...state,
        loading: false,
        payload: action.payload,
      };
    }

    default:
      return state;
  }
};

export default utilsReducer;
