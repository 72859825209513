
import profileConstants from  "../../constants/servicio-autenticacion/Profile"


export const getProfileAction = () =>{
  return{
    type: profileConstants.GET_PROFILE
  }
}

export const ProfileSuccessAction = (data) => {
  return {
    type: profileConstants.PROFILE_SUCCESS,
    payload: data,
  };
};


export const updateProfileAction = (data) => {
    return {
      type: profileConstants.UPDATE_PROFILE,
      payload: data,
    };
  };

 
  export default {
    getProfileAction,
    ProfileSuccessAction,
    updateProfileAction
  }