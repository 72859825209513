import contratosConstantes from "../../constants/servicio-contratos/Contratos";

const initState = {
  payload: [],
  loading: false,
  updating: false,
  redirect: "",
};

const contratosReducer = (state = initState, action) => {
  const { type, payload } = action
  switch (type) {

    case contratosConstantes.GET_CONTRATOS:
      return {
        ...state,
        loading: true,
        updating: true,
        redirect: "/app/apps2/portal-contratos/contratos/list-contratos",
      };
    

    case contratosConstantes.GET_CONTRATOS_ID:
    case contratosConstantes.DELETE_CONTRATOS:
    case contratosConstantes.DELETE_FILES:
    case contratosConstantes.SHOW_LOADING:
    case contratosConstantes.ADD_CONTRATOS:
    case contratosConstantes.UPDATE_CONTRATOS:
      return {
        ...state,
        loading: true,
      };

    case contratosConstantes.POST_FILES_SUCCESS:
    case contratosConstantes.ADD_CONTRATOS_SUCCESS:
    case contratosConstantes.DELETE_CONTRATOS_SUCCESS:
    case contratosConstantes.UPDATE_CONTRATOS_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case contratosConstantes.GET_CONTRATOS_SUCCESS:
    case contratosConstantes.GET_CONTRATOS_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: payload,
        updating: false,
      };

    //? Reducer Agregar archivos
    case contratosConstantes.POST_FILES: {
      return {
        ...state,
        loading: true,
        updating: true,
      };
    }

    case contratosConstantes.DELETE_FILES_SUCCESS: {
      const { archivoUrl, _id } = payload
      const newPayload = state.payload.map( e => e._id == _id?
            {...e,
              fileViewerPath: e.fileViewerPath.filter( i => i != archivoUrl)
            } : e 
          )
      return {
        ...state,
        payload: [...newPayload],
        loading: false,
      };
    }
    default:
      return state;
  }
};

export default contratosReducer;
