import { put, call } from "redux-saga/effects";
import PresupuestosActions from "../../actions/servicio-presupuesto/Presupuesto";
import { message, Modal } from "antd";
import servicioPresupuesto from "axios/servicio-presupuesto/Presupuesto";

//? Listar Presupuestos
export function* listPresupuestos() {
  try {
    const listPresupuesto = yield call(
      servicioPresupuesto.getPresupuestosAxios
    );

    if (!listPresupuesto.error) {
      yield put(PresupuestosActions.successPresupuestoAction(listPresupuesto));
    }
  } catch (err) {
    yield message.error("Hubo un error al listar los presupuestos");
  }
}

//? Listar Centros
export function* listCentros() {
  try {
    const listCentros = yield call(servicioPresupuesto.getCentrosAxios);

    if (!listCentros.error) {
      yield put(PresupuestosActions.successCentrosAction(listCentros));
    }
  } catch (err) {
    yield message.error("Hubo un error al listar los centros");
  }
}

//? Send data Presupuesto
function* sendPresupuestoData({ payload }) {
  try {
    const newObject = {
      centros: payload.centros.map((value) => JSON.parse(value)),
      presupuestoActual: payload.presupuestoActual,
      presupuestoAnterior: payload.presupuestoAnterior,
    };

    const presupuestoData = yield call(
      servicioPresupuesto.sendPresupuestoDataAxios,
      newObject
    );

    if (!presupuestoData.status) {
      Modal.error({
        title: "Ocurrió un error!",
        content: "Favor contactar con el Dpto de Informática.",
      });
    } else {
      Modal.success({
        content: "¡Se ha procesado de forma exitosa!",
      });

      // Después de completar la primera saga, llamamos a la segunda si es que el
      //flag sac es true
      if (payload.sac) yield call(processSac, { payload }); // Llama a la segunda saga

    }

    yield put(PresupuestosActions.successPostPresupuestoDataAction());

  } catch (err) {
    message.error(err.message);
  }
}

//? Procesar SAC
function* processSac({ payload }) {
  try {

    yield call(
      servicioPresupuesto.procesarSacAxios,
      payload.presupuestoActual
    );

    yield put(PresupuestosActions.successPostProcesarSacAction());
  } catch (err) {
    message.error(err.message);
  }
}


export default {
  listPresupuestos,
  listCentros,
  sendPresupuestoData,
  processSac
};
