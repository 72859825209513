import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { AUTH_PREFIX_PATH,PASS_PREFIX_PATH } from 'configs/AppConfig'
 

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="page"/>}>
      <Switch>
        <Route path={`${AUTH_PREFIX_PATH}/login-2`} component={lazy(() => import(`./authentication/login-2`))} />
        <Route path={`${AUTH_PREFIX_PATH}/register`} component={lazy(() => import(`./authentication/register`))} />
        <Route path={`${AUTH_PREFIX_PATH}/forgot-password/:tokenUrl/:username/`} component={lazy(() => import(`../app-views/apps/servicio-autenticacion/user/user-forgot-pass`))} />
        <Route path={`${AUTH_PREFIX_PATH}/error-1`} component={lazy(() => import(`./errors/error-page-1`))} />
        <Redirect from={`${AUTH_PREFIX_PATH}`} to={`${AUTH_PREFIX_PATH}/login-2`} />
      </Switch>
    </Suspense>
  )
} 

export default AppViews;
