import { put, call } from "redux-saga/effects";

import {  
   successLiquidacionesAction, 
   SuccessSendLiquidacionAction, FailureSendLiquidacionAction,
   successGenerateLiquidacionesAction, failureGenerateLiquidacionesAction,
   successOneGenerateLiquidacionesAction, failureOneGenerateLiquidacionesAction, 
   initProgressBarAction, initFailureProgressBarAction, endProgressBarAction
} from '../../actions/servicio-liquidacion-digital/Liquidaciones'
import { message } from "antd";
import serviceLiquidaciones from "../../../axios/servicio-liquidacion-digital/Liquidacones";

//? Funcion para listar los Liquidaciones
export function* ListLiquidaciones({payload}) {
    try {
    
      const liquidaciones = yield call(serviceLiquidaciones.getLiquidacionesAxios, payload);

      if (liquidaciones.error) {
        message.error(liquidaciones.msg);
      } else {
        message.success(liquidaciones.msg);
        yield put(successLiquidacionesAction(liquidaciones));
      }
    } catch (err) {
      message.error(err.message);
    }
}

//? Funcion para Generar Liquidaciones
export function* GenerateLiquidaciones({payload}) {
    try {

      yield put(initProgressBarAction())

      const PDFsGenerados = yield call(serviceLiquidaciones.generatePDFAxios, payload )
   
      const { ano, mes, list } = payload

      const totalSolicitados = list.length
      
      const checkCreatedPDF =  yield call(serviceLiquidaciones.checkCreationInfo, { ano, mes, totalSolicitados } )

      if(!checkCreatedPDF.completed) yield put(failureGenerateLiquidacionesAction(PDFsGenerados))

      if ( checkCreatedPDF.error ) {
        
        message.error(checkCreatedPDF.msg);
        yield put(failureGenerateLiquidacionesAction(checkCreatedPDF));
        yield put(endProgressBarAction());

      } else {

        message.success(checkCreatedPDF.msg);
        yield put(successGenerateLiquidacionesAction(checkCreatedPDF));
        yield put(endProgressBarAction());
        
      }
    } catch (err) {
      message.error(err.message);
    }
}

//? Funcion para Enviar Liquidaciones
export function* SendLiquidaciones({payload}) {
    try {
      
      const { ano, mes, list } = payload

      const login = yield call(serviceLiquidaciones.loginToDocumenta)
     
      const { jwt } = login
     
      const liquidacion = yield call(serviceLiquidaciones.insertarNuevaLiquidacion, { login, payload })

      yield put(initProgressBarAction()) 
      
      const insertarComprobantes = yield call(serviceLiquidaciones.insertarComprobantes, {jwt, list, liquidacion, date:{ ano, mes }}) 

      if (insertarComprobantes.error) {

        message.error(insertarComprobantes.msg);
        yield put((initFailureProgressBarAction()));
        yield put(FailureSendLiquidacionAction(insertarComprobantes));
        yield put(endProgressBarAction());
      
      } else {
       
        message.success(insertarComprobantes.msg);
        yield put(SuccessSendLiquidacionAction(insertarComprobantes));
        yield put(endProgressBarAction());
      
      }
    } catch (err) {
      message.error(err.message);
    }
}


//? Funcion para Generar Liquidaciones
export function* GenerateOneLiquidacion( { payload } ) {
  try {

    const PDFGenerado = yield call(serviceLiquidaciones.generateOneEmployeePDFAxios, payload);

    if ( PDFGenerado.error ) {
      
      message.error(PDFGenerado.msg);
      
      yield put(failureOneGenerateLiquidacionesAction(PDFGenerado));

    } else {

      message.success(PDFGenerado.msg);

      yield put(successOneGenerateLiquidacionesAction(PDFGenerado));
    }
  } catch (err) {
    message.error(err.message);
  }
}


export default {
    ListLiquidaciones,
    GenerateLiquidaciones,
    SendLiquidaciones,
    GenerateOneLiquidacion
}