import  movimientosConstants from "../../constants/servicio-gestion-vacaciones/Movimientos";

import { CONSULTA_USER_GET, USER_SUCCESS } from "../../constants/servicio-autenticacion/Users";

const initState = {
  payload: [],
  payloadUser: [],
  detalle: [],
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  creatingMov: false,
};

const movimientosReducer = (state = initState, action) => {
  const { type, payload } = action
  switch (type) {
    case movimientosConstants.HISTORIAL_SUCCESS: {
      return {
        ...state,
        payload: payload,
        loading: false,
      };
    }

    case movimientosConstants.CONSULTA_HISTORIAL_CURRENT_USER_SUCCESS: {
      return {
        ...state,
        payload: payload,
        loading: false,
      };
    }
    case movimientosConstants.HISTORIAL_SUCCESS_ONE: {
      return {
        ...state,
        detalle: payload,
        loading: false,
      };
    }

    case movimientosConstants.CONSULTA_HISTORIAL_CURRENT_USER: {
      return {
        ...state,
        loading: true,
      };
    }

    case movimientosConstants.CONSULTA_HISTORIAL_ONE: {
      return {
        ...state,
        loading: true,
      };
    }
    case movimientosConstants.CONSULTA_HISTORIAL: {
      return {
        ...state,
        loading: true,
        message: "",
      };
    }
    case movimientosConstants.CONSULTA_USER_GET: {
      return {
        ...state,
        loading: true,
        message: "",
      };
    }
    case movimientosConstants.GENERAR_MOVIMIENTO_SUCCESS: {
      return {
        ...state,
        creatingMov: false,
      };
    }
    case movimientosConstants.GENERAR_MOVIMIENTO: {
      return {
        ...state,
        creatingMov: true,
      };
    }
    case movimientosConstants.USER_SUCCESS: {
      return {
        ...state,
        payloadUser: payload,
        loading: true,
      };
    }
    case movimientosConstants.HISTORIAL_CONSULTA_AGREGADO: {
      return {
        ...state,
        loading: true,
      };
    }
    case movimientosConstants.HISTORIAL_CONSULTA_AGREGADO_SUCCESS: {
      return {
        ...state,
        loading: false,
        payload: payload,
      };
    }



    //? Reducer Eliminar las Movimientos M
    case movimientosConstants.DELETE_M_MOVIMIENTOS: {
      return {
        ...state,
      };
    }

    case movimientosConstants.DELETE_M_MOVIMIENTOS_SUCCESS: {
      return {
        ...state,
      };
    }

    default:
      return state;
  }
};
export default movimientosReducer;
