import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom';
import BillForm from "./viewBillForm";
import withRole from '../HOC/withRoles';

//? Definicion de reglas de roles
const restrictedBillForm = withRole(true, ['colaborador','lider','delegado','admin'], BillForm)

const Comprobantes = props => {
	const { match } = props
	return (
		<Switch>
			<Redirect exact from={`${match.url}`} to={`${match.url}/list-bill`} />
			<Route path={`${match.url}/list-bill`} component={restrictedBillForm} />
		</Switch>
	)
}

export default Comprobantes

