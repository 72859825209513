//? Se importan las constantes
import usersConstants from "../../constants/servicio-gestion-vacaciones/Users";

//? Accion para obtener todos los usuarios de vacaciones
export const consultaUsersAction = () => {
  return {
    type: usersConstants.CONSULTA_USERS,
  };
};

export const consultaSolicitudesUsersActionSuccess = (payload) => {
  return {
    type: usersConstants.CONSULTA_USERS_SUCCESS,
    payload: payload,
  };
};

//? Accion para obtener solo un usuario => Servicio de autenticacion
export const consultaOneUser = (payload) => {
  return {
    type: usersConstants.USER_ONLY_GET_VACACIONES,
    payload: payload,
  };
};


//? Accion para obtener solo un usuario => Servicio de autenticacion
export const consultaOneUserSuccess = (payload) => {
  return {
    type: usersConstants.USER_SUCCESS_ONLY_VACACACIONES,
    payload: payload,
  };
};

//? Lista todos los usuarios de vacaciones
export const listadoAllUserVacacionAll = () => {
  return {
    type: usersConstants.GET_FULL_USERS
  };
};

//? Lista todos los usuarios de vacaciones
export const listadoAllUserVacacionAllSuccess = (data) => {
  return {
    type: usersConstants.GET_FULL_USERS_SUCCESS,
    payload: data
  };
};

//? Accion para listar usuarios de dominio
export const listadoOneUserVacacion = (dataUser) => {
  return {
    type: usersConstants.GET_USER,
    payload: dataUser,
  };
};

//? Accion para crear usuarios de vacaciones
export const creacionUserVacacion = (dataUser) => {
  return {
    type: usersConstants.CREATE_USER,
    payload: dataUser,
  };
};

//? Accion para crear usuarios de vacaciones
export const creacionUserVacacionSuccess = () => {
  return {
    type: usersConstants.CREATE_USER_SUCCESS
  };
};


//? Accion para editar usuarios de vacaciones
export const editUserVacacion = (dataUser) => {
  return {
    type: usersConstants.EDIT_USER,
    payload: dataUser,
  };
};


//? Accion para editar usuarios de vacaciones
export const editUserVacacionSuccess = () => {
  return {
    type: usersConstants.EDIT_USER_SUCCESS
  };
};

//? Lista por id de usuarios de vacaciones -> obtiene el rol de cada usuario
export const listadoUserVacacionActionSuccess = (dataUser) => {
  return {
    type: usersConstants.GET_USER_SUCCESS,
    payload: dataUser,
  };
};

//? Accion parar eliminar usuarios de vacaciones 
export const deleteUserVacaionAction = (dataUser) => {
  return {
    type: usersConstants.DELETE_USER_VACACACION,
    payload: dataUser,
  };
};


//? Accion parar eliminar usuarios de vacaciones 
export const deleteUserVacaionActionSuccess = () => {
  return {
    type: usersConstants.DELETE_USER_VACACACION_SUCCESS
  };
}

export default {
  consultaUsersAction, consultaSolicitudesUsersActionSuccess,
  consultaOneUser, consultaOneUserSuccess,
  listadoAllUserVacacionAll, listadoAllUserVacacionAllSuccess,
  listadoOneUserVacacion,
  creacionUserVacacion, creacionUserVacacionSuccess,
  editUserVacacion, editUserVacacionSuccess,
  listadoUserVacacionActionSuccess,
  deleteUserVacaionAction, deleteUserVacaionActionSuccess
}