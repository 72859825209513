//? Se importan las constantes
import LiquidacionesActionTypes from "../../constants/servicio-liquidacion-digital/Liquidaciones";

//? Accion de Listar liquidaciones
export const listLiquidacionesAction = (data) => {
  return {
    type: LiquidacionesActionTypes.GET_LIQUIDACIONES,
    payload: data
  };
};

export const successLiquidacionesAction = data => {
  return {
    type: LiquidacionesActionTypes.GET_LIQUIDACIONES_SUCCESS,
    payload: data,
  };
};

//? Accion de Agregar Liquidaciones
export const sendLiquidacionAction = (data) => {
  return {
    type: LiquidacionesActionTypes.SEND_LIQUIDACIONES,
    payload: data,
  };
};

export const SuccessSendLiquidacionAction = (data) => {
    return {
      type: LiquidacionesActionTypes.SEND_LIQUIDACIONES_SUCCESS,
      payload: data,
    };
  };

  export const FailureSendLiquidacionAction = (data) => {
    return {
      type: LiquidacionesActionTypes.SEND_LIQUIDACIONES_FAILURE,
      payload: data
    };
  };

  
//? Accion de Actualizar Liquidaciones 
export const generateLiquidacionesAction = (data) => {
  return {
    type: LiquidacionesActionTypes.GENERATE_LIQUIDACIONES,
    payload: data,
  };
};


export const successGenerateLiquidacionesAction = (data) => {
    return {
      type: LiquidacionesActionTypes.GENERATE_LIQUIDACIONES_SUCCESS,
      payload: data,
    };
  };

export const failureGenerateLiquidacionesAction = (data) => {
  return {
    type: LiquidacionesActionTypes.GENERATE_LIQUIDACIONES_FAILURE,
    payload: data,
  };
};


//? Accion de Generar Una Liquidaciones 
export const generateOneLiquidacionesAction = (data) => {
  console.log('Action', data)
  return {
    type: LiquidacionesActionTypes.GENERATE_ONE_LIQUIDACIONES,
    payload: data,
  };
};


export const successOneGenerateLiquidacionesAction = (data) => {
    return {
      type: LiquidacionesActionTypes.GENERATE_ONE_LIQUIDACIONES_SUCCESS,
      payload: data,
    };
  };

export const failureOneGenerateLiquidacionesAction = (data) => {
  return {
    type: LiquidacionesActionTypes.GENERATE_ONE_LIQUIDACIONES_FAILURE,
    payload: data,
  };
};


export const initProgressBarAction = () => {
  return {
    type: LiquidacionesActionTypes.INIT_PROGRESS_BAR
  }
}

export const initFailureProgressBarAction = () => {
  return {
    type: LiquidacionesActionTypes.INIT_FAILURE_PROGRESS_BAR
  }
}

export const updateProgressBarAction = (data) => {
  console.log('updateProgressBarAction: ', data )
  return {
    type: LiquidacionesActionTypes.UPDATE_PROGRESS_BAR,
    payload: data,
  }
}

export const endProgressBarAction = () => {
  return {
    type: LiquidacionesActionTypes.END_PROGRESS_BAR
  }
}


export const registerComprobanteSended = (data) => {
  return {
    type: LiquidacionesActionTypes.REGISTER_COMPROBANTE_SENDED,
    payload: data,
  };
};

