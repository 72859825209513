import React from "react";

export default function Footer() {
  return (
    <footer className="footer text-center text-lg-start">
      <div style={{ fontSize: "1rem", marginTop: "5px" }}>
        <span>
          Desarrollado con ❤️ por tus compañeros del Depto. BI y Desarrollo -
          Tecnoedil S.A.
        </span>
      </div>
    </footer>
  );
}
