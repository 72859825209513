import { all, takeEvery, fork } from "redux-saga/effects";
//? Se importan las constantes
import userVacacionesConstants from "../../constants/servicio-gestion-vacaciones/Users";

import usersVacacionesFunctions from './functions/Users.functions'

//? Lista todos los usuarios -> Endpoint Servicio de autenticacion
export function* listSolicitudesUsersSaga() {
  yield takeEvery(userVacacionesConstants.CONSULTA_USERS, usersVacacionesFunctions.listSolicitudesUsers );
}

//? Lista por id de usuarios de vacaciones -> obtiene el rol de cada usuario
export function* getOneUsuarioVacacionesSaga() {
  yield takeEvery(userVacacionesConstants.GET_USER, usersVacacionesFunctions.GetOneUsuarioVacaciones);
}

//? Obtiene datos de un usuario por id -> Endpoint de autenticacion
export function* listOneUserSaga() {
  yield takeEvery(userVacacionesConstants.USER_ONLY_GET_VACACIONES, usersVacacionesFunctions.listOneUser );
}

//? Lista todos los usuarios de vacaciones
export function* listAllUserSaga() {
  yield takeEvery(userVacacionesConstants.GET_FULL_USERS, usersVacacionesFunctions.listAllUser );
}

//? Crea usuarios de vacaciones
export function* createUserVacacionSaga() {
  yield takeEvery(userVacacionesConstants.CREATE_USER, usersVacacionesFunctions.createUserVacacion );
}

//? Edita usuarios de vacaciones
export function* editUserVacacionSaga() {
  yield takeEvery(userVacacionesConstants.EDIT_USER, usersVacacionesFunctions.editUserVacacion);
}

//? Elimina usuarios de vacaciones
export function* deleteUserVacacionSaga() {
  yield takeEvery(userVacacionesConstants.DELETE_USER_VACACACION, usersVacacionesFunctions.deleteUserVacacion );
}

export default function* rootListUsersSaga() {
  yield all([
    fork(listSolicitudesUsersSaga),
    fork(getOneUsuarioVacacionesSaga),
    fork(listOneUserSaga),
    fork(listAllUserSaga),
    fork(createUserVacacionSaga),
    fork(editUserVacacionSaga),
    fork(deleteUserVacacionSaga)
  ]);
}
