import axios from "axios";
axios.defaults.headers.common["Authorization"] =
  localStorage.getItem("auth_token");

const ServicioVistaFactura = {};
const { REACT_APP_API_URL } = process.env;

//? Listar Facturas.
ServicioVistaFactura.getFacturasAxios = async () => {
  return await axios({
    method: "get",
    url: `${REACT_APP_API_URL}/factura/api/sync/queryFepy`,
    responseType: "json",
  })
    .then((response) => response.data)
    .catch((err) => err);
};

//? Listar todos los PDF's.
ServicioVistaFactura.getFilePdf = async () => {
  return await axios({
    method: "get",
    url: `${REACT_APP_API_URL}/factura/api/files`,
    responseType: "json",
  })
    .then((response) => response.data)
    .catch((err) => err);
};


//? Listar PDF's por parametros.
ServicioVistaFactura.getFileByParams = async (name) => {
  return await axios({
    method: "get",
    url: `${REACT_APP_API_URL}/factura/api/files/${name}`,
    responseType: "blob",
  })
    .then((response) => response.data)
    .catch((err) => err);
};

export default ServicioVistaFactura;
