import axios from "axios";
axios.defaults.headers.common["Authorization"] =
  localStorage.getItem("auth_token");

const ServicioPresupuesto = {};
const { REACT_APP_API_URL } = process.env;

//? Listar Presupuestos.
ServicioPresupuesto.getPresupuestosAxios = async () => {
  return await axios({
    method: "get",
    url: `${REACT_APP_API_URL}/presupuesto/api/presupuestos`,
    responseType: "json",
  })
    .then((response) => response.data)
    .catch((err) => err);
};

//? Listar Centros.
ServicioPresupuesto.getCentrosAxios = async () => {
  return await axios({
    method: "get",
    url: `${REACT_APP_API_URL}/presupuesto/api/centros`,
    responseType: "json",
  })
    .then((response) => response.data)
    .catch((err) => err);
};

//? Listar Centros.
ServicioPresupuesto.sendPresupuestoDataAxios = async (data) => {
  return await axios({
    method: "post",
    url: `${REACT_APP_API_URL}/presupuesto/api/procesar-presupuesto`,
    responseType: 'json',
    data: {
      dataPresupuesto: data
    }
  })
    .then((response) => response)
    .catch((err) => err);
};

//? Procesar SAC.
ServicioPresupuesto.procesarSacAxios = async (data) => {
  return await axios({
      method: "post",
      url: `${REACT_APP_API_URL}/presupuesto/api/procesar-sac`,
      responseType: 'json',
      data: {
          escenarioActual: data
      }
    })
      .then((response) => response)
      .catch((err) => err);
};


export default ServicioPresupuesto;
