import usersConstants from "../../constants/servicio-autenticacion/Users";
import modulosConstants from "redux/constants/servicio-autenticacion/Modulos";
import rolesConstants from "redux/constants/servicio-autenticacion/Roles";
import authConstants from "redux/constants/servicio-autenticacion/Auth";


export const createTokenPassUserAction = (data) => {
  return {
    type: usersConstants.CREATE_USER_TOKEN_PASS,
    payload: data,
  };
}

export const createTokenPassSuccessUserAction = (data) => {
  return {
    type: usersConstants.CREATE_USER_TOKEN_PASS_SUCCESS,
    payload: data,
  };
}

export const updateDatosUserAction = (data) => {
  return {
    type: usersConstants.UPDATE_USER_DATA,
    payload: data,
  };
};

export const updatePassUserAction = (data) => {
  return {
    type: usersConstants.UPDATE_USER_PASS,
    payload: data,
  };
};

//! Crea los modulos al usuario
export const createModuleUserAction = (data) => {
  return {
    type: usersConstants.CREATE_MODULE_USER,
    payload: data,
  };
};

export const createModuleUserSuccessAction = () => {
  return {
    type: usersConstants.CREATE_MODULE_USER_SUCCESS,
  };
};

export const listUserInitAction = (data) => {
  return {
    type: usersConstants.USER_SUCCESS_DATA,
    payload: data,
  };
};
export const listUserDataAction = (data) => {
  return {
    type: usersConstants.USER_SUCCESS_ONLY_DATA,
    payload: data,
  };
};
export const createUserAction = (data) => {
  return {
    type: usersConstants.CREATE_USER_EXITOSO,
    payload: data,
  };
};
export const deleteUserAction = (data) => {
  return {
    type: usersConstants.DELETE_USER_POST,
    payload: data,
  };
};
export const updPassAction = (data) => {
  return {
    type: usersConstants.UPDATE_USER_PASS,
    payload: data,
  };
};
export const listUserAction = () => {
  return {
    type: usersConstants.CONSULTA_USER_GET,
  };
};
export const listEmailAction = (data) => {
  return {
    type: usersConstants.USER_EMAIL_SUCCESS_ONLY,
    payload: data
  };
};


export const listRolesAction = () => {
  return {
    type: rolesConstants.CONSULTA_ROLES_GET,
  };
};


//! Obtiene los modulos del usuario
export const getModuleUserAction = (data) => {
  return {
    type: usersConstants.GET_MODULE_USER,
    payload: data,
  };
};

export const getModuleUserSuccessAction = (data) => {
  return {
    type: usersConstants.GET_MODULE_USER_SUCCESS,
    payload: data
  }
}

export const listOneUserSuccessAction = (data) => {
  return {
    type: usersConstants.USER_SUCCESS_ONLY,
    payload: data,
  };
};

//! Eliminar los modulos del usuario
export const deleteModuleUserAction = (data) => {
  return {
    type: usersConstants.DELETE_MODULE_USER,
    payload: data,
  };
};

export const deleteModuleUserSuccessAction = () => {
  return {
    type: usersConstants.DELETE_MODULE_USER_SUCCESS,
  };
};

export const userSuccessAction = (data) => {
  return {
    type: usersConstants.USER_SUCCESS,
    payload: data,
  };
};
export const moduloSuccessAction = (data) => {
  return {
    type: modulosConstants.MODULOS_SUCCESS,
    payload: data,
  };
};
export const listModulosAction = () => {
  return {
    type: modulosConstants.CONSULTA_MODULOS_GET,
  };
};
export const updateUserAction = (data) => {
  return {
    type: usersConstants.UPDATE_USER_POST,
    payload: data,
  };
};

export const showLoadingAction = () => {
  return {
    type: authConstants.SHOW_LOADING,
  };
};

export const showAuthMessageAction = (message) => {
  return {
    type: authConstants.SHOW_AUTH_MESSAGE,
    payload: message,
  };
};

export const getOnlyOneUser = (data) => {
  return {
    type: usersConstants.GET_ONLY_ONE_USER,
    payload: data,
  };
};

export const getOnlyOneUserSuccess = (data) => {
  return {
    type: usersConstants.GET_ONLY_ONE_USER_SUCCESS,
    payload: data,
  };
};


export const getOneUserFullData = (data) => {
  return {
    type: usersConstants.GET_ONE_USER_DATA,
    payload: data
  }
}

export const getOneUserFullDataSuccess = (data) => {
  return {
    type: usersConstants.GET_ONE_USER_DATA_SUCCESS,
    payload: data
  }
}



export default {
  createTokenPassUserAction,createTokenPassSuccessUserAction,
  updateDatosUserAction,  updatePassUserAction,
  createModuleUserAction, createModuleUserSuccessAction,listUserInitAction,
  listUserDataAction, createUserAction,
  deleteUserAction, updPassAction,
  listUserAction, listEmailAction,
  listRolesAction, getModuleUserAction,
  deleteModuleUserAction, userSuccessAction,
  moduloSuccessAction, listModulosAction,
  updateUserAction, showLoadingAction,
  showAuthMessageAction, getOnlyOneUser,
  getOnlyOneUserSuccess, getOneUserFullData,
  getOneUserFullDataSuccess, getModuleUserSuccessAction,
  deleteModuleUserSuccessAction
}