import {
  AppstoreOutlined,
  FileTextOutlined,
  MessageOutlined,
  ProfileOutlined,
  HomeOutlined,
  UserAddOutlined,
  ApartmentOutlined,
  GlobalOutlined,
  DashboardOutlined,
  SolutionOutlined,
  FileProtectOutlined,
  TeamOutlined,
  InboxOutlined
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const defaultNavTree = [
  {
    key: "dashboards-inicio",
    path: `${APP_PREFIX_PATH}/dashboards/inicio`,
    title: "sidenav.principal.inicio",
    icon: HomeOutlined,
    roles: ["*"],
    breadcrumb: false,
    submenu: [],
  },
];

const portalContratosNavTree = [
  {
    key: "apps2",
    path: `${APP_PREFIX_PATH}/apps2`,
    title: "sidenav.apps.portalcontratos",
    icon: AppstoreOutlined,
    roles: ["*"],
    breadcrumb: false,
    submenu: [
      //? Contratos
      {
        key: "apps-portalcontratos",
        path: `${APP_PREFIX_PATH}/apps2/portal-contratos`,
        title:   "sidenav.apps.portalcontratos.cargadatos",
        icon: InboxOutlined,
        roles: ["*"],
        breadcrumb: false,
        submenu: [
          {
            key: "apps-portalcontratos-contratos",
            path: `${APP_PREFIX_PATH}/apps2/portal-contratos/contratos`,
            title: "sidenav.apps.portalcontratos.cargadatos.contratos",
            icon: FileProtectOutlined,
            roles: ["*"],
            breadcrumb: true,
            submenu: [
              {
                key: "apps-portalcontratos-contratos-list-contratos",
                path: `${APP_PREFIX_PATH}/apps2/portal-contratos/contratos/list-contratos`,
                title: "sidenav.apps.portalcontratos.cargadatos.contratos",
                icon: FileProtectOutlined,
                roles: ["*"],
                breadcrumb: true,
                submenu: [],
              },
            ],
          },

          //? Certificados
          {
            key: "apps-portalcontratos-certificados",
            path: `${APP_PREFIX_PATH}/apps2/portal-contratos/certificados`,
            title: "sidenav.apps.portalcontratos.cargadatos.certificados",
            icon: ProfileOutlined,
            roles: ["*"],
            breadcrumb: true,
            submenu: [
              {
                key: "apps-portalcontratos-certificados-list-certificados`",
                path: `${APP_PREFIX_PATH}/apps2/portal-contratos/certificados/list-certificados`,
                title: "sidenav.apps.portalcontratos.cargadatos.certificados",
                icon: ProfileOutlined,
                roles: ["*"],
                breadcrumb: true,
                submenu: [],
              },
            ],
          },
        ],
      },

      //? Administración
      {
        key: "apps-portalcontratos-administracion",
        path: `${APP_PREFIX_PATH}/apps2/portal-contratos/usuarios`,
        title: "sidenav.apps.portalcontratos.administracion",
        icon: UserAddOutlined,
        roles: ["*"],
        breadcrumb: false,
        submenu: [
          //? Usuarios
          {
            key: "apps-portalcontratos-userroles-listarUser",
            path: `${APP_PREFIX_PATH}/apps2/portal-contratos/usuarios/listarUser`,
            title: "sidenav.apps.portalcontratos.userroles.listarUser",
            icon: TeamOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
    ],
  },
];

const portalVacacionesNavTree = [
  {
    key: "apps1",
    path: `${APP_PREFIX_PATH}/apps1`,
    title: "sidenav.apps1",
    icon: AppstoreOutlined,
    roles: ["*"],
    breadcrumb: false,
    submenu: [
      {
        key: "apps-portalvacaciones-general",
        path: `${APP_PREFIX_PATH}/apps1/portal-vacaciones`,
        title: "sidenav.apps.portalvacaciones.general",
        icon: DashboardOutlined,
        roles: ["*"],
        breadcrumb: true,
        submenu: [
          {
            key: "apps-portalvacaciones-dashboard",
            path: `${APP_PREFIX_PATH}/apps1/portal-vacaciones/principal`,
            title: "sidenav.apps.portalvacaciones.dashboard",
            icon: "",
            roles: ["*"],
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "apps-portalvacaciones-historial",
        path: `${APP_PREFIX_PATH}/apps1/portal-vacaciones/movimientos`,
        title: "sidenav.apps.portalvacaciones.historial",
        icon: GlobalOutlined,
        roles: ["*"],
        breadcrumb: false,
        submenu: [
          {
            key: "apps-portaladmin-movimientos-historialListado",
            path: `${APP_PREFIX_PATH}/apps1/portal-vacaciones/movimientos/historial-listado`,
            title: "sidenav.apps.portalvacaciones.general.historial",
            icon: "",
            roles: ["*"],
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "apps-portalvacaciones-comprobantes",
        path: `${APP_PREFIX_PATH}/apps1/portal-vacaciones/comprobantes`,
        title: "sidenav.apps.portalvacaciones.comprobantes",
        icon: FileTextOutlined,
        roles: ["*"],
        breadcrumb: false,
        submenu: [
          {
            key: "apps-portalvacaciones-comprobantes-list",
            path: `${APP_PREFIX_PATH}/apps1/portal-vacaciones/comprobantes/list-comprobantes`,
            title: "sidenav.apps.portalvacaciones.comprobantes.list",
            icon: "",
            roles: ["*"],
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "apps-portalvacaciones-solicitudes",
        path: `${APP_PREFIX_PATH}/apps1/portal-vacaciones/solicitudes`,
        title: "sidenav.apps.portalvacaciones.solicitudes",
        icon: MessageOutlined,
        roles: ["*"],
        breadcrumb: false,
        submenu: [
          {
            key: "apps-portalvacaciones-solicitudes-list-me",
            path: `${APP_PREFIX_PATH}/apps1/portal-vacaciones/solicitudes/list-solicitudes-propias`,
            title: "sidenav.apps.portalvacaciones.solicitudes.propias",
            icon: "",
            roles: ["*"],
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "apps-portalvacaciones-solicitudes-list-other",
            path: `${APP_PREFIX_PATH}/apps1/portal-vacaciones/solicitudes/list-solicitudes-terceros`,
            title: "sidenav.apps.portalvacaciones.solicitudes.terceros",
            icon: "",
            roles: ["admin", "lider"],
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "apps-portalvacaciones-userroles",
        path: `${APP_PREFIX_PATH}/apps1/portal-vacaciones/usuarios`,
        title: "sidenav.apps.portalvacaciones.userroles",
        icon: SolutionOutlined,
        roles: ["admin"],
        breadcrumb: true,
        submenu: [
          {
            key: "apps-portalvacaciones-userroles-listarUser",
            path: `${APP_PREFIX_PATH}/apps1/portal-vacaciones/usuarios/listarUser`,
            title: "sidenav.apps.portalvacaciones.userroles.listarUser",
            icon: "",
            roles: ["admin"],
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
    ],
  },
];

const portaladminNavTree = [
  {
    key: "apps",
    path: `${APP_PREFIX_PATH}/apps`,
    title: "sidenav.apps",
    icon: HomeOutlined,
    roles: ["*"],
    breadcrumb: false,
    submenu: [
      {
        key: "apps-portaladmin-principal",
        path: `${APP_PREFIX_PATH}/apps/portal-admin`,
        title: "sidenav.apps.portaladmin.general",
        icon: DashboardOutlined,
        roles: ["*"],
        breadcrumb: true,
        submenu: [
          {
            key: "apps-portaladmin-dashboard",
            path: `${APP_PREFIX_PATH}/apps/portal-admin/principal`,
            title: "sidenav.apps.portaladmin.dashboard",
            icon: "",
            roles: ["*"],
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "apps-portaladmin-modulos",
        path: `${APP_PREFIX_PATH}/apps/portal-admin/modulos`,
        title: "sidenav.apps.portaladmin.modulos",
        icon: ApartmentOutlined,
        roles: ["*"],
        breadcrumb: false,
        submenu: [
          {
            key: "apps-portaladmin-modulos-modulosList",
            path: `${APP_PREFIX_PATH}/apps/portal-admin/modulos/modulos-list`,
            title: "sidenav.apps.portaladmin.modulos.modulosList",
            icon: "",
            roles: ["*"],
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "apps-portaladmin-modulos-addmodulos",
            path: `${APP_PREFIX_PATH}/apps/portal-admin/modulos/add-modulos`,
            title: "sidenav.apps.portaladmin.modulos.addmodulos",
            icon: "",
            roles: ["*"],
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "apps-portaladmin-user",
        path: `${APP_PREFIX_PATH}/apps/portal-admin/user`,
        title: "sidenav.apps.portaladmin.user",
        icon: UserAddOutlined,
        roles: ["*"],
        breadcrumb: false,
        submenu: [
          {
            key: "apps-portaladmin-user-UserList",
            path: `${APP_PREFIX_PATH}/apps/portal-admin/user/user-list`,
            title: "sidenav.apps.portaladmin.user.UserList",
            icon: "",
            roles: ["*"],
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
    ],
  },
];

const navigationConfig = [
  ...defaultNavTree,
  ...portaladminNavTree,
  ...portalVacacionesNavTree,
  ...portalContratosNavTree,
];

export default navigationConfig;
