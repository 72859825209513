import axios from "axios";
axios.defaults.headers.common['Authorization'] = localStorage.getItem('auth_token');

const { REACT_APP_API_URL } = process.env;
const serviceContratos = {};
const resolve = (data) => {
  let v = data === "si" ? true : false;
  return v;
};

//? Listar contratos
serviceContratos.listContratos = async () => {
  return await axios({
    method: "get",
    url: `${REACT_APP_API_URL}/contratos/api/contratos/list/`,
    responseType: "json",
  })
    .then((response) => response.data)
    .catch((err) => err);
};

//? Listar solo un contrato
serviceContratos.listContratoId = async (data) => {
  return await axios({
    method: "get",
    url: `${REACT_APP_API_URL}/contratos/api/contratos/list/${data}`,
    responseType: "json",
  }).then((response) => response.data);
};

//? Crear Contratos
serviceContratos.createdContratos = async (data) => {

  const {
    unidadDeNegocio,
    nroContrato,
    descripcion,
    entidad,
    retencionCaucional,
    retencionRenta,
    retencionIva,
    cliente,
    diasVencimiento,
    activo,
    gastoLey,
    factorGastoLey
  } = data;

  return await axios({
    method: "post",
    data: {
      unidadDeNegocio,
      nroContrato,
      descripcion,
      entidad,
      retencionCaucional,
      retencionRenta,
      retencionIva,
      cliente,
      diasVencimiento,
      activo,
      gastoLey,
      factorGastoLey
    },
    url: `${REACT_APP_API_URL}/contratos/api/contratos/created/`,
  }).then((response) => response.data);
};

//? Actualizar Contrato
serviceContratos.updateContratos = async (data) => {
  const { _id } = data;

  return await axios({
    method: "post",
    data,
    url: `${REACT_APP_API_URL}/contratos/api/contratos/edit/${_id}`,
    // url: `http://localhost:4003/api/contratos/edit/${_id}`
  }).then((response) => response.data);
};

serviceContratos.deleteContratos = async (data) => {
  return await axios({
    method: "delete",
    url: `${REACT_APP_API_URL}/contratos/api/contratos/delete/${data}`,
  }).then((response) => response.data);
};

//? Agregar Archivos al contrato.
serviceContratos.insertFilesAxios = async (param) => {
  const { _id, fileViewerPath } = param;
  const formData = new FormData();
  formData.append("fileViewerPath", fileViewerPath);
  return axios({
    method: "post",
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
    url: `${REACT_APP_API_URL}/contratos/api/contratos/insertfiles/${_id}`,
  }).then((response) => response.data);
};

//? Eliminar Archivos del contrato.
serviceContratos.deleteFilesAxios = async (data) => {
  const { _id, archivoUrl } = data;
  return axios({
    method: "delete",
    url: `${REACT_APP_API_URL}/contratos/api/contratos/deletefiles`,
    data: { _id, archivoUrl },
  }).then((response) => response);
};

export default serviceContratos;