//? Se importan las constantes
import facturaConstants from "../../constants/servicio-vista-factura/VistaFactura";

//? Accion de Listar Facturas
export const listFacturasAction = () => {
  return {
    type: facturaConstants.GET_FACTURAS,
  };
};

export const successFacturasAction = (data) => {
  return {
    type: facturaConstants.GET_FACTURAS_SUCCESS,
    payload: data,
  };
};


//? Accion de Listar todos los pdf
export const listFileAction = () => {
  return {
    type: facturaConstants.GET_FILE,
  };
};

export const successFileAction = (data) => {
  return {
    type: facturaConstants.GET_FILE_SUCCESS,
    payload: data,
  };
};


//? Accion de Listar pdf por parametros
export const listOneFileAction = (data) => {
  return {
    type: facturaConstants.GET_ONE_FILE,
    payload: data,

  };
};

export const successOneFileAction = (data) => {
  return {
    type: facturaConstants.GET_ONE_FILE_SUCCESS,
    payload: data,
  };
};

export default {
  listFacturasAction,
  successFacturasAction,
  listFileAction,
  successFileAction,
  listOneFileAction,
  successOneFileAction
};
