import axios from "axios";
axios.defaults.headers.common['Authorization'] = localStorage.getItem('auth_token') ;

const serviceRole = {};

const { REACT_APP_API_URL } = process.env;
serviceRole.listRoleAxios = async () => {
  return await axios({
    method: "get",
    url: `${REACT_APP_API_URL}/admin/api/roles/register/`,
    responseType: "json",
  })
    .then((response) => response.data.datos)
    .catch((err) => err);
};

serviceRole.addRoleAxios = async (data) => {
  const { name, permisos } = data;
  return await axios({
    method: "post",
    url: `${REACT_APP_API_URL}/admin/api/roles/register/`,
    data: {
      name: name,
      permisos: permisos,
    },
  }).then((response) => response.data);
};

serviceRole.deleteRole = async (data) => {
  return await axios({
    method: "delete",
    url: `${REACT_APP_API_URL}/admin/api/roles/register/del/${data}`,
    data: {
      _id: data,
    },
  }).then((response) => response);
};

serviceRole.updateRole = async (data) => {
  const { _id, name, permisos } = data;

  return await axios({
    method: "post",
    url: `${REACT_APP_API_URL}/admin/api/roles/register/edit/${_id}`,
    data: {
      name: name,
      permisos: permisos,
    },
  }).then((response) => response);
};

export default serviceRole;
