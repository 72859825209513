import roleConstants from '../../constants/servicio-autenticacion/Roles';

export const listRolesAction = () => {
    return {
      type: roleConstants.CONSULTA_ROLES_GET,
    }
  };

  export const rolesExitosAction = (data) => {
    return {
      type: roleConstants.CONSULTA_ROLES_EXITOSO,
      payload: data
    }
  };
  export const addRolesAction = (data) => {
   
    return {
      type: roleConstants.ADD_ROLES_POST,
      payload: data
    };
  };
 
  export const rolesSuccessAction = (data) => {
    return {
      type: roleConstants.ROLES_SUCCESS,
      payload: data
    };
  };
 
  export const updateRolesAction = (data) => {

    return {
      type: roleConstants.UPDATE_ROLES_POST,
      payload: data
    };
  };
 
  export const deleteRolesAction = (data) => {

    return {
      type: roleConstants.DELETE_ROLES_POST,
      payload: data
    };
  };
 
  export const showLoadingAction = () => {
    return {
      type: roleConstants.SHOW_LOADING,
    };
  };

  export const showAuthMessageAction = (message) => {
    return {
      type: roleConstants.SHOW_AUTH_MESSAGE,
      payload: message
    };
  };
  
  export default {
    listRolesAction,
    rolesExitosAction,
    addRolesAction,
    rolesSuccessAction,
    updateRolesAction,
    deleteRolesAction,
    showLoadingAction,
    showAuthMessageAction
  }