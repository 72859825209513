import { all, takeEvery, fork } from "redux-saga/effects";
import {
  GET_CENTROS,
  GET_PRESUPUESTOS,
  POST_PRESUPUESTO_DATA,
  POST_PROCESAR_SAC
} from "../../constants/servicio-presupuesto/presupuesto";
import presupuestofunctions from "./Presupuesto.functions";

//? Listar Reportes de contratos
export function* listpresupuestosSagas() {
  yield takeEvery(GET_PRESUPUESTOS, presupuestofunctions.listPresupuestos);
}

export function* listCentrosSagas() {
  yield takeEvery(GET_CENTROS, presupuestofunctions.listCentros);
}

export function* sendDataPresupuestoSagas() {
  yield takeEvery(
    POST_PRESUPUESTO_DATA,
    presupuestofunctions.sendPresupuestoData
  );
}

export function* processSacSagas() {
  yield takeEvery(
    POST_PROCESAR_SAC,
    presupuestofunctions.processSac
  );
}
export default function* rootSagaReportes() {
  yield all([
    fork(listpresupuestosSagas),
    fork(listCentrosSagas),
    fork(sendDataPresupuestoSagas),
    // fork(processSacSagas)
  ]);
}
