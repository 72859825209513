import utils from "utils";
import { FilePdfOutlined, CloseOutlined } from "@ant-design/icons";

export const tableColumns = (handleDocumentClick, data) =>
  [
    {
      title: "ID",
      dataIndex: "_id",
      align: "center",
      sorter: (a, b) => utils.antdTableSorter(a, b, "_id"),
      hidden: true,
    },
    {
      title: "Ruc Emisor",
      dataIndex: "ruc_emisor",
      key: "ruc_emisor",
      align: "center",
      sorter: (a, b) => utils.antdTableSorter(a, b, "ruc_emisor"),
    },
    {
      title: "Nro. Factura",
      dataIndex: "num_factura",
      key: "num_factura",
      align: "center",
      sorter: (a, b) => utils.antdTableSorter(a, b, "num_factura"),
    },
    {
      title: "Fecha Emisión",
      dataIndex: "fecha_emision",
      key: "fecha_emision",
      align: "center",
      sorter: (a, b) => utils.antdTableSorter(a, b, "fecha_emision"),
      render: (date) => utils.getFullDate(date),
    },
    {
      title: "Condición",
      dataIndex: "cond_venta",
      key: "cond_venta",
      align: "center",
      sorter: (a, b) => utils.antdTableSorter(a, b, "cond_venta"),
    },
    {
      title: "Estado",
      dataIndex: "estado_doc",
      key: "estado_doc",
      align: "center",
      sorter: (a, b) => utils.antdTableSorter(a, b, "estado_doc"),
    },
    {
      title: "Documento",
      dataIndex: "ruta_doc",
      key: "ruta_doc",
      align: "center",
      sorter: (a, b) => utils.antdTableSorter(a, b, "_id"),
      render: (doc) => {
        if (doc) {
          return (
            <a href="#" onClick={() => handleDocumentClick(doc)}>
              <FilePdfOutlined style={{ fontSize: "30px" }} />
            </a>
          );
        } else {
          return <CloseOutlined style={{ fontSize: "30px" }} />;
        }
      },
    },
    {
      title: "CDC",
      dataIndex: "cdc",
      key: "cdc",
      align: "center",
      width: 140,
      fixed: "center",
      sorter: (a, b) => utils.antdTableSorter(a, b, "cdc"),
      render: (cdc) => {
        const findCdc = data.find((element) => element.cdc === cdc);

        if (findCdc.enlace !== null) {
          return (
            <a href="#" onClick={() => window.open(findCdc.enlace, "_blank")}>
              {cdc}
            </a>
          );
        } else return cdc;
      },
    },

    {
      title: "Correo Emisor",
      dataIndex: "correo_emisor",
      key: "correo_emisor",
      align: "center",
      fixed: "center",
      sorter: (a, b) => utils.antdTableSorter(a, b, "correo_emisor"),
    },
    {
      title: "Rec. Correo",
      dataIndex: "rec_correo",
      key: "correo_emisor",
      align: "center",
      fixed: "center",
      render: (e) => (e === 1 || e === typeof null ? "Si" : "No"),
      sorter: (a, b) => utils.antdTableSorter(a, b, "correo_emisor"),
    },
    {
      title: "Desc. Marangatu",
      dataIndex: "desc_marangatu",
      key: "correo_emisor",
      align: "center",
      fixed: "center",
      render: (e) => (e === 1 || e === typeof null ? "Si" : "No"),
      sorter: (a, b) => utils.antdTableSorter(a, b, "correo_emisor"),
    },
    {
      title: "Cargado en ERP",
      dataIndex: "registo_unysoft",
      key: "registo_unysoft",
      align: "center",
      sorter: (a, b) => utils.antdTableSorter(a, b, "registo_unysoft"),
    },
    {
      title: "Moneda",
      dataIndex: "moneda",
      key: "moneda",
      align: "center",
      sorter: (a, b) => utils.antdTableSorter(a, b, "moneda"),
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      align: "center",
      sorter: (a, b) => utils.antdTableSorter(a, b, "total"),
      render: (item) => item.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."),
    },
  ].filter((item) => !item.hidden);
