import authConstants  from '../../constants/servicio-autenticacion/Auth';


//servicio-autenticacion/constants/Auth
const initState = {
  loading: false,
  message: '',
  showMessage: false,
  redirect: '',
  token: localStorage.getItem(authConstants.AUTH_TOKEN)
}

const authReducer = (state = initState, action) => {
	const { type, payload } = action
	switch (type) {
		case authConstants.SHOW_LOADING: {
			return {
				...state,
				loading: true
			}
		}
		case authConstants.AUTHENTICATED:
			const { token, username } = payload
			return {
				...state,
				loading: false,
				redirect: '/app/dashboards/inicio',
				token: token
			}
		
		case authConstants.SHOW_AUTH_MESSAGE: 
			return {
				...state,
				message: payload,
				showMessage: true,
				loading: false
			}
		case authConstants.HIDE_AUTH_MESSAGE: 
			return {
				...state,
				message: '',
				showMessage: false,
			}
		case authConstants.SIGNOUT_SUCCESS: {
			return {
				...state,
				token: null,
				redirect: '/',
				loading: false
			}
		}
		case authConstants.SIGNUP_SUCCESS: {
			return {
			  ...state,
			  loading: false,
			  token: payload,
			  redirect: '',
			}
		}
		case authConstants.SIGNIN_WITH_API: 
		case authConstants.SIGNIN_WITH_GOOGLE_AUTHENTICATED: 
		case authConstants.SIGNIN_WITH_FACEBOOK_AUTHENTICATED: {
			return {
				...state,
				loading: false,
				token: payload.token
			}
		}

		default:
			return state;
	}
}

export default authReducer;