//? Se importan las constantes
import  unysoftConstants from "../../constants/servicio-gestion-unysoft/ServiceUnysoft";

const initState = {
    payload: [],
    loading: false,
};

const unysoftReducer = (state = initState, action) => {
    switch (action.type) {

        //? Reducer Listar haberes
        case unysoftConstants.CALCULATE_HABERES: {
            return {
                loading: true,
                ...state,
            };
        }

        case unysoftConstants.CALCULATE_HABERES_SUCCESS: {
            return {
                ...state,
                payload: action.data,
                loading: false,
            };
        }

        default:
            return state;
    }
};

export default unysoftReducer;
