import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listModulosAction } from "redux/actions/servicio-autenticacion/User";
import { compose } from "redux";
import { selectCurrentUser, selectUserLoading } from "redux/selectors/servicio-autenticacion/user.selector";

/*** Este componente de alto nivel verifica si el usuario tiene acceso al modulo ***/

const withModulos =  (data = false) => (nombreModulo) => (Component) => (props) => {

    let dispatch = useDispatch()

    const currentUser = useSelector(selectCurrentUser)
    const loading = useSelector(selectUserLoading)

    // Trae data del usuario actual
    useEffect(() => {
      dispatch(listModulosAction())
    }, [loading]);
    
    const modulos = currentUser.modulos.map( m => m.modulo )

    const isRightModule = () => modulos.includes(nombreModulo[0])
    
    // Si el usuario posee el modulo correcto, renderizar componente
    if (isRightModule()) {
      return <Component {...props} />;
    } else {
      return data ? <h1>Acceso denegado</h1> : null;
    }
};

const withModulosHoc = compose((data, role, Component) => withModulos(data)(role)(Component));

export default withModulosHoc;