import homeConstants from '../../constants/servicio-autenticacion/Home';
  
  const initState = {
    payload: []
  }
 
const homeReducer = (state = initState, action) => {
  const { type, payload } = action
	switch (type) {

		case homeConstants.GET_MODULES:
			return {
				...state,
            }

    case homeConstants.MODULES_SUCCESS:
			return {
				...state,
        payload: payload
      } 

    default:
			return state;

    }
}

  export default homeReducer;