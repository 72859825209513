//? Se importan las constantes
import utilsConstants from "../../constants/servicio-gestion-vacaciones/Utils";

//? Accion para realizar los cálculos de vacaciones
export const calculateVacacionesAction = (payload) => {
  return {
    type: utilsConstants.POST_CALCULATE_VACACIONES,
    payload: payload,
  };
};

export const calculateVacacionesSuccessAction = (payload) => {
  return {
    type: utilsConstants.POST_CALCULATE_VACACIONES_SUCCESS,
    payload: payload,
  };
};

export default {
  calculateVacacionesAction,
  calculateVacacionesSuccessAction,
};
