import facturasConstants from "../../constants/servicio-vista-factura/VistaFactura";

const initState = {
  payloadFacturas: [],
  payloadFile: [],
  payloadOneFile: [],
  loadingFacturas: false,
  loadingFiles: false,
  loadingProcess: false,
};

const facturasReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {

    //? Reducer Listar Facturas electronicas
    case facturasConstants.GET_FACTURAS: {
      return {
        ...state,
        loadingFacturas: true,
      };
    }

    case facturasConstants.GET_FACTURAS_SUCCESS: {
      return {
        ...state,
        payloadFacturas: payload,
        loadingFacturas: false,
      };
    }

    //? Reducer Listar pdf
    case facturasConstants.GET_FILE: {
      return {
        ...state,
        loadingFiles: true,
      };
    }

    case facturasConstants.GET_FILE_SUCCESS: {
      return {
        ...state,
        payloadFile: payload,
        loadingFiles: false,
      };
    }

    //?  //? Reducer Listar pdf por paramentros
    case facturasConstants.GET_ONE_FILE: {
      return {
        ...state,
        loadingFiles: true,

      };
    }

    case facturasConstants.GET_ONE_FILE_SUCCESS: {
      return {
        ...state,
        payloadOneFile: payload,
        loadingFiles: false,
      };
    }
    default:
      return state;
  }
};
export default facturasReducer;
