export const CONSULTA_HISTORIAL = 'CONSULTA_HISTORIAL';
export const HISTORIAL_SUCCESS = 'HISTORIAL_SUCCESS';

export const CONSULTA_HISTORIAL_ONE = 'CONSULTA_HISTORIAL_ONE'
export const HISTORIAL_SUCCESS_ONE = 'HISTORIAL_SUCCESS_ONE'

export const HISTORIAL_CONSULTA_AGREGADO = 'HISTORIAL_CONSULTA_AGREGADO'
export const HISTORIAL_CONSULTA_AGREGADO_SUCCESS = 'HISTORIAL_CONSULTA_AGREGADO_SUCCESS'

export const GENERAR_MOVIMIENTO = 'GENERAR_MOVIMIENTO'
export const GENERAR_MOVIMIENTO_SUCCESS = 'GENERAR_MOVIMIENTO_SUCCESS'

// Constantes utilizados para la nueva logica "M"
export const DELETE_M_MOVIMIENTOS = 'DELETE_M_MOVIMIENTOS'
export const DELETE_M_MOVIMIENTOS_SUCCESS = 'DELETE_M_MOVIMIENTOS_SUCCESS'

export const CONSULTA_HISTORIAL_CURRENT_USER = 'CONSULTA_HISTORIAL_CURRENT_USER'
export const CONSULTA_HISTORIAL_CURRENT_USER_SUCCESS = 'CONSULTA_HISTORIAL_CURRENT_USER_SUCCESS'

export default {
    CONSULTA_HISTORIAL, HISTORIAL_SUCCESS,
    CONSULTA_HISTORIAL_ONE, HISTORIAL_SUCCESS_ONE,
    HISTORIAL_CONSULTA_AGREGADO, HISTORIAL_CONSULTA_AGREGADO_SUCCESS,
    GENERAR_MOVIMIENTO, GENERAR_MOVIMIENTO_SUCCESS,
    DELETE_M_MOVIMIENTOS, DELETE_M_MOVIMIENTOS_SUCCESS,
    CONSULTA_HISTORIAL_CURRENT_USER, CONSULTA_HISTORIAL_CURRENT_USER_SUCCESS
}