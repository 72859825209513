///CONSTANTES MODULOS
export const GET_CONTRATOS = 'GET_CONTRATOS';
export const GET_CONTRATOS_SUCCESS = 'GET_CONTRATOS_SUCCESS'
export const UPDATE_CONTRATOS = 'UPDATE_CONTRATOS'
export const UPDATE_CONTRATOS_SUCCESS = 'UPDATE_CONTRATOS_SUCCESS'
export const DELETE_CONTRATOS = 'DELETE_CONTRATOS'
export const DELETE_CONTRATOS_SUCCESS = 'DELETE_CONTRATOS_SUCCESS'
export const GET_CONTRATOS_ID = 'GET_CONTRATOS_ID'
export const GET_CONTRATOS_ID_SUCCESS = 'GET_CONTRATOS_ID_SUCCESS'
export const ADD_CONTRATOS = 'ADD_CONTRATOS'
export const ADD_CONTRATOS_SUCCESS = 'ADD_CONTRATOS_SUCCESS'


export const POST_FILES = 'POST_FILES'
export const POST_FILES_SUCCESS = 'POST_FILES_SUCCESS'

export const DELETE_FILES = 'DELETE_FILES'
export const DELETE_FILES_SUCCESS = 'DELETE_FILES_SUCCESS'

export default {
    GET_CONTRATOS, GET_CONTRATOS_SUCCESS,
    UPDATE_CONTRATOS, UPDATE_CONTRATOS_SUCCESS,
    DELETE_CONTRATOS, DELETE_CONTRATOS_SUCCESS,
    GET_CONTRATOS_ID, GET_CONTRATOS_ID_SUCCESS,
    ADD_CONTRATOS, ADD_CONTRATOS_SUCCESS,
    POST_FILES, POST_FILES_SUCCESS,
    DELETE_FILES, DELETE_FILES_SUCCESS
}