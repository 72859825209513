import { put, call } from "redux-saga/effects";

import userAdminActions from '../../../actions/servicio-autenticacion/UserAdmin'
import { message } from "antd";
import userService from "axios/servicio-autenticacion/UserAdmin"; ///importamos el servicio de axios

function* listUserAdmin() {
    try {
        const dataUser =  yield call(userService.listUserAdmin)
        const valor=dataUser;
         
            if (dataUser.msg) {
     
              message.success(dataUser.msg);
              
            }                 
            yield put(userAdminActions.userSuccessAdmin(valor));
      } catch (err) {
        message.error(err.message+'Error');
      }
}


  
//actualizacion de datos usuarios
function* addUserAdmin({ payload }) {
    try {
     
        const dataUser = yield call(userService.addUserAdmin, payload)
        
        if (dataUser.msg) {
          message.success(dataUser.msg);
        }  
          yield put(userAdminActions.userSuccessAdmin(dataUser));
          
         
      } catch (err) {
        message.error(err.message+'Contactar con el Administrador');
      }
}


//lista por ci datos usuarios
function* listOneUserAdmin({ payload }) {
    try {
        const dataUser = yield call(userService.listarOneUSer, payload)
       
        if (dataUser.msg) {
         
          const dataCi=dataUser.msg;
        
          yield put(userAdminActions.userSuccessOnlyAdmin(dataCi));
        }
  
      } catch (err) {
        message.error(err.message+'Contactar con el Administrador');
      }
}


function* listOneUserAdminCI({ payload }) {
        try {
          const dataUser = yield call(userService.listOneUserDataAxios, payload)
          if (dataUser.msg) {
            message.error(dataUser.msg);
          } else {
            
            const dataCi=dataUser.ci;
          
           
            yield put(userAdminActions.userSuccessOnlyAdminCi(dataCi));
          }
        } catch (err) {
          message.error(err.message);
        }
}

function* editUserAdmin({ payload }) {
    try {
        const dataUser = yield call(userService.editUserAdminAxios, payload)
        if (dataUser.msg) {
        message.error(dataUser.msg);
        }  
        
        yield put(userAdminActions.userSuccessEditAdmin(dataUser));
        
    } catch (err) {
        message.error(err.message);
    }
}

export default  {
    listUserAdmin,
    addUserAdmin,
    listOneUserAdmin,
    listOneUserAdminCI,
    editUserAdmin
}
  