import { put, call } from "redux-saga/effects";
import { ROLES_SUCCESS } from "../../../constants/servicio-autenticacion/Roles";
import { showAuthMessageAction } from "../../../actions/servicio-autenticacion/Auth";
import { message } from "antd";
import userService from "axios/servicio-autenticacion/UserServices"; ///importamos el servicio de axios
import userActions from '../../../actions/servicio-autenticacion/User'


export  function*  createTokenPass( { payload } ){
    try {
      const updPassUrl =  yield  call(userService.sendEmailAxios, payload)
          if (updPassUrl.msg) {
            yield put(showAuthMessageAction(updPassUrl.msg));
            yield put(userActions.createTokenPassSuccessUserAction());
          } else {
            message.success(`Se ha enviado un correo a ${payload} con los pasos a seguir`);
            yield put(userActions.createTokenPassSuccessUserAction());
          }
    }
     catch (err) {
      console.error(err.message);
    }
}


//actualizacion de datos usuarios
export function* updateData( { payload } ) {
    try {
   
      const updData = yield call(userService.updateDataUserAxios, payload)
  
      if (updData.msg) {
        message.success(updData.msg);
      } else {
        yield put(userActions.listUserAction());
        message.success("Datos Actualizado");
      }
    } catch (err) {
      message.error(err.message+'Contactar con el Administrador');
    }
  }


///actualizacion de password de usuario
export function* updatePassword( { payload } ) {
    try {
   
      const updPass = yield call(userService.updatePassUserAxios, payload)
  
      if (updPass.msg) {
        message.success(updPass.msg);
      } else {
        yield put(userActions.listUserAction());
        message.success("Password Actualizado");
      }
    } catch (err) {
      message.error(err.message+'Contantar con el Administrador');
    }
  }

///elimina usuarios
export function* deleteUser( { payload } ) {
   
    try {
      const dtlUser = yield call(userService.deleteUserAxios, payload);

      if (dtlUser.msg) {
        yield put(userActions.listUserAction());
        message.success(dtlUser.msg);
      }
    } catch (err) {
      message.error(err.message+'Contantar con el Administrador');
    }
  }


//? Lista todos los usuarios -> Endpoint Servicio de autenticacion
export function* getUserFullData( { payload } ) {
    try{
      const user = yield call(userService.getOnlyOneUser, payload);

      if (user.msg) {
        message.error(user.msg);
      } else {
        yield put(userActions.getOneUserFullDataSuccess(user));
      }

    } catch (err) {
      message.error(err.message);
    }
  }


//crear usuarios
export function* createUser( { payload } ) {
    try {
      const createUser = yield call(userService.createUserAxios, payload);
      const vlu=createUser.username;
    
      if (createUser.msg) {
        message.success(createUser.msg);
      } else {
        yield put(userActions.createTokenPassUserAction(vlu));
        message.success("Usuario Creado");
      }
    } catch (err) {
      message.error(err.message+'Contantar con el Administrador');
    }
  }

//! Elimina modulo del usuario
export function* deleteModuleUser({ payload }) {
  try {
    const moduleUser = yield call(userService.deleteModuleUserAxios, payload);

    if (moduleUser.error) {
      message.error(moduleUser.error);
    }
    yield put(userActions.deleteModuleUserSuccessAction());
    message.success("¡Módulo Eliminado!");
  } catch (err) {
    message.error(err.message);
  }
}

//! Crea los modulos al usuario
export function* createModuleUser( { payload } ) {
    try {
      const moduleUser = yield call(userService.createModuleUserAxios, payload)

      if (moduleUser.msg) {
        message.error(moduleUser.msg);
      } else {
        yield put(userActions.createModuleUserSuccessAction());
        message.success("¡Módulo Agregado!");
      }
    } catch (err) {
      message.error(err.message);
    }
  }

//! listado de roles de un usuario por id
export function* getModuleUser({ payload }) {
  try {
    const dataUser = yield call(userService.getModuleUserAxios, payload);
    if (dataUser.msg) {
      message.error(dataUser.msg);
    }
    yield put(userActions.getModuleUserSuccessAction(dataUser));
  } catch (err) {
    message.error(err.message);
  }
}

//listado de un usuario por id
export function* listOneUserData( { payload } ) {

    try {
      const dataUser = yield call(userService.listOneUserDataAxios, payload)
      if (dataUser.msg) {
        message.error(dataUser.msg);
      } else {
        yield put(userActions.listUserDataAction(dataUser));
      }
    } catch (err) {
      message.error(err.message);
    }
  }

//listado de un usuario por id, sin params
export function* listOnlyOneUser( { payload } ) {
    try {
      const dataUser = yield call(userService.getOnlyOneUser, payload)

      if (dataUser.msg) {
        message.error(dataUser.msg);
      } 
      yield put(userActions.getOnlyOneUserSuccess(dataUser));

    } catch (err) {
      message.error(err.message);
    }
  }

//listado de usuarios
export function* listUser() {
    try {
      const user = yield call(userService.listUserAxios)
      
      if (user) {
        yield put(userActions.userSuccessAction(user));
      }
    } catch (err) {
      message.error(err.message);
    }
  }

///listado de roles
export function* listRol() {
    try {
      const roles = yield call(userService.listRolesAxios)

      if (roles.error == "true") {
        message.error(roles.msg);
      } else {
        yield put({ type: ROLES_SUCCESS, data: roles });
      }
    } catch (err) {
      message.error(err.message);
    }
}
///listado de modulos
export function* listModulos() {
    try {
      const modulos = yield call(userService.listModulosAxios)

      if (modulos.error == "true") {
        message.error(modulos.msg);
      } else {
        yield put(userActions.moduloSuccessAction(modulos));
      }
    } catch (err) {
      message.error(err.message);
    }
}

//listado de un usuario por id
export function* listOneEmail ({ payload }) {
    
    try {
      const dataUser = yield call(userService.getEmailAxios, payload)

      yield put(userActions.getModuleUserSuccessAction(dataUser));

    } catch (err) {
      console.error(err.message);
      yield put(userActions.getModuleUserSuccessAction( { auth: false } ));
    }
    
}

export default {
    createTokenPass,
    updateData,
    updatePassword,
    deleteUser,
    getUserFullData,
    createUser,
    deleteModuleUser,
    createModuleUser,
    getModuleUser,
    listOneUserData,
    listOnlyOneUser,
    listUser,
    listRol,
    listModulos,
    listOneEmail
}
