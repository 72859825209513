import React from 'react';
import PlayeroForm from '../PlayeroForm';

const ProcesarTickets = () => {
	return (
		<PlayeroForm mode="ADD"/>
	)
}

export default ProcesarTickets
