import axios from "axios";
import detalleList  from './detalleList.structure'
import { all, put, call, takeLatest, delay } from "redux-saga/effects";
import { updateProgressBarAction, registerComprobanteSended } from "redux/actions/servicio-liquidacion-digital/Liquidaciones";
const ServicioLiquidacionDigital = {};
const { REACT_APP_API_LIQ , REACT_APP_API_URL_DOCUMENTA, REACT_APP_DOCUMENTA_API_USER, REACT_APP_DOCUMENTA_API_PASS} = process.env;

axios.defaults.headers.common['Authorization'] = localStorage.getItem('auth_token');





//? Logear a DOCUMENTA API.
ServicioLiquidacionDigital.loginToDocumenta = async () => {

  return await axios({
    method: "post",
   
    url: `${REACT_APP_API_URL_DOCUMENTA}/api/auth/login`,
    data: {
      username: REACT_APP_DOCUMENTA_API_USER,
      password: REACT_APP_DOCUMENTA_API_PASS
    },
  })
    .then((response) => response.data.valor)
    .catch((err) => err);
};



//? Logear a DOCUMENTA API.
ServicioLiquidacionDigital.insertarNuevaLiquidacion = async ( data ) => {
  
  const date = new Date() 
  
  const { login:{ jwt }, payload:{ ano, mes } } = data
  

  const codigoRDM = `TecnoEdil-${mes < 10? '0'+mes: mes}-${ano}`

  const fecha = `${ date.getDate()}/${mes < 10? '0'+mes: mes}/${ano}`

  const response = await axios({
       method: "post",
       url: `${REACT_APP_API_URL_DOCUMENTA}/api/liquidation/insert`,
       responseType: 'json',
       headers: {
         'Authorization': `Bearer ${jwt}` 
       },
       data:{
           codigo: codigoRDM,
           plantilla: 'RSPD',
           fechadocumento: fecha
       }
     }).then((res) =>
         res.data 
       )
       .catch((err) => console.error("Error: ",err));

     return await response;
};



//? Listar Liquidaciones.
ServicioLiquidacionDigital.getLiquidacionesAxios = async (data) => {

const {ano, mes} = data
  return await axios({
    headers: { "Content-Type": "application/json"},
    method: "get",
    url: `${REACT_APP_API_LIQ}/api/lisLiqui`,
    params: {
      'ano': ano,
      'mes': mes
    },
    responseType: "json",
  }).then((response) => response.data)
    .catch((err) => err);
};

//? Traer Data para Comprobantes de Liquidacion.
ServicioLiquidacionDigital.getDataComprobanteLiquidacionAxios = async (data) => {
  
  console.log('getDataComprobanteLiquidacionAxios: ', data)
  const {ano, mes} = data
  return await axios({
    headers: { "Content-Type": "application/json"},
    method: "get",
    url: `${REACT_APP_API_LIQ}/api/liquiCompData`,
    params: {
      'ano': ano,
      'mes': mes
    },
    responseType: "json",
  })
    .then((response) => response.data)
    .catch((err) => err);
};


//? Agregar Liquidaciones.
ServicioLiquidacionDigital.checkCreationInfo = async (data) => {
  
  return await axios({
    method: "get",
    url: `${REACT_APP_API_LIQ}/api/checkCreationInfo`,
    params: {...data}
  })
    .then((response) => response.data)
    .catch((err) => err);
};



//? Eliminar Liquidaciones.
ServicioLiquidacionDigital.deleteLiquidacionesAxios = async (data) => {
  const { _id } = data;

  return await axios({
    method: "delete",
    url: `${REACT_APP_API_LIQ}/contratos/api/certificado/delete`,
    data: {
      _id: data,
    },
  }).then((response) => response);
};



ServicioLiquidacionDigital.generateOneEmployeePDFAxios = async (data) => {
  return await axios({
    method: "post",
    url: `${REACT_APP_API_LIQ}/api/generateOneEmployeePDF`,
    data: data
  })
    .then((response) => response.data)
    .catch((err) => err);
};





//? Generar PDFs de Liquidaciones.
const generatePDF = async (data) => {

  return await axios({
    method: "post",
    url: `${REACT_APP_API_LIQ}/api/generatePDF`,
    data: data,
  })
    .then((response) => response.data)
    .catch((err) => err);
};



//? Generar PDFs de Liquidaciones.
ServicioLiquidacionDigital.generatePDFAxios = function*(data){

  const {ano, mes, list} = data
  
  let filesCreated = 0
  
  for(let i = 0; i < list.length; i++){

    const comprobante = list[i]
    const { NOMBRE } = comprobante
    const UUID =`${NOMBRE.replace(' ', '')}_${mes}_${ano}`.trim()
    
    const PDFStat =  yield call(generatePDF, { ano, mes, UUID, comprobante } )

    if(PDFStat.base64){
      filesCreated++
      const percent = filesCreated*100/list.length
      const fielBase64 = PDFStat.base64
      yield put(updateProgressBarAction( { percent, fielBase64, NOMBRE } ))
    }
  }

  return  filesCreated

}
   
  
const sendCompAxios = async( data ) => {
    const {  comprobante, jwt } = data

     const response = await axios({
        method: "post",
        url: `${REACT_APP_API_URL_DOCUMENTA}/api/receipt/insert`,
        responseType: 'json',
        headers: {
          'Authorization': `Bearer ${jwt}`
        },
        data: comprobante
      }).then( res => res.data )
        .catch((err) => console.error("Error: ",err))

      return response
  }

  const registerPDFCompAxios = async( data ) => {

     const response = await axios({
        method: "post",
        url: `${REACT_APP_API_LIQ}/api/PDFSendedToDocumenta`,
        responseType: 'json',
        params: data
      }).then( res => res.data )
        .catch((err) => console.error("Error: ",err))

      return response
  }


  ServicioLiquidacionDigital.insertarComprobantes =  function* (payload){
    try{
    
      const { date:{ ano, mes }, list, liquidacion, jwt } = payload
      const { valor:{ codigo } } = liquidacion
      let response;
      const listaComprobantes = []
      const faltantes = []
      console.log('insertarComprobantes: ',payload)

       for(let i=0; i < list.length; i++){
     
        const item = list[i]
        
        const { base64, EMAIL, TOTALDESCUENTOS, TOTALHABERES, TOTALLIQUIDOAPAGO, CI, sended } = item;
       console.log(EMAIL, sended)
        // se modifica el formato del objeto segun documentacion Documenta, y se filtra si se encuentra en desarrollo
        const detalles = yield call(detalleList,item)
        
        const comprobante = {
          codigoliquidacion: codigo,
          usuario: EMAIL,
          base64: base64,
          detalleList:  detalles,
          total:{
              totalingresos: TOTALHABERES,
              totalretenciones: TOTALDESCUENTOS,
              totalneto: TOTALLIQUIDOAPAGO,
              fechapago: "01/04/2022"
          }
        }
          let res; 

          if(!sended){ 
            res = yield call(sendCompAxios, { comprobante, jwt }) 
          }
         if(!res){ 
            yield console.log('No enviado')
            yield !sended && faltantes.push(CI)
          }else{
            yield console.log('Enviado')
            yield call(registerPDFCompAxios, { CI,  mes, ano })
            yield put(registerComprobanteSended({CI}))
            yield listaComprobantes.push(CI)
            const percent = listaComprobantes.length*100/list.length
            yield put(updateProgressBarAction({percent}))
          }
        }
        yield response = {
          listaComprobantes,
          msg: listaComprobantes.length=== list.length? 'Los comprobantes han sido enviados Exitosamente':'algunos Comprobantes no han sido enviados',
          faltantes,
          error: listaComprobantes.length < list.length? true: false
        }
        
         return  response

    }catch(err){
      console.error(err)
    }
    
  }




  ServicioLiquidacionDigital.keepConnectionLive = async () => {
    return await axios({
      headers: { 
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
      method: "get",
      url: `${REACT_APP_API_LIQ}/api/lisLiqui`,
      responseType: "json",
    })
      .then((response) => response.data)
      .catch((err) => err);
  }


export default ServicioLiquidacionDigital;
