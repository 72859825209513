
export const GET_USERS_DOMAIN_REPORTES = "GET_USERS_DOMAIN_REPORTES";
export const GET_USERS_DOMAIN_REPORTES_SUCCESS = "GET_USERS_DOMAIN_REPORTES_SUCCESS";

export const CREATE_USER_REPORTES = "CREATE_USER_REPORTES";
export const CREATE_USER_REPORTES_SUCCESS = "CREATE_USER_REPORTES_SUCCESS";

export const DELETE_USER_REPORTES = "DELETE_USER_REPORTES";
export const DELETE_USER_REPORTES_SUCCESS = "DELETE_USER_REPORTES_SUCCESS";

export const EDIT_USER_REPORTES = "EDIT_USER_REPORTES";
export const EDIT_USER_REPORTES_SUCCESS = "EDIT_USER_REPORTES_SUCCESS";

export const GET_ALL_USERS_REPORTES = "GET_ALL_USERS_REPORTES";
export const GET_ALL_USERS_REPORTES_SUCCESS = "GET_ALL_USERS_REPORTES_SUCCESS";

export const GET_USER_ADMIN = "GET_USER_ADMIN";
export const GET_USER_ADMIN_SUCCESS = "GET_USER_ADMIN_SUCCESS ";

export const GET_ONE_USER_REPORTES = "GET_ONE_USER_REPORTES";
export const GET_ONE_USER_REPORTES_SUCCESS = "GET_ONE_USER_REPORTES_SUCCESS";


export default {
    GET_USERS_DOMAIN_REPORTES, GET_USERS_DOMAIN_REPORTES_SUCCESS, 
    CREATE_USER_REPORTES, CREATE_USER_REPORTES_SUCCESS,
    DELETE_USER_REPORTES, DELETE_USER_REPORTES_SUCCESS,
    EDIT_USER_REPORTES, EDIT_USER_REPORTES_SUCCESS,
    GET_ALL_USERS_REPORTES, GET_ALL_USERS_REPORTES_SUCCESS,
    GET_USER_ADMIN, GET_USER_ADMIN_SUCCESS,
    GET_ONE_USER_REPORTES, GET_ONE_USER_REPORTES_SUCCESS
}