import { all, takeEvery, fork } from "redux-saga/effects";
import {  CONSULTA_MODULOS_GET, ADD_MODULOS_POST, 
          DELETE_MODULOS_POST, UPDATE_MODULOS_POST } from "../../constants/servicio-autenticacion/Modulos";
import modulosFunctions from './functions/Modulos.functions'


export function* listModulosSaga() {
  yield takeEvery(CONSULTA_MODULOS_GET, modulosFunctions.listModulos );
}


export function* addModulosSaga() {
  yield takeEvery(ADD_MODULOS_POST, modulosFunctions.addModulos );
}


export function* deleteModulosSaga() {
  yield takeEvery(DELETE_MODULOS_POST, modulosFunctions.deleteModulos );
}


export function* updateModulosSaga() {
  yield takeEvery(UPDATE_MODULOS_POST, modulosFunctions.updateModulos);
}

export default function* rootSagaModulos() {
  yield all([
    fork(listModulosSaga),
    fork(addModulosSaga),
    fork(deleteModulosSaga),
    fork(updateModulosSaga),
  ]);
}
