//? Se importan las constantes
import reportesConstants from "../../constants/servicio-reportes/Reportes";

//? Accion de Listar Reportes
export const listReportesAction = () => {
  return {
    type: reportesConstants.GET_REPORTES,
  };
};

export const successReportesAction = (data) => {
  return {
    type: reportesConstants.GET_REPORTES_SUCCESS,
    payload: data,
  };
};

//? Accion de Agregar Reportes
export const addReportesAction = (data) => {
  return {
    type: reportesConstants.ADD_REPORTES,
    payload: data,
  };
};

export const SuccessAddReportesAction = () => {
    return {
      type: reportesConstants.ADD_REPORTES_SUCCESS
    };
  };
  
//? Accion de Actualizar Reportes 
export const updateReportesAction = (data) => {
  return {
    type: reportesConstants.UPDATE_REPORTES,
    payload: data,
  };
};


export const successUpdateReportesAction = () => {
    return {
      type: reportesConstants.UPDATE_REPORTES_SUCCESS
    };
  };

//? Accion de eliminar Reportes
export const deleteReportesAction = (data) => {
  return {
    type: reportesConstants.DELETE_REPORTES,
    payload: data,
  };
};

export const successDeleteReportesAction = () => {
    return {
      type: reportesConstants.DELETE_REPORTES_SUCCESS,
    };
  };
  

export default {
  listReportesAction, successReportesAction,
  addReportesAction, SuccessAddReportesAction,
  updateReportesAction, successUpdateReportesAction,
  deleteReportesAction, successDeleteReportesAction
}