//? Se importan las constantes
import movimientosConstants from "../../constants/servicio-gestion-vacaciones/Movimientos";
import usersConstants from "../../constants/servicio-autenticacion/Users";

//? Accion para listar solo un usuario del historial
export const historialPersonaOne = (data) => {
  return {
    type: movimientosConstants.CONSULTA_HISTORIAL_ONE,
    payload: data,
  };
};

export const historialSuccessOneAction = (data) => {
  return {
    type: movimientosConstants.HISTORIAL_SUCCESS_ONE,
    payload: data,
  };
};

//? Accion para listar todo los usuarios del historial
export const historialPersona = () => {
  return {
    type: movimientosConstants.CONSULTA_HISTORIAL,
  };
};

export const historialSuccessAction = (data) => {
  return {
    type: movimientosConstants.HISTORIAL_SUCCESS,
    payload: data,
  };
};

//? Accion para listar todo los usuarios -> Endpoint Servicio de Autenticacion
export const listadoUsuarios = () => {
  return {
    type: usersConstants.CONSULTA_USER_GET,
  };
};

export const listadoSuccess = (data) => {
  return {
    type: usersConstants.USER_SUCCESS,
    payload: data,
  };
};

//? Acccion para listar el historial de saldos de dias
export const consultaHistorialSumAction = (data) => {
  return {
    type: movimientosConstants.HISTORIAL_CONSULTA_AGREGADO,
    payload: data
  };
};

//? Acccion para listar el historial de saldos de dias
export const consultaHistorialSumAgregadoAction = (data) => {
  return {
    type: movimientosConstants.HISTORIAL_CONSULTA_AGREGADO_SUCCESS,
    payload: data
  };
};

//? Accion para crear movimientos
export const generarMovimientoAction = (data) => {
  return {
    type: movimientosConstants.GENERAR_MOVIMIENTO,
    payload: data,
  };
};

export const consultaHistorialCurrentUser = (data) => {
  return {
    type: movimientosConstants.CONSULTA_HISTORIAL_CURRENT_USER,
    payload: data
  }
}

export const consultaHistorialCurrentUserSuccess = (data) => {
  return {
    type: movimientosConstants.CONSULTA_HISTORIAL_CURRENT_USER_SUCCESS,
    payload: data
  }
}

export const generarMovimientoSuccessAction = (data) => {
  return {
    type: movimientosConstants.GENERAR_MOVIMIENTO_SUCCESS,
    payload: data
  }
}

//----------------Implementacion logica M--------
//? Accion para eliminar Movimientos "M"
export const deleteMovimientosMAction = (data) => {
  return {
    type: movimientosConstants.DELETE_M_MOVIMIENTOS,
    payload: data,
  };
};

export const deleteMovimientosMSuccessAction = () => {
  return {
    type: movimientosConstants.DELETE_M_MOVIMIENTOS_SUCCESS,
  };
};



export default {
  historialPersonaOne, historialSuccessOneAction,
  historialPersona, historialSuccessAction,
  listadoUsuarios, listadoSuccess,
  consultaHistorialSumAction, consultaHistorialSumAgregadoAction,
  generarMovimientoAction,  generarMovimientoSuccessAction,
  consultaHistorialCurrentUser, consultaHistorialCurrentUserSuccess,
  deleteMovimientosMAction, deleteMovimientosMSuccessAction
}