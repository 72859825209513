import  usersConstants from "../../constants/servicio-contratos/Users";
const initState = {
  role: "",
  payload: [],
  ci: "",
  loading: false,
  update: false,
};

const contratosUsersReducer = (state = initState, action) => {
  switch (action.type) {
    //? Reducer Lista usuarios de dominio
    case usersConstants.GET_USERS_DOMAIN_CONTRATOS: {
      return {
        ...state,
        loading: true,
      };
    }
    case usersConstants.GET_USERS_DOMAIN_CONTRATOS_SUCCESS: {
      return {
        ...state,
        payload: action.payload,
        loading: false,
      };
    }

    //? Reducer Listar usuarios
    case usersConstants.GET_ALL_USERS_CONTRATOS: {
      return {
        ...state,
        loading: true,
      };
    }
    case usersConstants.GET_ALL_USERS_CONTRATOS_SUCCESS: {
      return {
        ...state,
        payload: action.payload,
        loading: false,
      };
    }

    //? Reducer Crear usuarios
    case usersConstants.CREATE_USER_CONTRATOS: {
      return {
        ...state,
        loading: true,
        update: true,
      };
    }

    case usersConstants.CREATE_USER_CONTRATOS_SUCCESS: {
      return {
        ...state,
        loading: false,
        update: false,
      };
    }

    //? Reducer editar usuarios
    case usersConstants.EDIT_USER_CONTRATOS: {
      return {
        ...state,
        loading: true,
        update: true,
      };
    }
    case usersConstants.EDIT_USER_CONTRATOS_SUCCESS: {
      return {
        ...state,
        loading: false,
        update: false,
      };
    }

    //? Reducer eliminar usuarios
    case usersConstants.DELETE_USER_CONTRATOS: {
      return {
        ...state,
        loading: true,
        update: true,
      };
    }

    case usersConstants.DELETE_USER_CONTRATOS_SUCCESS: {
      return {
        ...state,
        loading: false,
        update: false,
      };
    }

    //? Reducer para listar solamente un usuario -> obtiene el rol
    case usersConstants.GET_ONE_USER_CONTRATOS: {
      return {
        ...state,
        loading: false,
        update: false,
      };
    }
    case usersConstants.GET_ONE_USER_CONTRATOS_SUCCESS: {
      return {
        ...state,
        role: action.payload,
        loading: false,
        update: false,
      };
    }

    //? Reducer de listar solo un usuario -> Endpoint Servicio de Autenticacion
    case usersConstants.GET_USER_ADMIN: {
      return {
        ...state,
        loading: false,
        update: false,
      };
    }
    case usersConstants.GET_USER_ADMIN_SUCCESS: {
      return {
        ...state,
        ci: action.payload,
        loading: false,
        update: false,
      };
    }

    default:
      return state;
  }
};

export default contratosUsersReducer;
