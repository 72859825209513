import axios from "axios";
const {REACT_APP_API_URL} = process.env;

//Configuración de los servicios de axios, dicha configuración es llamada en las sagas
export const  authSaga = async (data) => {
  const { email, password } = data;

  return axios({
    method: "post",
    url: `${REACT_APP_API_URL}/admin/api/login`,
    data: {
      username: email,
      password: password,
    },
  })
    .then((response) => response.data)
    .catch((err) => err);
};
