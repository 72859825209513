import themeConstants from '../../constants/servicio-autenticacion//Theme';

export function toggleCollapsedNav(navCollapsed) {
  return {
    type: themeConstants.TOGGLE_COLLAPSED_NAV,
    navCollapsed
  };
}

export function onNavStyleChange(sideNavTheme) {
  return {
    type: themeConstants.SIDE_NAV_STYLE_CHANGE,
    sideNavTheme
  };
}

export function onLocaleChange(locale) {
  return {
    type: themeConstants.CHANGE_LOCALE,
    locale
  };
}

export function onNavTypeChange(navType) {
  return {
    type: themeConstants.NAV_TYPE_CHANGE,
    navType
  };
}

export function onTopNavColorChange(topNavColor) {
  return {
    type: themeConstants.TOP_NAV_COLOR_CHANGE,
    topNavColor
  };
}

export function onHeaderNavColorChange(headerNavColor) {
  return {
    type: themeConstants.HEADER_NAV_COLOR_CHANGE,
    headerNavColor
  };
}

export function onMobileNavToggle(mobileNav) {
  return {
    type: themeConstants.TOGGLE_MOBILE_NAV,
    mobileNav
  };
}

export function onSwitchTheme(currentTheme) {
  return {
    type: themeConstants.SWITCH_THEME,
    currentTheme
  };
}

export function onDirectionChange(direction) {
  return {
    type: themeConstants.DIRECTION_CHANGE,
    direction
  };
}