import { all, takeEvery, put, fork, call } from "redux-saga/effects";
//? Se importan las constantes
import { GET_USERS_DOMAIN_CONTRATOS, GET_ALL_USERS_CONTRATOS, CREATE_USER_CONTRATOS,
  DELETE_USER_CONTRATOS, EDIT_USER_CONTRATOS, GET_USER_ADMIN, GET_ONE_USER_CONTRATOS } from "../../constants/servicio-contratos/Users";
import user_contratos_func from './functions/Users.functions'

//? Lista todos los usuarios del dominio -> Endpoint de Servicio de autenticacion
export function* listUsersDomainSagas() {
  yield takeEvery(GET_USERS_DOMAIN_CONTRATOS, user_contratos_func.listUsersDomain)
}

//? Lista por id de usuarios de contratos -> obtiene el rol de cada usuario
export function* listOneUsersContratosSagas() {
  yield takeEvery(GET_ONE_USER_CONTRATOS, user_contratos_func.listOneUsersContratos);
}

//? Listar todos los usuarios de contratos
export function* listAllUserSagas() {
  yield takeEvery(GET_ALL_USERS_CONTRATOS, user_contratos_func.listAllUser);
}

//? Crea Usuarios de contratos
export function* createUserContratosSagas() {
  yield takeEvery(CREATE_USER_CONTRATOS, user_contratos_func.createUserContratos);
}

//? Edita Usuarios de contratos
export function* editUserContratosSagas() {
  yield takeEvery(EDIT_USER_CONTRATOS, user_contratos_func.editUserContratos);
}

//? Elimina usuarios de contratos
export function* deleteUserContratosSagas() {
  yield takeEvery(DELETE_USER_CONTRATOS,user_contratos_func.deleteUserContratos );
}

//? Lista datos de un usuario por id -> Endpoint de Servicio de Autenticacion
export function* listOneUserSagas() {
  yield takeEvery(GET_USER_ADMIN, user_contratos_func.listOneUser);
}

export default function* rootUsersContratosSaga() {
  yield all([
    fork(listOneUserSagas),
    fork(listAllUserSagas),
    fork(listUsersDomainSagas),
    fork(editUserContratosSagas),
    fork(createUserContratosSagas),
    fork(deleteUserContratosSagas),
    fork(listOneUsersContratosSagas),
  ]);
}
