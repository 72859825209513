import  certificadosConstants from "../../constants/servicio-contratos/Certificados";

const initState = {
  payload: [],
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
};

const certificadosReducer = (state = initState, action) => {
  switch (action.type) {
    //? Reducer Listar certificados
    case certificadosConstants.GET_CERTIFICADOS: {
      return {
        loading: true,
        ...state,
      };
    }

    case certificadosConstants.GET_CERTIFICADOS_SUCCESS: {
      return {
        ...state,
        payload: action.payload,
        loading: false,
      };
    }

    //? Reducer Agregar certificados
    case certificadosConstants.ADD_CERTIFICADOS: {
      return {
        ...state,
        loading: true,
      };
    }

    case certificadosConstants.ADD_CERTIFICADOS_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    //? Reducer Actualizar certificados
    case certificadosConstants.UPDATE_CERTIFICADOS: {
      return {
        ...state,
        loading: true,
      };
    }


    case certificadosConstants.UPDATE_CERTIFICADOS_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    //? Reducer Eliminar certificados
    case certificadosConstants.DELETE_CERTIFICADOS: {
      return {
        ...state,
        loading: true,
      };
    }

    case certificadosConstants.DELETE_CERTIFICADOS_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};
export default certificadosReducer;
