import modulosConstants from "../../constants/servicio-autenticacion/Modulos";

export const listModulosAction = () => {
    return {
      type: modulosConstants.CONSULTA_MODULOS_GET,
    
    }
  };
  export const modulosExitosAction = (data) => {
    return {
      type: modulosConstants.CONSULTA_MODULOS_EXITOSO,
      payload: data
    }
  };
  export const addModulosAction = (data) => {
    return {
      type: modulosConstants.ADD_MODULOS_POST,
      payload: data
    };
  };
 
  export const modulosSuccessAction = (data) => {
    return {
      type: modulosConstants.MODULOS_SUCCESS,
      payload: data
    };
  };
 


  export const updateModulosAction = (data) => {
    return {
      type: modulosConstants.UPDATE_MODULOS_POST,
      payload: data
    };
  };
 
  export const deleteModulosAction = (data) => {
    return {
      type: modulosConstants.DELETE_MODULOS_POST,
      payload: data
    };
  };
 
  export const showLoadingAction = () => {
    return {
      type: modulosConstants.SHOW_LOADING,
    };
  };

  export const showAuthMessageAction = (message) => {
    return {
      type: modulosConstants.SHOW_AUTH_MESSAGE,
      payload: message 
    };
  };
  

  export default {
    listModulosAction,
    modulosExitosAction,
    addModulosAction,
    modulosSuccessAction,
    updateModulosAction,
    deleteModulosAction,
    showLoadingAction,
    showAuthMessageAction
  }