import { put, call } from "redux-saga/effects";
import playeroActions from "../../actions/servicio-playero/Playero";
import { Modal, message } from "antd";
import servicioPlayero from "axios/servicio-playero/Playero";
import { saveAs } from "file-saver";
import moment from "moment";

function* sendBeachSapData({ payload }) {
  try {
    const dataProcess = yield call(servicioPlayero.postBeachDataAxios, payload);
    const arrBeach = dataProcess.data;

    const isProcessingCorrectly = arrBeach.some(
      (element) => element.RESULT === "El registro no pudo ser insertado."
    );

    if (dataProcess.message) {
      // Si hay un mensaje de error general, muestra un mensaje
      message.error(dataProcess.message);
      console.log(dataProcess.message);
    } else {
      // Crear un arreglo de todos los datos en color correspondiente
      const allData = arrBeach.map((element) => {
        const color =
          element.RESULT === "El registro no pudo ser insertado."
            ? "red"
            : "green";
        return {
          text: `${element.RESULT} - Ticket: ${element.ZTICKET}`,
          color,
        };
      });

      // Función para descargar el log
      const downloadLog = () => {
        const logContent = allData.map((element) => element.text).join("\n");
        const blob = new Blob([logContent], { type: "text/plain" });
        saveAs(blob, `${moment(Date.now()).format("DD/MM/YYYY")}-log.txt`);
      };

      Modal.confirm({
        title: "Datos procesados y no procesados",
        content: (
          <div>
            {allData.map((element, index) => (
              <p key={index} style={{ color: element.color }}>
                <strong>{element.text}</strong>
              </p>
            ))}
          </div>
        ),
        okText: "Descargar log", // Change the "OK" button text
        onOk: downloadLog, // Handle the "OK" button click
        cancelText: "Cancelar", // Add a "Cancelar" button
        // You can handle the cancel button action if needed
      });
      yield put(playeroActions.successPostBeachSapDataAction());
      if (!isProcessingCorrectly) {
        // Si todos los datos se procesaron correctamente, muestra un mensaje de éxito
        yield put(playeroActions.successPostBeachSapDataAction());
        message.success("Los datos han sido procesados!");
      }
    }
  } catch (err) {
    // Maneja errores
    message.error(err.message);
    yield put(playeroActions.successPostBeachSapDataAction());
  }
}

export default {
  sendBeachSapData,
};
