import { all, takeEvery, fork } from "redux-saga/effects";
import {
  CONSULTA_USER_GET, CREATE_MODULE_USER, DELETE_USER_POST, GET_MODULE_USER, GET_ONE_USER_DATA,
  DELETE_MODULE_USER, CREATE_USER_EXITOSO, UPDATE_USER_PASS, UPDATE_USER_DATA,
  CREATE_USER_TOKEN_PASS, USER_EMAIL_SUCCESS_ONLY, USER_SUCCESS_DATA, GET_ONLY_ONE_USER,
} from "../../constants/servicio-autenticacion/Users";
import { CONSULTA_ROLES_GET } from "../../constants/servicio-autenticacion/Roles";
import userSagaFunction from './functions/User.functions'


//? crear token user 
export  function*   createTokenPassSaga () {
  yield takeEvery(CREATE_USER_TOKEN_PASS, userSagaFunction.createTokenPass );
}

//? actualizacion de datos usuarios
export function* updateDataSaga() {
  yield takeEvery(UPDATE_USER_DATA, userSagaFunction.updateData);
}

//? actualizacion de password de usuario
export function* updatePasswordSaga() {
  yield takeEvery(UPDATE_USER_PASS, userSagaFunction.updatePassword);
}

//? elimina usuarios
export function* deleteUserSaga() {
  yield takeEvery(DELETE_USER_POST, userSagaFunction.deleteUser );
}

//? Lista todos los usuarios -> Endpoint Servicio de autenticacion
export function* getUserFullDataSaga() {
  yield takeEvery(GET_ONE_USER_DATA, userSagaFunction.getUserFullData );
}

//?crear usuarios
export function* createUserSaga() {
  yield takeEvery(CREATE_USER_EXITOSO, userSagaFunction.createUser);
}

//! elimina los modulos del usuario
export function* deleteModuleUserSaga() {
  yield takeEvery(DELETE_MODULE_USER, userSagaFunction.deleteModuleUser);
}

//! Crea los modulos al usuario
export function* createModuleUserSaga() {
  yield takeEvery(CREATE_MODULE_USER, userSagaFunction.createModuleUser);
}

//! Lista los modulos del usuario
export function* getModuleUserSaga() {
  yield takeEvery(GET_MODULE_USER, userSagaFunction.getModuleUser);
}

//? listado de un usuario por id
export function* listOneUserDataSaga() {
  yield takeEvery(USER_SUCCESS_DATA , userSagaFunction.listOneUserData );
}

//? listado de un usuario por id, sin params
export function* listOnlyOneUserSaga() {
  yield takeEvery(GET_ONLY_ONE_USER, userSagaFunction.listOnlyOneUser );
}

//? listado de usuarios
export function* listUserSaga() {
  yield takeEvery(CONSULTA_USER_GET, userSagaFunction.listUser );
}
 
//? listado de roles
export function* listRolSaga() {
  yield takeEvery(CONSULTA_ROLES_GET, userSagaFunction.listRol );
}

// //? listado de modulos
// export function* listModulosSaga() {
//   yield takeEvery(CONSULTA_MODULOS_GET, userSagaFunction.listModulos );
// }

//? listado de un usuario por id
export function* listOneEmailSaga() {
  yield takeEvery(USER_EMAIL_SUCCESS_ONLY, userSagaFunction.listOneEmail );
}

export default function* rootSagaUser() {
  yield all([
    fork(listUserSaga),
    fork(listRolSaga),
    // fork(listModulosSaga),
    fork(createModuleUserSaga),
    fork(getModuleUserSaga),
    fork(deleteModuleUserSaga),
    fork(createUserSaga),
    fork(deleteUserSaga),
    fork(updatePasswordSaga),
    fork(updateDataSaga),
    fork(createTokenPassSaga),
    fork(listOneEmailSaga),
    fork(listOneUserDataSaga),
    fork(listOnlyOneUserSaga),
    fork(getUserFullDataSaga)
  ]);
}
