import axios from "axios";
axios.defaults.headers.common['Authorization'] = localStorage.getItem('auth_token');

const { REACT_APP_API_URL } = process.env;
const serviceUnysoft = {};



// Servicio para listar haberes
serviceUnysoft.listarHaberesAxios = async (payload) => {
  const{cedula, daysQuantity}= payload
  return await axios({
    method: "post",
    url: `${REACT_APP_API_URL}/interfazvacaciones/api/listar`,
    responseType: 'json',
    data: {
      ci: cedula,
      dias:daysQuantity
    }
  })
    .then((response) => response.data.haberes )
    .catch((err) => err);
};


// Servicio para listar vacaciones
serviceUnysoft.listremvacAxios = async () => {
  return await axios({
    method: "get",
    url: `${REACT_APP_API_URL}/interfazvacaciones/api/list`,
  })
    .then((response) => response.data)
    .catch((err) => err);
};

// Servicio para insertar vacaciones
serviceUnysoft.addRemvacAxios = async (data) => {
  const {
    IdConsorcio,
    IdEmpresa,
    Rut,
    FechaContrato,
    FechaInicio,
    FechaTermino,
    Nic,
    DiasTomados,
    DiasHabiles,
    DiasFestivos,
    DiasSabDom,
    DiasProgresivos,
    FechaRetorno,
  } = data;

  return await axios({
    method: "post",
    url: `${REACT_APP_API_URL}/interfazvacaciones/api/insert`,
    data: {
      IdConsorcio: IdConsorcio,
      IdEmpresa: IdEmpresa,
      Rut: Rut,
      FechaContrato: FechaContrato,
      FechaInicio: FechaInicio,
      FechaTermino: FechaTermino,
      Nic: Nic,
      DiasTomados: DiasTomados,
      DiasHabiles: DiasHabiles,
      DiasFestivos: DiasFestivos,
      DiasSabDom: DiasSabDom,
      DiasProgresivos: DiasProgresivos,
      FechaRetorno: FechaRetorno,
    },
  })
    .then((response) => response.data)
    .catch((err) => err);
};

// Servicio para eliminar vacaciones
serviceUnysoft.deleteRemvacAxios = async (data) => {
  const { IdConsorcio, IdEmpresa, Rut, FechaContrato, FechaInicio } = data;
  
  return await axios({
    method: "delete",
    url: `${REACT_APP_API_URL}/interfazvacaciones/api/delete`,
    data: {
      IdConsorcio: IdConsorcio,
      IdEmpresa: IdEmpresa,
      Rut: Rut,
      FechaContrato: FechaContrato,
      FechaInicio: FechaInicio,
    },
  }).then((response) => response);
};

// Servicio para listar total haberes
serviceUnysoft.listremcontAxios = async () => {
  return await axios({
    method: "get",
    url: `${REACT_APP_API_URL}/interfazvacaciones/api/listhaberes`,
  })
    .then((response) => response.data)
    .catch((err) => err);
};

// Servicio para insertar haberes
serviceUnysoft.addRemcontAxios = async (data) => {
  const {
    Año,
    Mes,
    Consorcio,
    Empresa,
    UnidadAplicacion,
    IdItemPpto,
    Rut,
    Nif,
    RemClasificacion,
    Monto,
    Semana,
  } = data;
  return await axios({
    method: "post",
    url: `${REACT_APP_API_URL}/interfazvacaciones/api/inserthaberes`,
    data: {
      Año: Año,
      Mes: Mes,
      Consorcio: Consorcio,
      Empresa: Empresa,
      UnidadAplicacion: UnidadAplicacion,
      IdItemPpto: IdItemPpto,
      Rut: Rut,
      NIF: Nif,
      RemClasificacion: RemClasificacion,
      Monto: Monto,
      Semana: Semana,
    },
  })
    .then((response) => response.data)
    .catch((err) => err);
};

// Servicio para eliminar haberes
serviceUnysoft.deleteRemcontAxios = async (data) => {
  const {
    Año,
    Mes,
    Consorcio,
    Empresa,
    UnidadAplicacion,
    IdItemPpto,
    Rut,
    Nif,
    RemClasificacion,
  } = data;

  return await axios({
    method: "delete",
    url: `${REACT_APP_API_URL}/interfazvacaciones/api/deletehaberes`,
    data: {
      Año: Año,
      Mes: Mes,
      Consorcio: Consorcio,
      Empresa: Empresa,
      UnidadAplicacion: UnidadAplicacion,
      IdItemPpto: IdItemPpto,
      Rut: Rut,
      NIF: Nif,
      RemClasificacion: RemClasificacion,
    },
  }).then((response) => response);
};

export default serviceUnysoft;
