/* eslint-disable */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Menu, Grid, Button, Card } from "antd";
import IntlMessage from "../util-components/IntlMessage";
import Icon from "../util-components/Icon";
import navigationConfig from "configs/NavigationConfig";
import { connect } from "react-redux";
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from "constants/ThemeConstant";
import utils from "utils";
import { onMobileNavToggle } from "redux/actions/servicio-autenticacion/Theme";
import { map } from "lodash-es";
import history from "../../history";
import {
  PlusCircleOutlined,
} from "@ant-design/icons";

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;
const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const setDefaultOpen = (key) => {
  let keyList = [];
  let keyString = "";
  if (key) {
    const arr = key.split("-");
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};

const SideNavContent = (props) => {

  const {roleVacaciones} = props; // Desestructura props

  // Path actual
  let path = location.pathname;
  // Configuración por defecto
  const defaultConfig = {
    base: "dashboards-inicio",
    config: navigationConfig.filter((obj) => obj.key === "dashboards-inicio"),
  };
  

  // Estado "nav" que será manipulado por un hook useState
  const [nav, setNav] = useState(defaultConfig.config);

  /********************************************************************************************************************/
  /* Almacenamos la configuración de navegación de cada aplicación
   * Algunas apps tienen una configuración más complicada
   * para poder renderizar elementos del navbar de forma condicional
   * dependiendo del rol del usuario
   */

  // Configuración portal admin
  // Configuración simple porque existe un solo rol en este módulo
  const portalAdmin = {
    base: "/app/apps/portal-admin",
    config: navigationConfig.filter(
      (obj) => obj.key === "apps" || obj.key === "dashboards-inicio"
    ),
  };

  // Configuración gestión vacaciones
  // Configuración compleja para renderizar menúes de acuerdo al rol del usuario
  // En resumen, se filtra recursivamente navigationConfig
  const portalVacaciones = {
    base: "/app/apps1/portal-vacaciones",
    config: navigationConfig.filter(function f(o) {
      // Como el rol llega asíncronamente, primero se chequea si este ya está listo
      if (roleVacaciones) {
        // Chequea si el menú puede ser visualizado por todos (*) o, caso contrario, si el usuario tiene el rol necesario para visualizarlo
        if (
          (o.roles.indexOf("*") !== -1 || o.roles.indexOf(roleVacaciones) !== -1) &&
          // Con esto limitamos el check sólo a aquéllos módulos que pertenecen a apps1, o sea, Gestión Vacaciones
          (o.path.includes("apps1") || o.key === "dashboards-inicio")
        ) {
          // Si el menú posee un submenú, lo pasamos como argumento para volver a entrar recursivamente a la función f
          if (o.submenu) {
            o.submenu = o.submenu.filter(f);
          }
          return o;
        }
      }
    }),
  };

  // Configuración portal de contratos
  const portalContratos = {
    base: "/app/apps2/portal-contratos",
    config: navigationConfig.filter(
      (obj) => obj.key === "apps2" || obj.key === "dashboards-inicio"
    ),
  };

  /***************************************Fin de configuración****************************************************************/

  // Este hook verifica el path en el que se encuentra el usuario acutalmente, y cambia la configuración de la navbar en base a eso
  // La idea es que la navBar cambie de acuerdo al módulo en que nos encontramos
  useEffect(() => {
    if (path.startsWith(portalAdmin.base)) {
      setNav(portalAdmin.config);
    } else if (path.startsWith(portalVacaciones.base)) {
      setNav(portalVacaciones.config);
    } else if (path.startsWith(portalContratos.base)) {
      setNav(portalContratos.config);
    } else {
      setNav(defaultConfig.config);
    }
  }, [path, roleVacaciones]);


  // Desestructura props
  const {
    sideNavTheme,
    routeInfo,
    hideGroupTitle,
    localization,
    onMobileNavToggle,
  } = props;
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes("lg");
  const closeMobileNav = () => {
    if (isMobile) {
      onMobileNavToggle(false);
    }
  };

  // Renderizado del componente de NavBar
  return (
    <Menu
      theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
      mode="inline"
      style={{ height: "100%", borderRight: 0 }}
      defaultSelectedKeys={[routeInfo?.key]}
      defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
      className={hideGroupTitle ? "hide-group-title" : ""}
    >
      {nav.map((menu) =>
        menu.submenu.length > 0 ? (
          <Menu.ItemGroup
            key={menu.key}
            title={setLocale(localization, menu.title)}
          >
            {path.startsWith(portalVacaciones.base) ? 
            <div className='main-button'>
              <Card size="small">
                <Link to='/app/apps1/portal-vacaciones/solicitudes/add-solicitudes'>
                  <Button
                  type="primary"
                  icon={<PlusCircleOutlined />}
                  block
                  >
                    Crear solicitud
                  </Button>
                </Link>
              </Card>
            </div> : null}
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  icon={
                    subMenuFirst.icon ? (
                      <Icon type={subMenuFirst?.icon} />
                    ) : null
                  }
                  key={subMenuFirst.key}
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      {subMenuSecond.icon ? (
                        <Icon type={subMenuSecond?.icon} />
                      ) : null}
                      <span>
                        {setLocale(localization, subMenuSecond.title)}
                      </span>
                      <Link
                        onClick={() => closeMobileNav()}
                        to={subMenuSecond.path}
                      />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  { subMenuFirst.icon && <Icon type={subMenuFirst.icon} /> }
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link
                    onClick={() => closeMobileNav()}
                    to={subMenuFirst.path}
                  />
                </Menu.Item>
              )
            )}
          </Menu.ItemGroup>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? (
              <Link onClick={() => closeMobileNav()} to={menu.path} />
            ) : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

// Barra de navegación posición top
const TopNavContent = (props) => {
  const { topNavColor, localization, roleVacaciones } = props;
  // Path actual
  let path = location.pathname;
  // Configuración por defecto
  const defaultConfig = {
    base: "dashboards-inicio",
    config: navigationConfig.filter((obj) => obj.key === "dashboards-inicio"),
  };
  // Estado "nav" que será manipulado por un hook useState
  const [nav, setNav] = useState(defaultConfig.config);
  // Almacenamos la configuración de navegación de cada aplicación
  const portalAdmin = {
    base: "/app/apps/portal-admin",
    config: navigationConfig.filter(
      (obj) => obj.key === "apps" || obj.key === "dashboards-inicio"
    ),
  };

  // Configuración gestión vacaciones
  // Configuración compleja para renderizar menúes de acuerdo al rol del usuario
  // En resumen, se filtra recursivamente navigationConfig
  const portalVacaciones = {
    base: "/app/apps1/portal-vacaciones",
    config: navigationConfig.filter(function f(o) {
      // Como el rol llega asíncronamente, primero se chequea si este ya está listo
      if (roleVacaciones) {
        // Chequea si el menú puede ser visualizado por todos (*) o, caso contrario, si el usuario tiene el rol necesario para visualizarlo
        if (
          (o.roles.indexOf("*") !== -1 || o.roles.indexOf(roleVacaciones) !== -1) &&
          // Con esto limitamos el check sólo a aquéllos módulos que pertenecen a apps1, o sea, Gestión Vacaciones
          (o.path.includes("apps1") || o.key === "dashboards-inicio")
        ) {
          // Si el menú posee un submenú, lo pasamos como argumento para volver a entrar recursivamente a la función f
          if (o.submenu) {
            o.submenu = o.submenu.filter(f);
          }
          return o;
        }
      }
    }),
  };

  // Configuración portal de contratos
  const portalContratos = {
    base: "/app/apps2/portal-contratos",
    config: navigationConfig.filter(
      (obj) => obj.key === "apps2" || obj.key === "dashboards-inicio"
    ),
  };

  
  // Este hook verifica el path, y cambia la configuración de la navbar
  useEffect(() => {
    if (path.startsWith(portalAdmin.base)) {
      setNav(portalAdmin.config);
    } else if (path.startsWith(portalVacaciones.base)) {
      setNav(portalVacaciones.config);
    } else if (path.startsWith(portalContratos.base)) {
      setNav(portalContratos.config);
    } else {
      setNav(defaultConfig.config);
    }
  }, [path, roleVacaciones]);

  return (
    <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
      {nav.map((menu) =>
        menu.submenu.length > 0 ? (
          <SubMenu
            key={menu.key}
            popupClassName="top-nav-menu"
            title={
              <span>
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                <span>{setLocale(localization, menu.title)}</span>
              </span>
            }
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  key={subMenuFirst.key}
                  icon={
                    subMenuFirst.icon ? (
                      <Icon type={subMenuFirst?.icon} />
                    ) : null
                  }
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      <span>
                        {setLocale(localization, subMenuSecond.title)}
                      </span>
                      <Link to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? (
                    <Icon type={subMenuFirst?.icon} />
                  ) : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link to={subMenuFirst.path} />
                </Menu.Item>
              )
            )}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link to={menu.path} /> : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

const MenuContent = (props) => {
  return props.type === NAV_TYPE_SIDE ? (
    <SideNavContent {...props} />
  ) : (
    <TopNavContent {...props} />
  );
};

const mapStateToProps = ({ theme, solicitudesUsers }) => {
  const { sideNavTheme, topNavColor } = theme;
  const roleVacaciones = solicitudesUsers.role;
  return { sideNavTheme, topNavColor, roleVacaciones };
};

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent);
