import  themeConstants from '../../constants/servicio-autenticacion/Theme';
import { THEME_CONFIG } from 'configs/AppConfig'

const initTheme = {
  ...THEME_CONFIG
};

const theme = (state = initTheme, action) => {
  switch (action.type) {
    case themeConstants.TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        navCollapsed: action.navCollapsed
      };
    case themeConstants.SIDE_NAV_STYLE_CHANGE:
      return {
        ...state,
        sideNavTheme: action.sideNavTheme
      };
    case themeConstants.CHANGE_LOCALE:
      return {
        ...state,
        locale: action.locale
      };
    case themeConstants.NAV_TYPE_CHANGE:
      return {
        ...state,
        navType: action.navType
      };
    case themeConstants.TOP_NAV_COLOR_CHANGE:
      return {
        ...state,
        topNavColor: action.topNavColor
      };
    case themeConstants.HEADER_NAV_COLOR_CHANGE:
      return {
        ...state,
        headerNavColor: action.headerNavColor
      };
    case themeConstants.TOGGLE_MOBILE_NAV:
      return {
        ...state,
        mobileNav: action.mobileNav
      };
    case themeConstants.SWITCH_THEME:
      return {
        ...state,
        currentTheme: action.currentTheme
      }
    case themeConstants.DIRECTION_CHANGE:
      return {
        ...state,
        direction: action.direction
      }
    default:
      return state;
  }
};

export default theme